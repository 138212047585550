import { Box, IconButton, Typography } from '@mui/material';
import { Card, PlaybookPageDataResponse } from '../../../API';
import AddIcon from '@mui/icons-material/Add';
import { truncateText } from '../../../helpers/utils';
import { AuthSession, UserPermissions, WorksheetSize } from '../../../globals';
import CustomLegend from '../../charts/CustomLegend';
import { PagePreview } from '../../workbooks/WorkbookDrawerPage';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { CardContext } from '../../cards/context';
import { StyledModal } from '../../Modal';
import ModalContainer from '../../ModalContainer';
import CreateCard from '../../forms/CreateCard';
import AnalysisModal from './AnalysisModal';
import { GhostGridCard } from './PestleAnalysis';
import { OptionsValue } from './SwotGrid';
import { DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import { AppContext, WorksheetContext } from '../../contexts';
import CardComponentSmall from '../../cards/CardComponentSmall';
import { cardToCardComponentProps } from '../../../pages/cards';
import { ReactComponent as MenuIcon } from '../../../assets/icons/Menu.svg';
import CardMenu from '../../cards/CardMenu';
import { CardComponentData, PageIdentifier } from '../../cards/cardTypes';
import { getUserPermissions } from '../../../helpers/permissions';
import { ViewType } from '../../layouts/PageView';
import { CardScore } from '../../forms/CreateCard/types';
import { getWorkSheetCardMenuItems } from '../../../helpers/worksheetCards';
import { getWorksheetClickSide } from '../../../helpers/worksheets';

interface RiskAnalysisProps {
    cards: Card[];
    size: WorksheetSize;
    page: PlaybookPageDataResponse | PagePreview | undefined;
    workbookId?: string;
    pptView?: boolean;
    pageOptions?: any;
    selectedCard?: string | null;
    setSelectedCard?: React.Dispatch<React.SetStateAction<string | null>>;
    fullWidth?: boolean | undefined;
    addCardToWorkbook?: (card: Card, addToWorksheet: boolean) => Promise<void>;
    activeCardId?: string | null;
    activeCardId2?: string | null;
    workbookPermissions?: UserPermissions[] | undefined;
    setInnerHeight?: React.Dispatch<React.SetStateAction<number>>;
    onHover?: (id: string | null) => void;
    worksheetMainWidth?: number;
    handleCardClick?: (card: Card | CardComponentData, side: string) => void;
    activeCardIds?: (string | null)[];
}

export type ScoreCard = Card & { score?: number };
export const severityScores: any = {
    '0': 4,
    '1': 2,
    '2': 0,
    '3': -2,
    '4': -4,
    '5': -5,
};
export const likelihoodScores: any = {
    '0': 1,
    '1': 3,
    '2': 5,
    '3': 7,
    '4': 9,
    '5': 10,
};
export const colorArrays = [
    ['#ffa600', '#ff7700', '#f10000', '#f10000', '#f10000'],
    ['#ffa600', '#ffa600', '#ff7700', '#ff7700', '#f10000'],
    ['#00cc3f', '#ffa600', '#ff7700', '#ff7700', '#f10000'],
    ['#00a53a', '#00cc3f', '#ffa600', '#ffa600', '#ffa600'],
    ['#00a53a', '#00a53a', '#00cc3f', '#00cc3f', '#00cc3f'],
];
export const getRiskCards = (y: number, x: number, cards: Card[]) => {
    const riskCards: ScoreCard[] = [];
    const impactScores: any = {
        '5': 0,
        '4': 0,
        '3': 1,
        '2': 1,
        '1': 2,
        '0': 2,
        '-1': 2,
        '-2': 3,
        '-3': 2,
        '-4': 4,
        '-5': 4,
    };
    const likelihoodScores: any = {
        '0': 0,
        '1': 0,
        '2': 0,
        '3': 1,
        '4': 1,
        '5': 2,
        '6': 2,
        '7': 3,
        '8': 3,
        '9': 4,
        '10': 4,
    };

    cards.forEach((card) => {
        let impact = card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === '7-S-1'
        );
        if (!impact) return;

        let likelihood = card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === '7-S-2'
        );
        if (!likelihood) return;

        const scoreImpact = impact?.data?.[impact.data.length - 1];
        const scoreLikelihood = likelihood?.data?.[likelihood.data.length - 1];

        if (
            impactScores[scoreImpact?.value] === y &&
            likelihoodScores[scoreLikelihood?.value] === x
        ) {
            const mitigation = card.toScoreData?.items.find(
                (item) => item?.scoreDefinitionId === '7-S-3'
            );
            const mitigationScore =
                mitigation?.data[mitigation.data.length - 1];

            riskCards.push({
                ...card,
                ...(mitigationScore && {
                    score: mitigationScore?.value,
                }),
            });
        }
    });
    return riskCards;
};
const RiskAnalysis = ({
    cards,
    size,
    page,
    workbookId,
    pptView,
    pageOptions,
    selectedCard,
    setSelectedCard,
    fullWidth,
    addCardToWorkbook,
    activeCardId,
    activeCardId2,
    workbookPermissions,
    setInnerHeight,
    onHover,
    worksheetMainWidth,
    handleCardClick,
    activeCardIds,
}: RiskAnalysisProps) => {
    const { permissions, handleClose } = useContext(CardContext);
    const { user } = useContext(AppContext);
    const { removeId, multiSelectedCards, setMultiSelectedCards } =
        useContext(WorksheetContext);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [scores, setScores] = useState<CardScore[] | null>(null);
    const {
        handleDelete,
        handleRemoveFromWorkbook,
        handleRemoveFromWorksheet,
        createCardRelationship,
        setCardFrom,
        handleCopy,
    } = useContext(WorksheetContext);

    const CARD_FONT_SIZE =
        size === WorksheetSize.SMALL
            ? '0.2rem'
            : size === WorksheetSize.CAROUSEL
            ? '0.9rem'
            : '0.6rem';

    const AXIS_TEXT_NORMAL =
        size === WorksheetSize.SMALL
            ? '7px'
            : WorksheetSize.CAROUSEL
            ? '12px'
            : '8px';
    const AXIS_TEXT_BOLD =
        size === WorksheetSize.SMALL
            ? '8px'
            : WorksheetSize.CAROUSEL
            ? '12px'
            : '8px';

    const includes: OptionsValue[] = pageOptions?.layout[0].include ?? null;

    const LegendComponent = () => {
        return (
            <CustomLegend
                labels={[
                    'Potentially catastrophic - stop!',
                    'Unacceptable - urgent action required',
                    'Undesirable - take action',
                    'Acceptable - monitor with periodic review',
                    'Desirable - no action required',
                ]}
                colors={['#f10000', '#fb7915', '#f5ab00', '#00c248', '#00a33b']}
                sx={{
                    paddingLeft: size === WorksheetSize.CAROUSEL ? 0 : '60px',
                }}
                title="Legend"
                thumbnail={size === WorksheetSize.SMALL}
                pptView={pptView}
                customLabelSize={pptView ? '14px' : '12px'}
                width={'100%'}
                worksheet
            />
        );
    };
    function Draggable({
        card,
        children,
    }: {
        card: Card;
        children: React.ReactNode;
    }) {
        const { attributes, listeners, setNodeRef } = useDraggable({
            id: `remove_${card.id}`,
        });

        return (
            <div ref={setNodeRef} style={{ width: '100%' }}>
                <div {...listeners} {...attributes} style={{ cursor: 'grab' }}>
                    {children}
                </div>
            </div>
        );
    }
    const GridCard = ({
        card,
        index,
        user,
        workbookPermissions,
    }: {
        card: ScoreCard;
        index: number;
        user: AuthSession | null;
        workbookPermissions: UserPermissions[] | undefined;
    }) => {
        const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
        const [permissions, setPermissions] = useState<UserPermissions[]>([]);

        const isSelected = useMemo(() => {
            return multiSelectedCards?.some(
                (selectedCard) => selectedCard.id === card.id
            );
        }, [multiSelectedCards, card.id]);

        const menuItems: any = useMemo(
            () =>
                getWorkSheetCardMenuItems({
                    activeCardIds: activeCardIds ?? [],
                    card,
                    worksheetId: page?.id,
                    permissions,
                    workbookPermissions,
                    handleDelete,
                    multiSelectedCards,
                    handleRemoveFromWorkbook,
                    createCardRelationship,
                    handleRemoveFromWorksheet,
                    handleCopy,
                    setCardFrom,
                }),
            [card]
        );

        useEffect(() => {
            if (user) {
                const userPermissions = getUserPermissions(
                    user?.tokens?.idToken?.payload?.sub ?? '',
                    card
                );

                setPermissions(userPermissions);
            }
        }, [card, user]);

        const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
            if (e) {
                e.stopPropagation();
                setMenuAnchor(e.currentTarget);
            } else {
                setMenuAnchor(null);
            }
        };
        const { attributes, listeners, setNodeRef } = useDraggable({
            id: `remove_${card.id}`,
        });
        return removeId && removeId === card.id ? (
            <Box />
        ) : (
            <Draggable card={card}>
                <Box
                    {...(onHover && {
                        onMouseEnter: (
                            event: React.MouseEvent<HTMLElement>
                        ) => {
                            onHover(card.id);
                        },
                        onMouseLeave: (
                            event: React.MouseEvent<HTMLElement>
                        ) => {
                            onHover(null);
                        },
                    })}
                    ref={setNodeRef}
                    className={index === 0 ? 'grid-card' : ''}
                    sx={{
                        opacity:
                            multiSelectedCards?.length && !isSelected
                                ? '0.25'
                                : '1',
                        position: 'relative',
                        background: '#666',
                        color: '#fff',
                        minHeight: pptView
                            ? '20px'
                            : size === WorksheetSize.SMALL
                            ? '25px'
                            : size === WorksheetSize.CAROUSEL && !pptView
                            ? '100px'
                            : '50px',
                        borderRadius:
                            size === WorksheetSize.CAROUSEL ? '8px' : '4px',
                        marginBottom:
                            size === WorksheetSize.CAROUSEL ? '8px' : '4px',
                        padding:
                            size === WorksheetSize.CAROUSEL ? '10px' : '5px',
                        cursor: 'pointer',
                        borderColor: 'transparent',
                        borderWidth: index > 0 ? '3px' : 0,
                        borderStyle: 'solid',
                        ...(workbookId && {
                            ':hover': {
                                borderStyle: 'solid',
                                borderColor: 'rgb(61, 116, 222)',
                                borderWidth: '4px',
                                zIndex: 1000,
                            },
                        }),
                        border:
                            card.id === activeCardId ||
                            card.id === activeCardId2
                                ? 'solid 4px rgb(61, 116, 222)'
                                : 'inherit',
                    }}
                    onDoubleClick={(event) => {
                        if (setSelectedCard) {
                            setSelectedCard(null);
                            setTimeout(() => setSelectedCard(card.id), 500);
                        }
                        if (workbookId) {
                            const side = getWorksheetClickSide(
                                worksheetMainWidth,
                                event
                            );
                            handleCardClick && handleCardClick(card, side);
                        }
                    }}
                    onClick={() => {
                        setTimeout(() => {
                            setMultiSelectedCards((multiSelectedCards) => {
                                let cards = [...multiSelectedCards];

                                if (
                                    multiSelectedCards.find(
                                        (selectedCard) =>
                                            selectedCard.id === card.id
                                    )
                                ) {
                                    cards = cards.filter(
                                        (c) => c.id !== card.id
                                    );
                                } else {
                                    cards.push(card);
                                }

                                return cards;
                            });
                        }, 250);
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                cursor: 'pointer',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: CARD_FONT_SIZE,
                                    fontWeight: 600,
                                }}
                                className={index === 0 ? 'grid-card-text' : ''}
                            >
                                {truncateText(
                                    card.name,
                                    size === WorksheetSize.SMALL
                                        ? 25
                                        : size === WorksheetSize.CAROUSEL
                                        ? 30
                                        : 20
                                )}
                            </Typography>

                            <Typography
                                variant="body2"
                                sx={{
                                    fontSize: CARD_FONT_SIZE,
                                    fontWeight: 'bold',
                                }}
                                className={index === 0 ? 'grid-card-text' : ''}
                            >
                                {card.score}
                            </Typography>
                        </Box>
                        <Box sx={{ flex: 1, width: '100%' }}>
                            {!pptView && (
                                <Typography
                                    variant="body2"
                                    sx={{
                                        fontSize: CARD_FONT_SIZE,
                                    }}
                                    className={
                                        index === 0 ? 'grid-card-text' : ''
                                    }
                                >
                                    {truncateText(
                                        card.description ?? '',
                                        size === WorksheetSize.SMALL
                                            ? 15
                                            : size === WorksheetSize.CAROUSEL
                                            ? 80
                                            : 45
                                    )}
                                </Typography>
                            )}
                        </Box>
                    </Box>

                    {workbookId && (
                        <IconButton
                            aria-label=""
                            id="menu-button"
                            onClick={(e) => {
                                e.stopPropagation();
                                setMenu(e);
                            }}
                            sx={{
                                ml: 2,
                                color: '#fff',
                                position: 'absolute',
                                zIndex: 1100,
                                right: 0,
                                bottom: 0,
                            }}
                        >
                            <MenuIcon width={'20px'} height={'20px'} />
                        </IconButton>
                    )}
                    {menuAnchor && (
                        <CardMenu
                            permissions={[
                                UserPermissions.EDIT,
                                UserPermissions.DELETE,
                            ]}
                            anchor={menuAnchor}
                            data={{
                                organisation: card.organisation || '',
                                id: card.id,
                            }}
                            menuItems={menuItems}
                            setMenu={setMenu}
                        />
                    )}
                </Box>
            </Draggable>
        );
    };
    function DroppableCell({
        droppableId,
        children,
        style,
    }: {
        droppableId: string;
        children: React.ReactNode;
        style: any;
    }) {
        const { isOver, setNodeRef } = useDroppable({ id: droppableId });

        return (
            <Box
                ref={setNodeRef}
                sx={{
                    ...style,
                    background: isOver ? '#99b7e4' : style.background,
                }}
            >
                {children}
            </Box>
        );
    }
    const getDroppableId = (yIndex: number, xIndex: number) =>
        `7_${yIndex}-${xIndex}-`;

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref && ref.current?.clientHeight) {
            setInnerHeight && setInnerHeight(ref.current.clientHeight);
        }
    }, [ref.current?.clientHeight]);
    return (
        <Box
            ref={ref}
            sx={{
                display: size === WorksheetSize.CAROUSEL ? 'flex' : 'inherit',
                flexDirection: workbookId
                    ? 'column-reverse'
                    : size === WorksheetSize.CAROUSEL
                    ? 'row'
                    : 'column',
                mt: pptView ? 2 : 0,
            }}
        >
            {size === WorksheetSize.CAROUSEL && (
                <Box sx={{ width: workbookId ? '500px' : '300px' }}>
                    <LegendComponent />
                </Box>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {workbookId &&
                        permissions?.includes(UserPermissions.EDIT) && (
                            <Box
                                sx={{
                                    width: '45px%',
                                    height: '45px',
                                    marginBottom: '20px',
                                }}
                            >
                                <IconButton
                                    color="primary"
                                    sx={{
                                        borderRadius: '50px',
                                        border: 'dashed 2px blue',
                                    }}
                                    onClick={() => setSelectedType('7')}
                                >
                                    <AddIcon
                                        sx={{
                                            fontSize: '3rem',
                                            width: '25px',
                                            height: '23px',
                                        }}
                                    />
                                </IconButton>
                            </Box>
                        )}
                </Box>
                <Box sx={{ display: 'flex' }}>
                    {size !== WorksheetSize.SMALL && (
                        <Box
                            sx={{
                                width:
                                    size === WorksheetSize.CAROUSEL
                                        ? '80px'
                                        : '60px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                paddingBottom: '20px',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'right',
                                    marginRight: '6px',
                                    fontSize: AXIS_TEXT_NORMAL,
                                }}
                            >
                                Critical
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'right',
                                    marginRight: '6px',
                                    fontSize: AXIS_TEXT_BOLD,
                                    fontWeight: 600,
                                }}
                            >
                                Impact
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'right',
                                    marginRight: '6px',
                                    fontSize: AXIS_TEXT_NORMAL,
                                }}
                            >
                                Very Low
                            </Typography>
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(5, 1fr)',
                            gridGap: '3px',
                            width: '100%',
                            flex: 1,
                        }}
                    >
                        {colorArrays.map((colorArray, yIndex) =>
                            colorArray.map((color, xIndex) => {
                                const droppableId = getDroppableId(
                                    yIndex,
                                    xIndex
                                );
                                const cellStyle = {
                                    position: 'relative',
                                    ...(workbookId && {
                                        '&:hover .hoverBox': {
                                            opacity: 1,
                                            visibility: 'visible',
                                        },
                                    }),
                                    ...(workbookId &&
                                        workbookPermissions?.includes(
                                            UserPermissions.EDIT
                                        ) && {
                                            '&:hover .grid-card': {
                                                height:
                                                    size ===
                                                    WorksheetSize.CAROUSEL
                                                        ? '90px'
                                                        : '30px',
                                                minHeight:
                                                    size ===
                                                    WorksheetSize.CAROUSEL
                                                        ? '60px'
                                                        : '30px',
                                                mt: '20px',
                                            },
                                        }),

                                    width: '100%',
                                    minHeight: workbookId
                                        ? '90px'
                                        : pptView
                                        ? '80px'
                                        : size === WorksheetSize.SMALL
                                        ? '25px'
                                        : '50px',
                                    borderRadius: '4px',
                                    background: color,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    padding:
                                        size === WorksheetSize.CAROUSEL
                                            ? '10px 10px 0 10px'
                                            : size === WorksheetSize.SMALL
                                            ? '3px 3px 0 3px'
                                            : '5px 5px 0 5px',
                                };
                                const riskCards = useMemo(() => {
                                    return getRiskCards(yIndex, xIndex, cards);
                                }, [cards]);
                                return (
                                    <DroppableCell
                                        key={droppableId}
                                        droppableId={droppableId}
                                        style={cellStyle}
                                    >
                                        {workbookPermissions?.includes(
                                            UserPermissions.EDIT
                                        ) && (
                                            <GhostGridCard
                                                top={0}
                                                onClick={() => {
                                                    setSelectedType('7');
                                                    setScores([
                                                        {
                                                            scoreDefinition:
                                                                '7-S-1',
                                                            value: severityScores[
                                                                yIndex.toString()
                                                            ],
                                                        },
                                                        {
                                                            scoreDefinition:
                                                                '7-S-2',
                                                            value: likelihoodScores[
                                                                xIndex.toString()
                                                            ],
                                                        },
                                                    ]);
                                                }}
                                            />
                                        )}
                                        {riskCards.map((card, index) => (
                                            <GridCard
                                                key={card.id}
                                                card={card}
                                                index={index}
                                                user={user}
                                                workbookPermissions={
                                                    workbookPermissions
                                                }
                                            />
                                        ))}
                                    </DroppableCell>
                                );
                            })
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                gridColumn: '1 / span 5',
                            }}
                        >
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'right',
                                    marginRight: '6px',
                                    fontSize: AXIS_TEXT_NORMAL,
                                }}
                            >
                                Not likely
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'right',
                                    marginRight: '6px',
                                    fontSize: AXIS_TEXT_BOLD,
                                    fontWeight: 600,
                                }}
                            >
                                Likelihood
                            </Typography>
                            <Typography
                                variant="body2"
                                sx={{
                                    textAlign: 'right',
                                    marginRight: '6px',
                                    fontSize: AXIS_TEXT_NORMAL,
                                }}
                            >
                                Highly likely
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {size !== WorksheetSize.CAROUSEL && <LegendComponent />}
                {!!workbookId && (
                    <StyledModal
                        key="modal"
                        open={!!selectedType}
                        onClose={() => setSelectedType(null)}
                    >
                        <Box>
                            {!!selectedType && (
                                <ModalContainer sx={{ maxWidth: '35rem' }}>
                                    <CreateCard
                                        cardTypeFromDrawer={selectedType}
                                        cardSet={workbookId}
                                        handleClose={() => {
                                            handleClose();
                                            setSelectedType(null);
                                        }}
                                        riskScores={scores}
                                        addCardToWorkbook={addCardToWorkbook}
                                        worksheet={true}
                                        close={() => setSelectedType(null)}
                                    />
                                </ModalContainer>
                            )}
                        </Box>
                    </StyledModal>
                )}
                {selectedCard &&
                    cards.find((card) => card.id === selectedCard) && (
                        <AnalysisModal
                            card={
                                cards.find(
                                    (card) => card.id === selectedCard
                                ) ?? null
                            }
                            handleClose={() => {
                                setSelectedCard && setSelectedCard(null);
                            }}
                            fullWidth={fullWidth}
                            setSelectedCard={setSelectedCard}
                        />
                    )}
            </Box>
            {removeId && (
                <DragOverlay>
                    {removeId && cards.find((card) => card.id === removeId) && (
                        <Box sx={{ zIndex: 9999 }}>
                            <CardComponentSmall
                                data={cardToCardComponentProps(
                                    cards.find(
                                        (card) => card.id === removeId
                                    ) as Card
                                )}
                                smallCard={true}
                                swotGrid={true}
                                swotColor=""
                            />
                        </Box>
                    )}
                </DragOverlay>
            )}
        </Box>
    );
};

export default RiskAnalysis;
