import { useContext, useEffect } from 'react';
import CardComponent from '../CardComponent';
import GridLayout from '../../layouts/GridLayout';
import { CardContext } from '../context';
import { FilterCards } from '../../../hooks/useFilterCards';
import {
    CardComponentData,
    CardSetComponentData,
    PageIdentifier,
} from '../cardTypes';
import { CardToDelete } from '../../../pages/cards';
import { Card } from '../../../API';
import OutsideClickHandler from 'react-outside-click-handler';
import CardComponentSmall from '../CardComponentSmall';
interface CardGridViewProps {
    action?: () => void;
    actionLabel?: string;
    secondaryAction?: () => void;
    secondaryActionLabel?: string;
    pageIdentifier: PageIdentifier;
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    multiSelectedCards?: CardToDelete[] | [];
    setMultiSelectedCards?: React.Dispatch<
        React.SetStateAction<CardToDelete[] | []>
    >;
    cardsObject?: {
        [key: string]: Card;
    };
    cardItems?: CardComponentData[];
    mediumCard?: boolean;
    handleSelect?: (item: CardComponentData | CardSetComponentData) => void;
    clickedItems?: CardSetComponentData[];
    onLoadMore?: (initialLoad?: boolean) => Promise<void>;
    token?: string | null;
    loading?: boolean;
    small?: boolean;
}

const CardGridView = ({
    action,
    actionLabel,
    secondaryAction,
    secondaryActionLabel,
    pageIdentifier,
    xs,
    sm,
    md,
    lg,
    xl,
    multiSelectedCards,
    setMultiSelectedCards,
    cardsObject,
    cardItems,
    mediumCard,
    handleSelect,
    clickedItems,
    onLoadMore,
    token,
    loading,
    small,
}: CardGridViewProps) => {
    const {
        createCardRelationship,
        emptyAction,
        setCardFrom,
        handleClick,
        handleClose,
        handleDelete,
        handleEdit,
        handleRemove,
        handleConvert,
        handleCopy,
        items,
    } = useContext(CardContext);
    const itemsToMap = cardItems || items;

    return (
        <GridLayout
            emptyAction={emptyAction}
            secondaryAction={secondaryAction}
            action={action}
            actionLabel={actionLabel}
            secondaryActionLabel={secondaryActionLabel}
            pageIdentifier={pageIdentifier}
            xs={xs}
            sm={sm}
            md={md}
            lg={lg}
            xl={xl}
            mediumCard={mediumCard}
            onLoadMore={onLoadMore}
            token={token}
            loading={loading}
            gridItems={itemsToMap.map((item) => (
                <>
                    {small ? (
                        <CardComponentSmall
                            key={item.id}
                            data={item}
                            onClick={handleClick}
                            onDelete={handleClose}
                            handleDelete={handleDelete}
                            handleConvert={handleConvert}
                            handleEdit={handleEdit}
                            {...(handleRemove && {
                                handleRemove: handleRemove,
                            })}
                            {...(handleCopy && {
                                handleCopy: handleCopy,
                            })}
                            multiSelectedCards={multiSelectedCards}
                            setMultiSelectedCards={setMultiSelectedCards}
                            cardsObject={cardsObject}
                            pageIdentifier={pageIdentifier}
                            mediumCard={mediumCard}
                            handleSelect={handleSelect}
                            clickedItems={clickedItems}
                        />
                    ) : (
                        <CardComponent
                            key={item.id}
                            data={item}
                            createCardRelationship={createCardRelationship}
                            onClick={handleClick}
                            onDelete={handleClose}
                            handleDelete={handleDelete}
                            handleConvert={handleConvert}
                            handleEdit={handleEdit}
                            setCardFrom={setCardFrom}
                            {...(handleRemove && {
                                handleRemove: handleRemove,
                            })}
                            {...(handleCopy && {
                                handleCopy: handleCopy,
                            })}
                            multiSelectedCards={multiSelectedCards}
                            setMultiSelectedCards={setMultiSelectedCards}
                            cardsObject={cardsObject}
                            pageIdentifier={pageIdentifier}
                            mediumCard={mediumCard}
                            handleSelect={handleSelect}
                            clickedItems={clickedItems}
                        />
                    )}
                </>
            ))}
        />
    );
};

export default CardGridView;
