import { Card, Relationship, RelationshipType } from '../API';
import { listRelationships } from '../graphql/queries';
import { queryDataWithFilter } from './QueryData';

type targetFilterId = {
    targetId: { eq: string };
};
type sourceFilterId = {
    sourceId: { eq: string };
};

type filterId = sourceFilterId | targetFilterId;
export const getRelationshipsByCardsIds = async ({
    cardIds,
    nextToken,
    organisation,
}: {
    cardIds: string[];
    nextToken?: string;
    organisation: string;
}) => {
    const filter: { or: filterId[]; and: {} } = {
        or: [],
        and: { sourceType: { eq: 'Card' } },
    };

    cardIds.forEach((cardId) => {
        filter.or.push({ targetId: { eq: cardId } });
        filter.or.push({ sourceId: { eq: cardId } });
    });

    try {
        const { data, nextToken }: any = await queryDataWithFilter({
            query: listRelationships,
            responseType: 'listRelationships',
            filter: filter,
            organisation: organisation,
            limit: true,
        });

        return { data: data, nextToken };
    } catch (err) {
        console.log(err);
    }
};
