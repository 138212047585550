import React, { useContext, useEffect, useState } from 'react';
import { generateClient } from 'aws-amplify/api';
import { Box, Typography, useTheme } from '@mui/material';
import { PlaybookPageProps } from '../playbookTypes';
import {
    StyledPlaybookPage,
    StyledPlaybookScrollContainer,
} from '../StyledPlaybookComponents';
import { StyledSection } from '../../StyledElements';
import {
    Card,
    CardsOrderedByNameQuery,
    CardsCardSetsByCardSetIdWithScoresQuery,
} from '../../../API';
import {
    cardsCardSetsByCardSetIdWithScores,
    cardsOrderedByNameWithScoreData,
} from '../../../graphql/custom-queries';
import SwotGrid from './SwotGrid';
import PlaybookMenu from '../PlaybookMenu';
import Spiral from '../../../assets/images/SC-spiral.png';
import { UserPermissions } from '../../../globals';

import { getUserOrganisation } from '../../../helpers/auth';
import BubblePage from './BubblePage';
import Retrospective from './Retrospective';
import { WorksheetSize } from '../../../globals';
import {
    ChartType,
    WorksheetNames,
    getChartType,
} from '../../../helpers/worksheets';
import { worksheets } from '../../forms/worksheets';
import { AppContext } from '../../contexts';
import RiskAnalysis from './RiskAnalysis';
import RadarPage from './RadarPage';
import PestleAnalysis from './PestleAnalysis';
import PortersFiveForces from './PortersFivesForces';
import Logo from '../../profile/Logo';

const ChartPage = ({
    page,
    playbookPageForm,
    setEditMode,
    orderPages,
    cards,
    handleDelete,
    permissions,
    itemSelected,
    first,
    last,
    edit,
    carousel,
    pageOptions,
    setPageOptions,
    radarOptions,
    setRadarOptions,
    pptView,
    slideNumber,
    logo,
}: PlaybookPageProps) => {
    const worksheetId = page?.worksheetId ?? '1';
    const worksheet = worksheets.find((item) => item.i === worksheetId);
    const [chartType, setChartType] = useState<ChartType>();
    const parsedPageCardSetIds: string[] | undefined = [];
    const [playbookCards, setPlaybookCards] = useState<Card[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const { user } = useContext(AppContext);

    const worksheetSize =
        !edit && !carousel
            ? WorksheetSize.SMALL
            : carousel
            ? WorksheetSize.CAROUSEL
            : WorksheetSize.DEFAULT;

    const client = generateClient();
    const setMenu = (e: React.BaseSyntheticEvent) => {
        e.stopPropagation();

        if (e) {
            setAnchorEl(e.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };
    const theme = useTheme();
    useEffect(() => {
        const worksheetName = worksheets?.find(
            (item) => item.i === page?.worksheetId
        )?.n as WorksheetNames;

        if (worksheetName) {
            const worksheetChartType = getChartType(worksheetName);
            worksheetChartType && setChartType(worksheetChartType);
        } else {
            setChartType(ChartType.GRID);
        }
    }, [page]);

    useEffect(() => {
        const fetchCards = async () => {
            const getCardsCardSets = async (cardSetId?: string) => {
                if (cardSetId) {
                    return (await client.graphql({
                        query: cardsCardSetsByCardSetIdWithScores,
                        variables: {
                            cardSetId,
                        },
                    })) as { data: CardsCardSetsByCardSetIdWithScoresQuery };
                } else {
                    return;
                }
            };

            const getCards = async (cardSetIds: (string | undefined)[]) => {
                const cardSetRes = await Promise.all(
                    cardSetIds.map((cardSetId) => {
                        if (cardSetId) {
                            return getCardsCardSets(cardSetId);
                        }
                    })
                );

                const cardsCardSets = cardSetRes.flatMap(
                    (promiseItem) =>
                        promiseItem?.data?.cardsCardSetsByCardSetId?.items
                );

                const cards = cardsCardSets?.map(
                    (cardsCardSet) => cardsCardSet?.card
                ) as Card[];
                const dedupedCards = cards.filter(
                    (value, index, self) =>
                        index === self.findIndex((t) => t.id === value.id) &&
                        worksheet?.auto.includes(value.cardToCardTypeId)
                );

                if (dedupedCards) {
                    setPlaybookCards(dedupedCards);
                }
            };

            const cardSetsToQuery: string[] = playbookPageForm?.cardSetIds
                .length
                ? playbookPageForm?.cardSetIds
                : playbookPageForm?.workbookIds.length
                ? playbookPageForm?.workbookIds
                : parsedPageCardSetIds ?? [];

            if (cardSetsToQuery.length) {
                getCards(cardSetsToQuery);
            } else {
                setPlaybookCards([]);
            }
        };

        user &&
        (playbookPageForm?.workbookIds?.length ||
            playbookPageForm?.cardSetIds?.length)
            ? fetchCards()
            : cards?.length
            ? setPlaybookCards(cards as Card[])
            : setPlaybookCards([]);
    }, [
        playbookPageForm?.cardSetIds,
        playbookPageForm?.workbookIds,
        cards,
        user,
    ]);

    useEffect(() => {
        const fetchCards = async () => {
            const userGroup = getUserOrganisation(user);

            const ids: { id: { eq: string } }[] = [];
            playbookPageForm?.cardIds.forEach((id: string) => {
                ids.push({ id: { eq: id } });
            });

            let allCards: Card[] = [];
            let nextToken = null;

            try {
                do {
                    const response = await client.graphql({
                        query: cardsOrderedByNameWithScoreData,
                        variables: {
                            organisation: userGroup,
                            filter: { or: ids },
                            nextToken: nextToken,
                        },
                    });

                    const result = response as {
                        data: CardsOrderedByNameQuery;
                    };
                    allCards = [
                        ...allCards,
                        ...(result?.data?.cardsOrderedByName?.items as Card[]),
                    ];
                    nextToken = result?.data?.cardsOrderedByName?.nextToken;
                } while (nextToken);

                setPlaybookCards(allCards);
            } catch (e) {
                console.log(e);
            }
        };
        if (playbookPageForm?.cardIds?.length) {
            !cards?.length ? fetchCards() : setPlaybookCards(cards);
        }
    }, [playbookPageForm?.cardIds, pageOptions, cards, user]);

    const renderedPageData = page;
    const thumbnailStyles = {
        height: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
        userSelect: 'none',
    };

    const layout = pageOptions?.layout?.[0]
        ? pageOptions?.layout?.[0]?.layout
        : page?.options
        ? JSON.parse(page.options).layout?.[0].layout
        : null;

    return (
        <StyledPlaybookPage edit={!!playbookPageForm} carousel={carousel}>
            {!playbookPageForm && !pptView && (
                <Box
                    sx={{
                        width: '50px',
                        height: '418px',
                        background: `url(${Spiral})`,
                        backgroundRepeat: 'repeat-y',
                        position: 'absolute',
                        left: '-11px',
                        backgroundSize: '65%',
                    }}
                />
            )}
            <StyledPlaybookScrollContainer
                sx={(!playbookPageForm && thumbnailStyles) || {}}
            >
                <StyledSection
                    sx={{
                        mb: !playbookPageForm ? 0 : '',
                        minHeight: pptView ? '100px' : '',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontSize= {carousel ? "3rem" : !!playbookPageForm ? "2rem" :"1.3rem"}
                            sx={{ width: '100%' }}
                        >
                            {renderedPageData?.title}{' '}
                            <span style={{ fontSize: '24px' }}>
                                {slideNumber && `(${slideNumber} of 2)`}
                            </span>
                        </Typography>

                        {logo &&
                            layout?.find(
                                (item: { value: string }) =>
                                    item.value === 'logo'
                            )?.enabled &&
                            !pptView && (
                                <Logo
                                    thumbnail={!playbookPageForm}
                                    logo={logo}
                                    pptView={pptView}
                                />
                            )}
                    </Box>

                    {(!pptView ||
                        (pptView && !slideNumber) ||
                        (pptView && slideNumber === 1)) && (
                        <Typography
                            variant="body2"
                            fontSize={pptView ? '1em' : '0.875em'}
                            sx={{ marginTop: '10px', whiteSpace: 'pre-wrap' }}
                        >
                            {renderedPageData?.commentary}
                        </Typography>
                    )}
                </StyledSection>
                {chartType &&
                    {
                        [ChartType.GRID]: (
                            <SwotGrid
                                cards={playbookCards}
                                width={600}
                                include={
                                    (renderedPageData?.include?.filter(
                                        (item) => !!item && item != null
                                    ) as string[]) ?? []
                                }
                                thumbnail={edit ? false : true}
                                fullWidth={carousel ? true : false}
                                pptView={pptView}
                                slideNumber={slideNumber}
                                pageOptions={pageOptions}
                            />
                        ),
                        [ChartType.BUBBLES]: (
                            <BubblePage
                                cards={playbookCards}
                                page={page}
                                thumbnail={!playbookPageForm}
                                carousel={carousel}
                                fullWidth={carousel ? true : false}
                                pageOptions={pageOptions}
                                pptView={pptView}
                            />
                        ),
                        [ChartType.RETROSPECTIVE]: (
                            <Retrospective
                                cards={playbookCards}
                                page={page}
                                size={worksheetSize}
                                pptView={pptView}
                                slideNumber={slideNumber}
                                pageOptions={pageOptions}
                            />
                        ),
                        [ChartType.RISK_ANALYSIS]: (
                            <RiskAnalysis
                                cards={playbookCards ?? []}
                                page={page}
                                size={worksheetSize}
                                pptView={pptView}
                                pageOptions={pageOptions}
                            />
                        ),
                        [ChartType.RADAR]: (
                            <RadarPage
                                cards={playbookCards}
                                page={page}
                                thumbnail={!playbookPageForm}
                                carousel={carousel}
                                fullWidth={carousel ? true : false}
                                pageOptions={pageOptions}
                                radarOptions={radarOptions}
                                setRadarOptions={setRadarOptions}
                                pptView={pptView}
                            />
                        ),
                        [ChartType.PORTERS_FIVE_FORCES]: (
                            <PortersFiveForces
                                cards={playbookCards ?? []}
                                page={page}
                                size={worksheetSize}
                                pptView={pptView}
                                pageOptions={pageOptions}
                            />
                        ),
                        [ChartType.PESTLE_ANALYSIS]: (
                            <PestleAnalysis
                                cards={playbookCards ?? []}
                                page={page}
                                size={worksheetSize}
                                pptView={pptView}
                                pageOptions={pageOptions}
                            />
                        ),
                        [ChartType.BLANK]: <Box></Box>,
                    }[chartType]}
            </StyledPlaybookScrollContainer>
            {edit !== true &&
                itemSelected !== true &&
                (permissions.includes(UserPermissions.DELETE) ||
                    permissions.includes(UserPermissions.EDIT)) && (
                    <PlaybookMenu
                        id={page?.id ?? ''}
                        setMenu={setMenu}
                        orderPages={orderPages}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleDelete={handleDelete}
                        setEditMode={setEditMode}
                        first={first}
                        last={last}
                        iconColor="rgba(0, 0, 0, 0.54)"
                    />
                )}
        </StyledPlaybookPage>
    );
};

export default ChartPage;
