import { Card } from '../../API';
import { CardComponentData } from '../cards/cardTypes';
import Highcharts from 'highcharts';
import 'highcharts/modules/draggable-points';
import { RadarCardScore } from './RadarChart';

interface ExtendedPointOptions extends Highcharts.PointOptionsObject {
    originalCard: Card;
    scoreDefinitionId: string;
}
interface ExtendedPointOptions extends Highcharts.PointOptionsObject {
    originalCard: Card;
    scoreDefinitionId: string;
}

export interface Dataset {
    label: string;
    data: number[];
    backgroundColor?: string;
    borderColor?: string;
    borderWidth?: number;
    originalCard?: Card;
}

interface ChartData {
    labels: string[];
    datasets: Dataset[];
}

export const getRadarChartOptions = (
    radarChartData: ChartData,
    seriesData: Highcharts.SeriesAreaOptions[],
    handleMultiSelect: (card: Card) => void,
    activeCardIds: (string | null)[] | undefined,
    handleCardClick:
        | ((card: Card | CardComponentData, side: string) => void)
        | undefined,
    updateScores: (card: Card, cardScore: RadarCardScore) => Promise<void>
): Highcharts.Options => {
    const ACTIVE_COLOR = '#3d74de';
    const options = {
        chart: {
            polar: true,
            height: '100%',
            type: 'area',
            animation: false,
            events: {
                load: function (
                    this: Highcharts.Chart & {
                        draggingPoint?: Highcharts.Point;
                        draggingInitialValue?: number;
                        draggingInitialPixelDistance?: number;
                    }
                ) {
                    const chart = this;
                    chart.draggingPoint = undefined;
                    chart.draggingInitialValue = undefined;
                    chart.draggingInitialPixelDistance = undefined;

                    Highcharts.addEvent(
                        chart.container as HTMLElement,
                        'mousedown',
                        function (e: MouseEvent) {
                            const normalizedEvent = chart.pointer.normalize(e);
                            if (chart.hoverPoint) {
                                chart.draggingPoint = chart.hoverPoint;

                                let center:
                                    | [number, number, number]
                                    | undefined = chart.xAxis[0].center;
                                if (!center) {
                                    const centerX =
                                        chart.plotLeft + chart.plotWidth / 2;
                                    const centerY =
                                        chart.plotTop + chart.plotHeight / 2;
                                    const radius =
                                        Math.min(
                                            chart.plotWidth,
                                            chart.plotHeight
                                        ) / 2;
                                    center = [centerX, centerY, radius];
                                }
                                const dx = normalizedEvent.chartX - center[0];
                                const dy = normalizedEvent.chartY - center[1];
                                const initialPixelDistance = Math.sqrt(
                                    dx * dx + dy * dy
                                );
                                chart.draggingStartPixelDistance =
                                    initialPixelDistance;

                                chart.draggingStartValue = chart.draggingPoint
                                    .y as number;
                                chart.dragStartX = normalizedEvent.chartX;
                                chart.dragStartY = normalizedEvent.chartY;
                                chart.hasDragged = false;
                            } else {
                                console.log(
                                    'No hover point found on mousedown'
                                );
                            }
                        }
                    );

                    Highcharts.addEvent(
                        chart.container as HTMLElement,
                        'mousemove',
                        function (e: MouseEvent) {
                            if (
                                chart.draggingPoint &&
                                chart.xAxis[0] &&
                                chart.yAxis[0] &&
                                chart.draggingStartValue !== undefined &&
                                chart.draggingStartPixelDistance !== undefined
                            ) {
                                const normalizedEvent =
                                    chart.pointer.normalize(e);
                                if (
                                    chart.dragStartX === undefined ||
                                    chart.dragStartY === undefined
                                ) {
                                    return;
                                }
                                const dragDistance = Math.sqrt(
                                    Math.pow(
                                        normalizedEvent.chartX -
                                            chart.dragStartX,
                                        2
                                    ) +
                                        Math.pow(
                                            normalizedEvent.chartY -
                                                chart.dragStartY,
                                            2
                                        )
                                );
                                if (!chart.hasDragged && dragDistance < 5) {
                                    return;
                                }
                                chart.hasDragged = true;
                                e.preventDefault();
                                let center:
                                    | [number, number, number]
                                    | undefined = chart.xAxis[0].center;
                                if (!center) {
                                    const centerX =
                                        chart.plotLeft + chart.plotWidth / 2;
                                    const centerY =
                                        chart.plotTop + chart.plotHeight / 2;
                                    const radius =
                                        Math.min(
                                            chart.plotWidth,
                                            chart.plotHeight
                                        ) / 2;
                                    center = [centerX, centerY, radius];
                                }
                                const dx = normalizedEvent.chartX - center[0];
                                const dy = normalizedEvent.chartY - center[1];
                                const currentPixelDistance = Math.sqrt(
                                    dx * dx + dy * dy
                                );

                                const pixelDelta =
                                    currentPixelDistance -
                                    chart.draggingStartPixelDistance;
                                const axis = chart.yAxis[0];
                                const min = axis.min ?? 0;
                                const max = axis.max ?? 10;

                                const radialDelta =
                                    (pixelDelta / center[2]) * (max - min);
                                let newRadialValue =
                                    chart.draggingStartValue + radialDelta;
                                if (axis.reversed) {
                                    newRadialValue =
                                        chart.draggingStartValue - radialDelta;
                                }
                                newRadialValue = Math.min(
                                    Math.max(newRadialValue, min),
                                    max
                                );

                                chart.draggingPoint.update(
                                    newRadialValue,
                                    true,
                                    false
                                );
                                chart.draggingStartValue = newRadialValue;
                                chart.draggingStartPixelDistance =
                                    currentPixelDistance;
                            }
                        }
                    );

                    Highcharts.addEvent(
                        chart.container as HTMLElement,
                        'mouseup',
                        function (e: MouseEvent) {
                            if (!chart.hasDragged) {
                                chart.draggingPoint = undefined;
                                chart.draggingStartValue = undefined;
                                chart.draggingStartPixelDistance = undefined;
                                return;
                            }

                            if (
                                chart.draggingPoint &&
                                chart.xAxis[0] &&
                                chart.yAxis[0] &&
                                chart.draggingStartValue !== undefined &&
                                chart.draggingStartPixelDistance !== undefined
                            ) {
                                const finalRadialValue = Math.round(
                                    chart.draggingPoint.y!
                                );
                                chart.draggingPoint.update(
                                    finalRadialValue,
                                    true,
                                    false
                                );
                                const chartPoint = chart.draggingPoint
                                    .options as ExtendedPointOptions;
                                updateScores(chartPoint.originalCard, {
                                    value: finalRadialValue,
                                    scoreDefinition:
                                        chartPoint.scoreDefinitionId,
                                });
                            }
                            chart.draggingPoint = undefined;
                            chart.draggingStartValue = undefined;
                            chart.draggingStartPixelDistance = undefined;
                        }
                    );
                },
            },
        },
        title: { text: '' },
        area: {
            trackByArea: true,
        },
        pane: {
            size: '80%',
        },
        xAxis: {
            categories: radarChartData.labels as string[],
            tickmarkPlacement: 'on' as Highcharts.OptionsTickmarkPlacementValue,
            lineWidth: 0,
        },
        yAxis: {
            gridLineInterpolation:
                'polygon' as Highcharts.OptionsGridLineInterpolationValue,
            lineWidth: 0,
            min: 0,
            max: 10,
        },
        tooltip: {
            shared: false,
        },
        legend: {
            enabled: false,
        },
        credits: {
            enabled: false,
        },
        plotOptions: {
            area: {
                trackByArea: true,
                stickyTracking: false,
            },
            series: {
                marker: {
                    symbol: 'circle',
                },
                animation: {
                    duration: 0,
                },
                dragDrop: {
                    draggableY: true,
                },
                point: {
                    events: {
                        click: function (this: Highcharts.Point) {
                            const point = this as Highcharts.Point & {
                                lastTapTime?: number;
                            };
                            const now = new Date().getTime();
                            const doubleClickThreshold = 300;
                            const originalCard = (
                                this.options as ExtendedPointOptions
                            ).originalCard;
                            if (
                                point.lastTapTime &&
                                now - point.lastTapTime <
                                    doubleClickThreshold &&
                                handleCardClick
                            ) {
                                handleCardClick(originalCard, 'right');
                                point.lastTapTime = undefined;
                            } else {
                                point.lastTapTime = now;
                                setTimeout(() => {
                                    if (point.lastTapTime === now) {
                                        handleMultiSelect(originalCard);
                                    }
                                }, doubleClickThreshold);
                            }
                        },

                        mouseOver: function (this: Highcharts.Point) {
                            const point = this;
                            if (point.series.graph) {
                                if (!point.series.originalStroke) {
                                    point.series.originalStroke = String(
                                        point.series.graph.attr('stroke')
                                    );
                                }
                                point.series.graph.attr({
                                    stroke: ACTIVE_COLOR,
                                    'stroke-width': 3,
                                });
                            }
                        },

                        mouseOut: function (this: Highcharts.Point) {
                            const point = this;
                            const originalCard = (
                                this.options as ExtendedPointOptions
                            ).originalCard;

                            if (point.series.graph) {
                                const isActive = activeCardIds?.includes(
                                    originalCard?.id
                                );

                                point.series.graph.attr({
                                    stroke: isActive
                                        ? ACTIVE_COLOR
                                        : point.series.originalStroke ||
                                          undefined,
                                    'stroke-width': isActive ? 3 : 1,
                                });
                            }
                        },
                    },
                },
            },
        },

        series: seriesData,
    };
    return options;
};
