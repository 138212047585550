import {
    BaseSyntheticEvent,
    MouseEventHandler,
    SyntheticEvent,
    useContext,
    useEffect,
    useState,
} from 'react';
import { Menu, MenuItem, useTheme } from '@mui/material';
import { UserPermissions } from '../../globals';
import { AppContext } from '../contexts';
import { PageIdentifier } from './cardTypes';

interface CardMenuData {
    id: string;
    organisation: string;
}

export interface MenuItem {
    text: string;
    action: () => void;
}

interface CardMenuProps {
    data: CardMenuData;
    handleEdit?: MouseEventHandler<any>;
    handleDelete?: (id: string, organisation: string) => void;
    handleCopy?: MouseEventHandler<any>;
    onDelete?: () => void;
    menuItems?: MenuItem[];
    anchor?: HTMLElement;
    setMenu: (e: React.MouseEvent<HTMLElement> | null) => void;
    permissions?: UserPermissions[];
    handleSecondaryCopy?: () => void;
    handleSecondaryDelete?: (id: string, organisation: string) => void;
    handleSecondaryEdit?: () => void;
    multiDelete?: boolean;
    pageIdentifier?: PageIdentifier | undefined;
}

const CardMenu = ({
    onDelete,
    handleEdit,
    handleDelete,
    handleCopy,
    menuItems,
    data,
    anchor,
    permissions,
    setMenu,
    handleSecondaryCopy,
    handleSecondaryDelete,
    handleSecondaryEdit,
    multiDelete,
    pageIdentifier,
}: CardMenuProps) => {
    const handleClose = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        setMenu(null);
    };

    const menuOpen = Boolean(anchor);
    const theme = useTheme();

    return (
        <Menu
            id="positioned-menu"
            aria-labelledby="menu-button"
            anchorEl={anchor}
            open={menuOpen}
            onClose={(e: BaseSyntheticEvent) => handleClose(e)}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            sx={{
                '& .MuiPaper-root': {
                    boxShadow: `1px 1px 1px 1px ${theme.palette.background.paper}`,
                    backgroundColor: theme.palette.background.default,
                    zIndex: 1600,
                },
                mt: !multiDelete ? 2 : 0,
            }}
        >
            {menuItems &&
                menuItems.length > 0 &&
                menuItems.map((item) => (
                    <MenuItem
                        key={item.text}
                        onClick={(e) => {
                            e.stopPropagation();
                            setMenu(null);
                            item.action();
                        }}
                    >
                        {item.text}
                    </MenuItem>
                ))}
            {!!handleSecondaryEdit && !multiDelete && (
                <MenuItem onClick={handleSecondaryEdit}>Edit</MenuItem>
            )}
            {!!handleCopy && !multiDelete && (
                <MenuItem
                    onClick={(e) => {
                        setMenu(null);
                        handleCopy(e);
                    }}
                >
                    Copy
                </MenuItem>
            )}
            {!!handleSecondaryCopy && !multiDelete && (
                <MenuItem
                    onClick={(e) => {
                        e.stopPropagation();
                        setMenu(null);
                        handleSecondaryCopy();
                    }}
                >
                    Copy
                </MenuItem>
            )}
            {!!handleDelete && pageIdentifier !== PageIdentifier.WORKSHEET && (
                <MenuItem
                    data-testid="primary-delete"
                    disabled={!permissions?.includes(UserPermissions.DELETE)}
                    onClick={(e) => {
                        e.stopPropagation();
                        if (permissions?.includes(UserPermissions.DELETE)) {
                            handleDelete(data.id, data.organisation);
                            setMenu(null);
                        }
                    }}
                >
                    Delete
                </MenuItem>
            )}
            {!!handleSecondaryDelete && !multiDelete && (
                <MenuItem
                    data-testid="secondary-delete"
                    disabled={!permissions?.includes(UserPermissions.DELETE)}
                    onClick={() => {
                        if (permissions?.includes(UserPermissions.DELETE)) {
                            handleSecondaryDelete(data.id, data.organisation);
                            setMenu(null);
                        }
                    }}
                >
                    Delete
                </MenuItem>
            )}
        </Menu>
    );
};

export default CardMenu;
