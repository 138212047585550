import { Box } from '@mui/material';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import StyledCardContent from './CardContent';
import CreateCard from '../forms/CreateCard';
import CardComponent from './CardComponent';
import CardDetails from './CardDetails';
import CardInfo from './CardInfo';
import CardScores from './CardScores';
import { CardComponentData, CardPage, PageIdentifier } from './cardTypes';
import { Card, CardSet } from '../../API';
import CardRelationships from './CardRelationships';
import CardActivity from './CardActivity';
import CardComments from './CardComments';

export const modalBackdropStyles = {
    backdropFilter: 'blur(5px)',
};

export const modalStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    //boxShadow: 24,
    maxWidth: {
        xs: '95vw',
        lg: '48rem',
    },
    width: '100%',
    zIndex: 14000,
};

interface CardModalProps {
    activeCardId?: string;
    editableCard?: Card | null;
    resetCard: () => void;
    handleModalClose: () => void;
    cardSet: CardSet | null;
    handleClose: () => void;
    data: CardComponentData;
    card: Card;
    handleClick: (cardId: string, cardPage?: CardPage) => void;
    pageIdentifier?: PageIdentifier;
    activeCardPage: CardPage;
    handleEdit: (id: string) => void;
    refreshCard?: (id: string, organisation: string) => Promise<void>;
    createCardRelationship?: (target: string, source: string) => void;
    slideIn?: boolean;
    handleDelete?: (
        id: string,
        organisation: string,
        secondaryDelete?: boolean
    ) => void;
    handleRemove?: (cardId: string) => void;
    handleCopy?: (cardId: string) => void;
    handleConvert?: (cardId: string) => void;
    setRelationCard?: (card: Card) => void;
    setCardFrom?: (card: Card[]) => void;
    cardsObject?: {
        [key: string]: Card;
    };
}

const CardSetModal = ({
    activeCardId,
    editableCard,
    resetCard,
    handleModalClose,
    cardSet,
    handleClose,
    pageIdentifier,
    card,
    data,
    handleClick,
    activeCardPage,
    handleEdit,
    refreshCard,
    slideIn,
    handleDelete,
    handleRemove,
    handleConvert,
    handleCopy,
    setCardFrom,
    createCardRelationship,
    cardsObject,
}: CardModalProps) => {
    return (
        <StyledModal
            key="modal"
            open={!!activeCardId || !!editableCard}
            onClose={!!activeCardId ? resetCard : handleModalClose}
            sx={modalBackdropStyles}
        >
            <Box>
                {!!editableCard && (
                    <ModalContainer>
                        <CreateCard
                            card={editableCard}
                            handleClose={handleClose}
                            close={handleClose}
                        />
                    </ModalContainer>
                )}

                {!!activeCardId && (
                    <>
                        <Box
                            key="modal"
                            sx={{ ...modalStyles, borderRadius: '1rem' }}
                            className={slideIn ? 'refresh-modal' : ''}
                        >
                            <CardComponent
                                data={data}
                                onClick={(
                                    cardId: string,
                                    cardPage?: CardPage
                                ) => handleClick(cardId, cardPage)}
                                handleEdit={handleEdit}
                                handleClose={handleClose}
                                showPage={activeCardPage}
                                pageIdentifier={pageIdentifier}
                                expanded
                                handleDelete={handleDelete}
                                handleRemove={handleRemove}
                                handleConvert={handleConvert}
                                handleCopy={handleCopy}
                                setCardFrom={setCardFrom}
                                createCardRelationship={createCardRelationship}
                                cardsObject={cardsObject}
                            >
                                {!!activeCardPage && (
                                    <StyledCardContent expanded={true}>
                                        {
                                            {
                                                [CardPage.DETAILS]: (
                                                    <CardDetails
                                                        card={card}
                                                        isCard={true}
                                                        onUpdate={refreshCard}
                                                    />
                                                ),
                                                [CardPage.SCORES]: (
                                                    <CardScores
                                                        card={card}
                                                        onUpdate={refreshCard}
                                                    />
                                                ),
                                                [CardPage.INFO]: (
                                                    <CardInfo
                                                        card={card}
                                                        onUpdate={refreshCard}
                                                    />
                                                ),
                                                [CardPage.RELATIONSHIPS]: (
                                                    <CardRelationships
                                                        card={card}
                                                    />
                                                ),
                                                [CardPage.ACTIVITY]: (
                                                    <CardActivity card={card} />
                                                ),
                                                [CardPage.COMMENTS]: (
                                                    <CardComments card={card} />
                                                ),
                                            }[activeCardPage]
                                        }
                                    </StyledCardContent>
                                )}
                            </CardComponent>
                        </Box>
                    </>
                )}
            </Box>
        </StyledModal>
    );
};

export default CardSetModal;
