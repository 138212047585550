import { Box, useTheme } from '@mui/material';
import Search from '@mui/icons-material/Search';

const SearchBox = ({
    setSearchTerm,
    searchTerm,
}: {
    setSearchTerm: React.Dispatch<React.SetStateAction<string | null>>;
    searchTerm?: string | null;
}) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                position: 'relative',
                display: 'inline-block',
                margin: '4px 2px',
                height: '40px',
                width: '300px',
                verticalAlign: 'bottom',
                border: 'solid 1px #cfc8c8',
                borderRadius: '50px',
                padding: '6px 10px 6px 35px',
                postion: 'relative',
            }}
        >
            <form
                action="/search"
                method="get"
                onSubmit={(e) => e.preventDefault()}
            >
                <input
                    type="search"
                    className="searchBox"
                    placeholder="Search by name"
                    onChange={(event) => setSearchTerm(event.target.value)}
                    style={{
                        border: '0px',
                        width: '100%',
                        background: 'none',
                        color:
                            theme.palette.mode === 'dark' ? '#fff' : 'inherit',
                    }}
                    {...(searchTerm != null && {
                        value: searchTerm,
                    })}
                />
                <label
                    style={{
                        color: 'rgb(158, 158, 158)',
                    }}
                    htmlFor="searchleft"
                >
                    <Search
                        style={{
                            width: '30px',
                            height: '30px',
                            color: '#919191',
                            position: 'absolute',
                            left: '6px',
                        }}
                    />
                </label>
            </form>
        </Box>
    );
};

export default SearchBox;
