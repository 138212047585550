import { Modal, styled } from '@mui/material';

export const StyledModal = styled(Modal)<any>(({ transparent, theme }) => ({
    backdropFilter: transparent ? 'blur(0px)' : 'blur(5px)',
}));

export const CardStyledModal = styled(StyledModal)(() => ({
    maxWidth: '33rem',
}));

export const FormStyledModal = styled(StyledModal)(() => ({
    maxWidth: '35rem',
}));

export const SwotModal = styled(StyledModal)(() => ({
    backdropFilter: 'blur(5px)',
}));
