/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const aiRequest = /* GraphQL */ `query AiRequest($params: AiRequestParams) {
  aiRequest(params: $params) {
    content
    threadId
    __typename
  }
}
` as GeneratedQuery<APITypes.AiRequestQueryVariables, APITypes.AiRequestQuery>;
export const getCardSets = /* GraphQL */ `query GetCardSets(
  $sort: CardSort
  $limit: Int
  $nextToken: String
  $filter: CardSetFilter
) {
  getCardSets(
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    filter: $filter
  ) {
    items {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardSetsQueryVariables,
  APITypes.GetCardSetsQuery
>;
export const getWorkbooks = /* GraphQL */ `query GetWorkbooks(
  $sort: CardSort
  $limit: Int
  $nextToken: String
  $filter: WorkbookFilter
) {
  getWorkbooks(
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    filter: $filter
  ) {
    items {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorkbooksQueryVariables,
  APITypes.GetWorkbooksQuery
>;
export const getPlaybooks = /* GraphQL */ `query GetPlaybooks(
  $sort: CardSort
  $limit: Int
  $nextToken: String
  $filter: WorkbookFilter
) {
  getPlaybooks(
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    filter: $filter
  ) {
    items {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlaybooksQueryVariables,
  APITypes.GetPlaybooksQuery
>;
export const getAuditEntry = /* GraphQL */ `query GetAuditEntry($contextId: ID!, $createdAt: String!, $id: ID!) {
  getAuditEntry(contextId: $contextId, createdAt: $createdAt, id: $id) {
    contextId
    createdAt
    id
    organisation
    deleteAfter
    operation
    subContext
    contextType
    subContextType
    user
    system
    old
    new
    batchId
    eventContext
    eventContextId
    relationshipType
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAuditEntryQueryVariables,
  APITypes.GetAuditEntryQuery
>;
export const listAuditEntries = /* GraphQL */ `query ListAuditEntries(
  $contextId: ID
  $createdAtId: ModelAuditEntryPrimaryCompositeKeyConditionInput
  $filter: ModelAuditEntryFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listAuditEntries(
    contextId: $contextId
    createdAtId: $createdAtId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      contextId
      createdAt
      id
      organisation
      deleteAfter
      operation
      subContext
      contextType
      subContextType
      user
      system
      old
      new
      batchId
      eventContext
      eventContextId
      relationshipType
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAuditEntriesQueryVariables,
  APITypes.ListAuditEntriesQuery
>;
export const auditEntriesByOrganisation = /* GraphQL */ `query AuditEntriesByOrganisation(
  $organisation: ID!
  $createdAtId: ModelAuditEntryByOrganisationCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAuditEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  auditEntriesByOrganisation(
    organisation: $organisation
    createdAtId: $createdAtId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      contextId
      createdAt
      id
      organisation
      deleteAfter
      operation
      subContext
      contextType
      subContextType
      user
      system
      old
      new
      batchId
      eventContext
      eventContextId
      relationshipType
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AuditEntriesByOrganisationQueryVariables,
  APITypes.AuditEntriesByOrganisationQuery
>;
export const auditEntriesByUser = /* GraphQL */ `query AuditEntriesByUser(
  $user: String!
  $createdAtId: ModelAuditEntryByUserCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAuditEntryFilterInput
  $limit: Int
  $nextToken: String
) {
  auditEntriesByUser(
    user: $user
    createdAtId: $createdAtId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      contextId
      createdAt
      id
      organisation
      deleteAfter
      operation
      subContext
      contextType
      subContextType
      user
      system
      old
      new
      batchId
      eventContext
      eventContextId
      relationshipType
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AuditEntriesByUserQueryVariables,
  APITypes.AuditEntriesByUserQuery
>;
export const getCardType = /* GraphQL */ `query GetCardType($id: ID!) {
  getCardType(id: $id) {
    id
    name
    cardCategoryID
    toCards {
      items {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      nextToken
      __typename
    }
    attributeDefinitions {
      id
      name
      attributeType
      configuration
      __typename
    }
    scoreDefinitions {
      id
      name
      shortName
      description
      scoreType
      minimumValue
      maximumValue
      target
      __typename
    }
    defaultScoreDefinitionId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardTypeQueryVariables,
  APITypes.GetCardTypeQuery
>;
export const listCardTypes = /* GraphQL */ `query ListCardTypes(
  $filter: ModelCardTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listCardTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCardTypesQueryVariables,
  APITypes.ListCardTypesQuery
>;
export const cardTypesByCardCategoryID = /* GraphQL */ `query CardTypesByCardCategoryID(
  $cardCategoryID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCardTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  cardTypesByCardCategoryID(
    cardCategoryID: $cardCategoryID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardTypesByCardCategoryIDQueryVariables,
  APITypes.CardTypesByCardCategoryIDQuery
>;
export const getCardCategory = /* GraphQL */ `query GetCardCategory($id: ID!) {
  getCardCategory(id: $id) {
    id
    name
    description
    toCardTypes {
      items {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardCategoryQueryVariables,
  APITypes.GetCardCategoryQuery
>;
export const listCardCategories = /* GraphQL */ `query ListCardCategories(
  $filter: ModelCardCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCardCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCardCategoriesQueryVariables,
  APITypes.ListCardCategoriesQuery
>;
export const getCard = /* GraphQL */ `query GetCard($organisation: ID!, $id: ID!) {
  getCard(organisation: $organisation, id: $id) {
    organisation
    id
    owner
    createdBy
    editors
    orgEdit
    name
    capitalName
    toCardCategory {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    description
    attributes {
      attributeDefinitionID
      value
      __typename
    }
    toAttributes {
      items {
        cardId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        attributeDefinitionId
        organisation
        value
        refToCardId
        refCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        refToCardSetId
        refCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toScoreData {
      items {
        cardId
        id
        scoreDefinitionId
        data {
          value
          date
          comment
          commentDate
          commentAuthor
          __typename
        }
        organisation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toCardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    toCardSets {
      items {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    updatedBy
    deleteAfter
    _auditContextType
    _auditContextId
    createdFromId
    createdFromIds
    cardTypeToCardsId
    cardToCardCategoryId
    cardToCardTypeId
    __typename
  }
}
` as GeneratedQuery<APITypes.GetCardQueryVariables, APITypes.GetCardQuery>;
export const cardsOrderedByUpdatedAt = /* GraphQL */ `query CardsOrderedByUpdatedAt(
  $organisation: ID!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCardFilterInput
  $limit: Int
  $nextToken: String
) {
  cardsOrderedByUpdatedAt(
    organisation: $organisation
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardsOrderedByUpdatedAtQueryVariables,
  APITypes.CardsOrderedByUpdatedAtQuery
>;
export const cardsOrderedByName = /* GraphQL */ `query CardsOrderedByName(
  $organisation: ID!
  $capitalName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCardFilterInput
  $limit: Int
  $nextToken: String
) {
  cardsOrderedByName(
    organisation: $organisation
    capitalName: $capitalName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardsOrderedByNameQueryVariables,
  APITypes.CardsOrderedByNameQuery
>;
export const cardsOrderedByCreatedAt = /* GraphQL */ `query CardsOrderedByCreatedAt(
  $organisation: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCardFilterInput
  $limit: Int
  $nextToken: String
) {
  cardsOrderedByCreatedAt(
    organisation: $organisation
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardsOrderedByCreatedAtQueryVariables,
  APITypes.CardsOrderedByCreatedAtQuery
>;
export const getCardAttribute = /* GraphQL */ `query GetCardAttribute($cardId: ID!, $attributeDefinitionId: ID!) {
  getCardAttribute(
    cardId: $cardId
    attributeDefinitionId: $attributeDefinitionId
  ) {
    cardId
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    attributeDefinitionId
    organisation
    value
    refToCardId
    refCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    refToCardSetId
    refCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardAttributeQueryVariables,
  APITypes.GetCardAttributeQuery
>;
export const listCardAttributes = /* GraphQL */ `query ListCardAttributes(
  $cardId: ID
  $attributeDefinitionId: ModelIDKeyConditionInput
  $filter: ModelCardAttributeFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCardAttributes(
    cardId: $cardId
    attributeDefinitionId: $attributeDefinitionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      cardId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      attributeDefinitionId
      organisation
      value
      refToCardId
      refCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      refToCardSetId
      refCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCardAttributesQueryVariables,
  APITypes.ListCardAttributesQuery
>;
export const cardAttributesReferencingCard = /* GraphQL */ `query CardAttributesReferencingCard(
  $refToCardId: ID!
  $attributeDefinitionId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCardAttributeFilterInput
  $limit: Int
  $nextToken: String
) {
  cardAttributesReferencingCard(
    refToCardId: $refToCardId
    attributeDefinitionId: $attributeDefinitionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      cardId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      attributeDefinitionId
      organisation
      value
      refToCardId
      refCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      refToCardSetId
      refCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardAttributesReferencingCardQueryVariables,
  APITypes.CardAttributesReferencingCardQuery
>;
export const cardAttributesReferencingCardSet = /* GraphQL */ `query CardAttributesReferencingCardSet(
  $refToCardSetId: ID!
  $attributeDefinitionId: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCardAttributeFilterInput
  $limit: Int
  $nextToken: String
) {
  cardAttributesReferencingCardSet(
    refToCardSetId: $refToCardSetId
    attributeDefinitionId: $attributeDefinitionId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      cardId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      attributeDefinitionId
      organisation
      value
      refToCardId
      refCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      refToCardSetId
      refCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardAttributesReferencingCardSetQueryVariables,
  APITypes.CardAttributesReferencingCardSetQuery
>;
export const getScoreData = /* GraphQL */ `query GetScoreData($cardId: ID!, $scoreDefinitionId: ID!) {
  getScoreData(cardId: $cardId, scoreDefinitionId: $scoreDefinitionId) {
    cardId
    id
    scoreDefinitionId
    data {
      value
      date
      comment
      commentDate
      commentAuthor
      __typename
    }
    organisation
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetScoreDataQueryVariables,
  APITypes.GetScoreDataQuery
>;
export const listScoreData = /* GraphQL */ `query ListScoreData(
  $cardId: ID
  $scoreDefinitionId: ModelIDKeyConditionInput
  $filter: ModelScoreDataFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listScoreData(
    cardId: $cardId
    scoreDefinitionId: $scoreDefinitionId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListScoreDataQueryVariables,
  APITypes.ListScoreDataQuery
>;
export const scoreDataByCardId = /* GraphQL */ `query ScoreDataByCardId(
  $cardId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelScoreDataFilterInput
  $limit: Int
  $nextToken: String
) {
  scoreDataByCardId(
    cardId: $cardId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ScoreDataByCardIdQueryVariables,
  APITypes.ScoreDataByCardIdQuery
>;
export const scoreDataByOrganisation = /* GraphQL */ `query ScoreDataByOrganisation(
  $organisation: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelScoreDataFilterInput
  $limit: Int
  $nextToken: String
) {
  scoreDataByOrganisation(
    organisation: $organisation
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ScoreDataByOrganisationQueryVariables,
  APITypes.ScoreDataByOrganisationQuery
>;
export const getCardSet = /* GraphQL */ `query GetCardSet($organisation: ID!, $id: ID!) {
  getCardSet(organisation: $organisation, id: $id) {
    organisation
    id
    type
    name
    capitalName
    description
    owner
    createdBy
    editors
    orgEdit
    toCards {
      items {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toCardCategory {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    toCardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    updatedBy
    hiddenWorksheets
    copiedFrom
    counts {
      comments
      worksheets
      cards
      __typename
    }
    processProgress {
      stageId
      progress
      __typename
    }
    cardSetToCardCategoryId
    cardSetToCardTypeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardSetQueryVariables,
  APITypes.GetCardSetQuery
>;
export const listCardSets = /* GraphQL */ `query ListCardSets(
  $organisation: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelCardSetFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCardSets(
    organisation: $organisation
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCardSetsQueryVariables,
  APITypes.ListCardSetsQuery
>;
export const cardSetsOrderedByTypeAndName = /* GraphQL */ `query CardSetsOrderedByTypeAndName(
  $organisation: ID!
  $typeCapitalName: ModelCardSetByOrgSortedByTypeAndNameCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCardSetFilterInput
  $limit: Int
  $nextToken: String
) {
  cardSetsOrderedByTypeAndName(
    organisation: $organisation
    typeCapitalName: $typeCapitalName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardSetsOrderedByTypeAndNameQueryVariables,
  APITypes.CardSetsOrderedByTypeAndNameQuery
>;
export const cardSetsOrderedByTypeAndCreated = /* GraphQL */ `query CardSetsOrderedByTypeAndCreated(
  $organisation: ID!
  $typeCreatedAt: ModelCardSetByOrgSortedByTypeAndCreatedCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCardSetFilterInput
  $limit: Int
  $nextToken: String
) {
  cardSetsOrderedByTypeAndCreated(
    organisation: $organisation
    typeCreatedAt: $typeCreatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardSetsOrderedByTypeAndCreatedQueryVariables,
  APITypes.CardSetsOrderedByTypeAndCreatedQuery
>;
export const cardSetsOrderedByTypeAndUpdated = /* GraphQL */ `query CardSetsOrderedByTypeAndUpdated(
  $organisation: ID!
  $typeUpdatedAt: ModelCardSetByOrgSortedByTypeAndUpdatedCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCardSetFilterInput
  $limit: Int
  $nextToken: String
) {
  cardSetsOrderedByTypeAndUpdated(
    organisation: $organisation
    typeUpdatedAt: $typeUpdatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardSetsOrderedByTypeAndUpdatedQueryVariables,
  APITypes.CardSetsOrderedByTypeAndUpdatedQuery
>;
export const getCardsCardSets = /* GraphQL */ `query GetCardsCardSets($organisation: ID!, $id: ID!) {
  getCardsCardSets(organisation: $organisation, id: $id) {
    organisation
    id
    cardId
    cardSetId
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardsCardSetsQueryVariables,
  APITypes.GetCardsCardSetsQuery
>;
export const cardsCardSetsByCardId = /* GraphQL */ `query CardsCardSetsByCardId(
  $cardId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCardsCardSetsFilterInput
  $limit: Int
  $nextToken: String
) {
  cardsCardSetsByCardId(
    cardId: $cardId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardsCardSetsByCardIdQueryVariables,
  APITypes.CardsCardSetsByCardIdQuery
>;
export const cardsCardSetsByCardSetId = /* GraphQL */ `query CardsCardSetsByCardSetId(
  $cardSetId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelCardsCardSetsFilterInput
  $limit: Int
  $nextToken: String
) {
  cardsCardSetsByCardSetId(
    cardSetId: $cardSetId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CardsCardSetsByCardSetIdQueryVariables,
  APITypes.CardsCardSetsByCardSetIdQuery
>;
export const getComment = /* GraphQL */ `query GetComment($contextId: ID!, $createdAt: String!, $id: ID!) {
  getComment(contextId: $contextId, createdAt: $createdAt, id: $id) {
    contextId
    id
    organisation
    contextType
    createdAt
    createdBy
    updatedBy
    message
    reactions {
      items {
        commentId
        commentContextId
        commentContextType
        commentCreatedAt
        organisation
        comment {
          contextId
          id
          organisation
          contextType
          createdAt
          createdBy
          updatedBy
          message
          updatedAt
          __typename
        }
        createdAt
        createdBy
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentQueryVariables,
  APITypes.GetCommentQuery
>;
export const listComments = /* GraphQL */ `query ListComments(
  $contextId: ID
  $createdAtId: ModelCommentPrimaryCompositeKeyConditionInput
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listComments(
    contextId: $contextId
    createdAtId: $createdAtId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentsQueryVariables,
  APITypes.ListCommentsQuery
>;
export const commentsByOrganisationAndId = /* GraphQL */ `query CommentsByOrganisationAndId(
  $organisation: ID!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCommentFilterInput
  $limit: Int
  $nextToken: String
) {
  commentsByOrganisationAndId(
    organisation: $organisation
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CommentsByOrganisationAndIdQueryVariables,
  APITypes.CommentsByOrganisationAndIdQuery
>;
export const getCommentReaction = /* GraphQL */ `query GetCommentReaction($commentId: ID!, $createdBy: String!) {
  getCommentReaction(commentId: $commentId, createdBy: $createdBy) {
    commentId
    commentContextId
    commentContextType
    commentCreatedAt
    organisation
    comment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    createdBy
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCommentReactionQueryVariables,
  APITypes.GetCommentReactionQuery
>;
export const listCommentReactions = /* GraphQL */ `query ListCommentReactions(
  $commentId: ID
  $createdBy: ModelStringKeyConditionInput
  $filter: ModelCommentReactionFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listCommentReactions(
    commentId: $commentId
    createdBy: $createdBy
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      commentId
      commentContextId
      commentContextType
      commentCreatedAt
      organisation
      comment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      createdBy
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCommentReactionsQueryVariables,
  APITypes.ListCommentReactionsQuery
>;
export const getNotification = /* GraphQL */ `query GetNotification($organisation: ID!, $createdAt: String!, $id: ID!) {
  getNotification(organisation: $organisation, createdAt: $createdAt, id: $id) {
    organisation
    createdAt
    id
    type
    entity
    userId
    deleteAfter
    cardId
    toCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSetId
    cardSetType
    toCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    cardsCardSetsId
    toCardsCardSets {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    scoreDefinitionId
    toScoreData {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookPageId
    commentContextId
    commentCreatedAt
    commentId
    toComment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    commentReactionCreatedBy
    toCommentReaction {
      commentId
      commentContextId
      commentContextType
      commentCreatedAt
      organisation
      comment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      createdBy
      updatedAt
      owner
      __typename
    }
    worksheetId
    toWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          _auditContextType
          _auditContextId
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    relationshipId
    toRelationship {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _auditContextType
      _auditContextId
      __typename
    }
    relationshipInfo
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetNotificationQueryVariables,
  APITypes.GetNotificationQuery
>;
export const listNotifications = /* GraphQL */ `query ListNotifications(
  $organisation: ID
  $createdAtId: ModelNotificationPrimaryCompositeKeyConditionInput
  $filter: ModelNotificationFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listNotifications(
    organisation: $organisation
    createdAtId: $createdAtId
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organisation
      createdAt
      id
      type
      entity
      userId
      deleteAfter
      cardId
      toCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSetId
      cardSetType
      toCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      cardsCardSetsId
      toCardsCardSets {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      scoreDefinitionId
      toScoreData {
        cardId
        id
        scoreDefinitionId
        data {
          value
          date
          comment
          commentDate
          commentAuthor
          __typename
        }
        organisation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      playbookPageId
      commentContextId
      commentCreatedAt
      commentId
      toComment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      commentReactionCreatedBy
      toCommentReaction {
        commentId
        commentContextId
        commentContextType
        commentCreatedAt
        organisation
        comment {
          contextId
          id
          organisation
          contextType
          createdAt
          createdBy
          updatedBy
          message
          updatedAt
          __typename
        }
        createdAt
        createdBy
        updatedAt
        owner
        __typename
      }
      worksheetId
      toWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      relationshipId
      toRelationship {
        organisation
        id
        type
        sourceId
        sourceType
        sourceCardId
        toSourceCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        sourceCardSetId
        toSourceCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        sourceWorksheetId
        toSourceWorksheet {
          organisation
          id
          parentId
          name
          commentary
          typeId
          ordinal
          createdAt
          createdBy
          updatedAt
          updatedBy
          options
          __typename
        }
        sourcePlaybookPageId
        toSourcePlaybookPage {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        targetId
        targetType
        targetCardId
        toTargetCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        targetCardSetId
        toTargetCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        _auditContextType
        _auditContextId
        __typename
      }
      relationshipInfo
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListNotificationsQueryVariables,
  APITypes.ListNotificationsQuery
>;
export const getPlaybook = /* GraphQL */ `query GetPlaybook($organisation: ID!, $id: ID!) {
  getPlaybook(organisation: $organisation, id: $id) {
    organisation
    id
    name
    description
    toPlaybookPages {
      items {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      nextToken
      __typename
    }
    owner
    editors
    orgEdit
    createdAt
    createdBy
    updatedAt
    updatedBy
    capitalName
    workbooks
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlaybookQueryVariables,
  APITypes.GetPlaybookQuery
>;
export const listPlaybooks = /* GraphQL */ `query ListPlaybooks(
  $organisation: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelPlaybookFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listPlaybooks(
    organisation: $organisation
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListPlaybooksQueryVariables,
  APITypes.ListPlaybooksQuery
>;
export const playbooksOrderedByName = /* GraphQL */ `query PlaybooksOrderedByName(
  $organisation: ID!
  $capitalName: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlaybookFilterInput
  $limit: Int
  $nextToken: String
) {
  playbooksOrderedByName(
    organisation: $organisation
    capitalName: $capitalName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlaybooksOrderedByNameQueryVariables,
  APITypes.PlaybooksOrderedByNameQuery
>;
export const playbooksOrderedByCreatedAt = /* GraphQL */ `query PlaybooksOrderedByCreatedAt(
  $organisation: ID!
  $createdAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlaybookFilterInput
  $limit: Int
  $nextToken: String
) {
  playbooksOrderedByCreatedAt(
    organisation: $organisation
    createdAt: $createdAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlaybooksOrderedByCreatedAtQueryVariables,
  APITypes.PlaybooksOrderedByCreatedAtQuery
>;
export const playbooksOrderedByUpdatedAt = /* GraphQL */ `query PlaybooksOrderedByUpdatedAt(
  $organisation: ID!
  $updatedAt: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlaybookFilterInput
  $limit: Int
  $nextToken: String
) {
  playbooksOrderedByUpdatedAt(
    organisation: $organisation
    updatedAt: $updatedAt
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlaybooksOrderedByUpdatedAtQueryVariables,
  APITypes.PlaybooksOrderedByUpdatedAtQuery
>;
export const getPlaybookPage = /* GraphQL */ `query GetPlaybookPage($organisation: ID!, $id: ID!) {
  getPlaybookPage(organisation: $organisation, id: $id) {
    organisation
    id
    cardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    commentary
    playbookPageType
    worksheetId
    ordinal
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    workbookId
    title
    toCardSets {
      items {
        organisation
        id
        cardSetId
        cardId
        playbookPageId
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        playbookPage {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    include
    options
    createdAt
    createdBy
    updatedAt
    updatedBy
    cardSets
    workbooks
    cards
    worksheets
    playbookPageCardTypeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlaybookPageQueryVariables,
  APITypes.GetPlaybookPageQuery
>;
export const playbookPagesByPlaybookId = /* GraphQL */ `query PlaybookPagesByPlaybookId(
  $playbookId: ID!
  $ordinal: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlaybookPageFilterInput
  $limit: Int
  $nextToken: String
) {
  playbookPagesByPlaybookId(
    playbookId: $playbookId
    ordinal: $ordinal
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      cardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentary
      playbookPageType
      worksheetId
      ordinal
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      workbookId
      title
      toCardSets {
        items {
          organisation
          id
          cardSetId
          cardId
          playbookPageId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      include
      options
      createdAt
      createdBy
      updatedAt
      updatedBy
      cardSets
      workbooks
      cards
      worksheets
      playbookPageCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlaybookPagesByPlaybookIdQueryVariables,
  APITypes.PlaybookPagesByPlaybookIdQuery
>;
export const playbookPagesByWorkbookId = /* GraphQL */ `query PlaybookPagesByWorkbookId(
  $workbookId: ID!
  $ordinal: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelPlaybookPageFilterInput
  $limit: Int
  $nextToken: String
) {
  playbookPagesByWorkbookId(
    workbookId: $workbookId
    ordinal: $ordinal
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      cardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentary
      playbookPageType
      worksheetId
      ordinal
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      workbookId
      title
      toCardSets {
        items {
          organisation
          id
          cardSetId
          cardId
          playbookPageId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      include
      options
      createdAt
      createdBy
      updatedAt
      updatedBy
      cardSets
      workbooks
      cards
      worksheets
      playbookPageCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlaybookPagesByWorkbookIdQueryVariables,
  APITypes.PlaybookPagesByWorkbookIdQuery
>;
export const getPlaybookPageCardSets = /* GraphQL */ `query GetPlaybookPageCardSets($organisation: ID!, $id: ID!) {
  getPlaybookPageCardSets(organisation: $organisation, id: $id) {
    organisation
    id
    cardSetId
    cardId
    playbookPageId
    cardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    playbookPage {
      organisation
      id
      cardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentary
      playbookPageType
      worksheetId
      ordinal
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      workbookId
      title
      toCardSets {
        items {
          organisation
          id
          cardSetId
          cardId
          playbookPageId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      include
      options
      createdAt
      createdBy
      updatedAt
      updatedBy
      cardSets
      workbooks
      cards
      worksheets
      playbookPageCardTypeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlaybookPageCardSetsQueryVariables,
  APITypes.GetPlaybookPageCardSetsQuery
>;
export const playbookPageCardSetsByCardSetId = /* GraphQL */ `query PlaybookPageCardSetsByCardSetId(
  $cardSetId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPlaybookPageCardSetsFilterInput
  $limit: Int
  $nextToken: String
) {
  playbookPageCardSetsByCardSetId(
    cardSetId: $cardSetId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      cardSetId
      cardId
      playbookPageId
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      playbookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlaybookPageCardSetsByCardSetIdQueryVariables,
  APITypes.PlaybookPageCardSetsByCardSetIdQuery
>;
export const playbookPageCardSetsByCardId = /* GraphQL */ `query PlaybookPageCardSetsByCardId(
  $cardId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPlaybookPageCardSetsFilterInput
  $limit: Int
  $nextToken: String
) {
  playbookPageCardSetsByCardId(
    cardId: $cardId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      cardSetId
      cardId
      playbookPageId
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      playbookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlaybookPageCardSetsByCardIdQueryVariables,
  APITypes.PlaybookPageCardSetsByCardIdQuery
>;
export const playbookPageCardSetsByPlaybookPageId = /* GraphQL */ `query PlaybookPageCardSetsByPlaybookPageId(
  $playbookPageId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelPlaybookPageCardSetsFilterInput
  $limit: Int
  $nextToken: String
) {
  playbookPageCardSetsByPlaybookPageId(
    playbookPageId: $playbookPageId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      cardSetId
      cardId
      playbookPageId
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      playbookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.PlaybookPageCardSetsByPlaybookPageIdQueryVariables,
  APITypes.PlaybookPageCardSetsByPlaybookPageIdQuery
>;
export const getRelationship = /* GraphQL */ `query GetRelationship($organisation: ID!, $id: ID!) {
  getRelationship(organisation: $organisation, id: $id) {
    organisation
    id
    type
    sourceId
    sourceType
    sourceCardId
    toSourceCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    sourceCardSetId
    toSourceCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    sourceWorksheetId
    toSourceWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          _auditContextType
          _auditContextId
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    sourcePlaybookPageId
    toSourcePlaybookPage {
      organisation
      id
      cardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentary
      playbookPageType
      worksheetId
      ordinal
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      workbookId
      title
      toCardSets {
        items {
          organisation
          id
          cardSetId
          cardId
          playbookPageId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      include
      options
      createdAt
      createdBy
      updatedAt
      updatedBy
      cardSets
      workbooks
      cards
      worksheets
      playbookPageCardTypeId
      __typename
    }
    targetId
    targetType
    targetCardId
    toTargetCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    targetCardSetId
    toTargetCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    _auditContextType
    _auditContextId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRelationshipQueryVariables,
  APITypes.GetRelationshipQuery
>;
export const listRelationships = /* GraphQL */ `query ListRelationships(
  $organisation: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelRelationshipFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listRelationships(
    organisation: $organisation
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _auditContextType
      _auditContextId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRelationshipsQueryVariables,
  APITypes.ListRelationshipsQuery
>;
export const relationshipsBySourceId = /* GraphQL */ `query RelationshipsBySourceId(
  $sourceId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRelationshipFilterInput
  $limit: Int
  $nextToken: String
) {
  relationshipsBySourceId(
    sourceId: $sourceId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _auditContextType
      _auditContextId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RelationshipsBySourceIdQueryVariables,
  APITypes.RelationshipsBySourceIdQuery
>;
export const relationshipsByTargetId = /* GraphQL */ `query RelationshipsByTargetId(
  $targetId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelRelationshipFilterInput
  $limit: Int
  $nextToken: String
) {
  relationshipsByTargetId(
    targetId: $targetId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _auditContextType
      _auditContextId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RelationshipsByTargetIdQueryVariables,
  APITypes.RelationshipsByTargetIdQuery
>;
export const getOrganisation = /* GraphQL */ `query GetOrganisation($id: ID!) {
  getOrganisation(id: $id) {
    id
    name
    owningUserProfileID
    edition
    logo
    aiKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganisationQueryVariables,
  APITypes.GetOrganisationQuery
>;
export const organisationsByOwningUserProfileID = /* GraphQL */ `query OrganisationsByOwningUserProfileID(
  $owningUserProfileID: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelOrganisationFilterInput
  $limit: Int
  $nextToken: String
) {
  organisationsByOwningUserProfileID(
    owningUserProfileID: $owningUserProfileID
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      owningUserProfileID
      edition
      logo
      aiKey
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.OrganisationsByOwningUserProfileIDQueryVariables,
  APITypes.OrganisationsByOwningUserProfileIDQuery
>;
export const getUserProfile = /* GraphQL */ `query GetUserProfile($organisation: ID!, $id: ID!) {
  getUserProfile(organisation: $organisation, id: $id) {
    organisation
    id
    email
    firstName
    lastName
    telephone
    title
    department
    location
    status
    lastStatusChange
    preferences
    avatar
    lastNotificationSeen
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserProfileQueryVariables,
  APITypes.GetUserProfileQuery
>;
export const listUserProfiles = /* GraphQL */ `query ListUserProfiles(
  $organisation: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelUserProfileFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listUserProfiles(
    organisation: $organisation
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organisation
      id
      email
      firstName
      lastName
      telephone
      title
      department
      location
      status
      lastStatusChange
      preferences
      avatar
      lastNotificationSeen
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserProfilesQueryVariables,
  APITypes.ListUserProfilesQuery
>;
export const getWorksheet = /* GraphQL */ `query GetWorksheet($organisation: ID!, $id: ID!) {
  getWorksheet(organisation: $organisation, id: $id) {
    organisation
    id
    parentId
    name
    commentary
    typeId
    ordinal
    createdAt
    createdBy
    updatedAt
    updatedBy
    toDataSources {
      items {
        organisation
        id
        type
        sourceId
        sourceType
        sourceCardId
        toSourceCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        sourceCardSetId
        toSourceCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        sourceWorksheetId
        toSourceWorksheet {
          organisation
          id
          parentId
          name
          commentary
          typeId
          ordinal
          createdAt
          createdBy
          updatedAt
          updatedBy
          options
          __typename
        }
        sourcePlaybookPageId
        toSourcePlaybookPage {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        targetId
        targetType
        targetCardId
        toTargetCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        targetCardSetId
        toTargetCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        _auditContextType
        _auditContextId
        __typename
      }
      nextToken
      __typename
    }
    options
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetWorksheetQueryVariables,
  APITypes.GetWorksheetQuery
>;
export const listWorksheets = /* GraphQL */ `query ListWorksheets(
  $organisation: ID
  $id: ModelIDKeyConditionInput
  $filter: ModelWorksheetFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listWorksheets(
    organisation: $organisation
    id: $id
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          _auditContextType
          _auditContextId
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListWorksheetsQueryVariables,
  APITypes.ListWorksheetsQuery
>;
export const worksheetsByParentId = /* GraphQL */ `query WorksheetsByParentId(
  $parentId: ID!
  $ordinal: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelWorksheetFilterInput
  $limit: Int
  $nextToken: String
) {
  worksheetsByParentId(
    parentId: $parentId
    ordinal: $ordinal
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          _auditContextType
          _auditContextId
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.WorksheetsByParentIdQueryVariables,
  APITypes.WorksheetsByParentIdQuery
>;
export const getAuditData = /* GraphQL */ `query GetAuditData(
  $contextIds: [String]
  $nextTokens: String
  $filter: AuditFilter
  $limit: Int
) {
  getAuditData(
    contextIds: $contextIds
    nextTokens: $nextTokens
    filter: $filter
    limit: $limit
  ) {
    names
    items {
      contextId
      createdAt
      id
      organisation
      deleteAfter
      operation
      subContext
      contextType
      subContextType
      user
      system
      old
      new
      batchId
      eventContext
      eventContextId
      relationshipType
      updatedAt
      __typename
    }
    nextTokens
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAuditDataQueryVariables,
  APITypes.GetAuditDataQuery
>;
export const getCardData = /* GraphQL */ `query GetCardData(
  $sort: CardSort
  $limit: Int
  $nextToken: String
  $filter: CardFilter
  $context: ID
) {
  getCardData(
    sort: $sort
    limit: $limit
    nextToken: $nextToken
    filter: $filter
    context: $context
  ) {
    items {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCardDataQueryVariables,
  APITypes.GetCardDataQuery
>;
export const getPlaybookData = /* GraphQL */ `query GetPlaybookData($playbookId: ID) {
  getPlaybookData(playbookId: $playbookId) {
    organisation
    id
    name
    description
    pages {
      id
      playbookPageType
      worksheetId
      title
      commentary
      cardTypeId
      cardSets
      workbooks
      worksheets
      cards
      allCards
      include
      options
      ordinal
      updatedAt
      createdAt
      createdBy
      __typename
    }
    cards {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    owner
    editors
    orgEdit
    workbooks
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPlaybookDataQueryVariables,
  APITypes.GetPlaybookDataQuery
>;
export const getUserNotifications = /* GraphQL */ `query GetUserNotifications(
  $nextToken: String
  $limit: Int
  $cutoffTS: String
  $filter: UserNotificationFilter
) {
  getUserNotifications(
    nextToken: $nextToken
    limit: $limit
    cutoffTS: $cutoffTS
    filter: $filter
  ) {
    names
    entries {
      contextId
      createdAt
      id
      organisation
      deleteAfter
      operation
      subContext
      contextType
      subContextType
      user
      system
      old
      new
      batchId
      eventContext
      eventContextId
      relationshipType
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserNotificationsQueryVariables,
  APITypes.GetUserNotificationsQuery
>;
