import { Box, IconButton, Typography } from '@mui/material';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { Node } from '../charts/BubbleChart';
import { Card } from '../../API';
import { useContext, useEffect, useState } from 'react';
import CardMenu, { MenuItem } from '../cards/CardMenu';
import { UserPermissions } from '../../globals';
import { CardContext } from '../cards/context';
import { AppContext, WorksheetContext } from '../contexts';
import { getUserPermissions } from '../../helpers/permissions';
import { truncateText } from '../../helpers/utils';
import { getWorkSheetCardMenuItems } from '../../helpers/worksheetCards';

interface BubbleKebabMenuProps {
    node: Node;
    card: Card;
    fullWidth: boolean;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    worksheetId?: string | null | undefined;
    permissions: UserPermissions[] | undefined;
    handleCopy: ((card: Card, worksheetId?: string) => void) | undefined;
    handleRemoveFromWorkbook?: ((card: Card) => void) | undefined;
    setCardFrom?: ((card: Card[] | null) => void) | undefined;
}

const BubbleKebabMenu = ({
    node,
    card,
    fullWidth,
    setIsModalOpen,
    worksheetId,
    permissions,
    handleCopy,
    handleRemoveFromWorkbook,
    setCardFrom
}: BubbleKebabMenuProps) => {
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const { userProfileObject, user } = useContext(AppContext);
    const {
        addCardToWorksheet,
        handleDelete,
        handleRemoveFromWorksheet,
        selectedTypeId,
        multiSelectedCards,
        setMultiSelectedCards,
    } = useContext(WorksheetContext);
    const [cardPermissions, setCardPermissions] = useState<UserPermissions[]>();

    useEffect(() => {
        const permissions = getUserPermissions(
            userProfileObject?.getUserProfile?.id ?? '',
            card
        );
        setCardPermissions(permissions);
    }, []);

    const DELETECARD: boolean = cardPermissions?.includes(UserPermissions.EDIT)
        ? true
        : false;

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e.stopPropagation();
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };

    const menuItems: MenuItem[] = getWorkSheetCardMenuItems({
        card,
        workbookPermissions: permissions,
        permissions: getUserPermissions(
            user?.tokens.idToken.payload.sub as string,
            card
        ),
        worksheetId: worksheetId || '',
        handleRemoveFromWorkbook,
        handleRemoveFromWorksheet,
        multiSelectedCards,
        handleDelete,
        setCardFrom,
        handleCopy,
    });
    return (
        <Box sx={{ p: 0, mr: '-20px' }}>
            <IconButton
                aria-label=""
                id="menu-button"
                onClick={setMenu}
                sx={{ color: '#666', width: '50px', height: '50px' }}
            >
                <MenuIcon />
            </IconButton>
            {menuAnchor && menuItems.length > 0 && (
                <CardMenu
                    permissions={permissions ?? []}
                    anchor={menuAnchor}
                    data={{
                        organisation: card.organisation || '',
                        id: card.id,
                    }}
                    menuItems={menuItems}
                    setMenu={setMenu}
                />
            )}
        </Box>
    );
};

export default BubbleKebabMenu;
