import { PropsWithChildren, useState, useMemo, useContext } from 'react';
import { Box, darken, styled } from '@mui/material';
import CardActionArea from '@mui/material/CardActionArea';

import {
    Category,
    getCategoryHex,
    getCategoryHexText,
} from '../../helpers/category';
import { getScoreColorSx } from '../../helpers/scores';
import HeaderScore from '../playbooks/HeaderScore';
import CardBase from './CardBase';
import CardSummary from './CardSummary';
import { CardComponentProps, CardComponentType, CardPage } from './cardTypes';

import { UserPermissions } from '../../globals';
import CardMenu from './CardMenu';
import CardHelp from './CardHelp';
import { Lightbox } from './CardComponent';
import { WorksheetContext } from '../contexts';
import { truncateText } from '../../helpers/utils';

const StyledLayerOne = styled('div')<{ categoryId: string }>(
    ({ categoryId }) => ({
        borderRadius: '16px',
        backgroundColor: darken(getCategoryHex(categoryId), 0.1),
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
        position: 'absolute',
        zIndex: '-1',
        width: '100%',
        height: '100%',
        top: '0.150rem',
        left: '0.250rem',
    })
);

const CardComponentSmall = (props: PropsWithChildren<CardComponentProps>) => {
    const [helpTextAnchor, setHelpTextAnchor] = useState<HTMLElement | null>();
    const {
        data,
        onClick,
        onDoubleClick,
        handleCardClick,
        showPage,
        expanded,
        multiSelectedCards,
        setMultiSelectedCards,
        handleDelete,
        handleEdit,
        handleRemove,
        handleConvert,
        setCardFrom,
        createFrom,
        handleCopy,
        handleClose,
        cardsObject,
        mediumCard,
        smallCard,
        handleSelect,
        clickedItems,
        swotGrid,
        swotColor,
        hideHelp,
        activeId,
        setActiveBottomPanelCardId,
        worksheetCard,
        onHover,
        highlighted,
        highlighted2,
        worksheetGridCard,
        headerClick,
        showMenu,
        permissions,
        worksheetMainWidth,
    } = props;

    const {
        id,
        description,
        // cardCategory,
        cardComponentType,
        cardCategoryId,
        cardToCardCategoryId,
        cardTypeId,
        cardTypeName,
        link,
        name,
        scoreDefinition,
        scoreValue,
        scoreValues,
        organisation,
        scoreName,
    } = data;
    const textColor = !!swotColor
        ? '#ffffff'
        : getCategoryHexText(cardCategoryId);
    const isSelected = useMemo(() => {
        if (clickedItems) {
            return clickedItems.some((card) => card.id === id);
        } else if (multiSelectedCards) {
            return multiSelectedCards.some((card) => card.id === id);
        }
    }, [multiSelectedCards, id]);

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();

    const {
        worksheetCards,
        setWorksheetCards,
        selectedRelatedCards,
        hoverId,
        setHoverId,
        setHoverPosition,
    } = useContext(WorksheetContext);

    const menuItems = [];

    createFrom &&
        menuItems.push({
            text: 'Create card from',
            action: () => createFrom(),
        });

    menuItems.push({
        text: 'Remove from workbook',
        action: () => handleRemove && handleRemove(id),
    });

    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e.stopPropagation();
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };

    const handleClick = (
        cardPage?: CardPage,
        event?: React.MouseEvent<HTMLElement>
    ) => {
        if (!!onClick) {
            onClick(data.id, cardPage);
        }
        if (!!handleCardClick && event) {
            const viewportWidth = worksheetMainWidth || window.innerWidth;
            const clickPosition = event.clientX;
            const side =
                clickPosition < (viewportWidth + 200) / 2 ? 'left' : 'right';
            handleCardClick(data, side);
        }
    };

    const handleHeaderClick = () => {
        if (headerClick) {
            headerClick();
        } else if (
            cardComponentType === CardComponentType.CARD &&
            setMultiSelectedCards &&
            organisation &&
            permissions?.includes(UserPermissions.DELETE)
        ) {
            setMultiSelectedCards((prevCards) => {
                const newCard = {
                    id: id,
                    organisation: organisation,
                    cardTypeId: cardsObject
                        ? cardsObject[id].toCardType.id
                        : undefined,
                    cardCategoryId: cardsObject
                        ? cardsObject[id].toCardCategory.id
                        : undefined,
                };
                const index = prevCards.findIndex((card) => card.id === id);

                if (index > -1) {
                    return [
                        ...prevCards.slice(0, index),
                        ...prevCards.slice(index + 1),
                    ];
                } else {
                    return [...prevCards, newCard];
                }
            });
        }
    };
    const setHelpTextModal = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e?.stopPropagation();
            setHelpTextAnchor(e.currentTarget);
        } else {
            setHelpTextAnchor(null);
        }
    };

    return (
        <>
            {helpTextAnchor && <Lightbox />}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                    cursor: activeId ? 'grabbing' : 'pointer',
                }}
                onClickCapture={(e) => {
                    setActiveBottomPanelCardId && e.stopPropagation();

                    setActiveBottomPanelCardId &&
                        setActiveBottomPanelCardId(id);
                }}
                {...(onHover && {
                    onMouseEnter: (event: React.MouseEvent<HTMLElement>) => {
                        onHover(id);
                    },
                    onMouseLeave: (event: React.MouseEvent<HTMLElement>) => {
                        onHover(null);
                    },
                })}
            >
                <CardBase
                    sx={{
                        ...(swotGrid && {
                            boxShadow: 'none',
                            '.MuiPaper-root ': {
                                '&.MuiCard-root': {
                                    boxShadow: 'none!important',
                                },
                            },
                        }),
                        '.MuiCardHeader-avatar': {
                            svg: {
                                width: '25px',
                                height: '25px',
                            },
                        },
                    }}
                    isSelected={isSelected}
                    worksheetCard={true}
                    worksheetGridCard={worksheetGridCard}
                    highlighted={highlighted}
                    highlighted2={highlighted2}
                    hideHelp={hideHelp}
                    hideShadow={swotGrid ? true : false}
                    mediumCard={mediumCard}
                    smallCard={smallCard}
                    workbookCard={true}
                    headerRight={
                        scoreName && (
                            <HeaderScore
                                borderColor={getScoreColorSx(
                                    data.scoreValue,
                                    data.scoreDefinition?.minimumValue,
                                    data.scoreDefinition?.maximumValue,
                                    typeof scoreValue === 'number' &&
                                        scoreValue < 0,
                                    true
                                )}
                                name={scoreName}
                                value={scoreValue}
                                type={data.scoreDefinition?.scoreType}
                                mediumCard={mediumCard}
                                smallCard={smallCard}
                                swotColor={swotColor}
                                cardTypeName={cardTypeName}
                                fadeOut={
                                    (multiSelectedCards?.length
                                        ? true
                                        : false) &&
                                    (!isSelected ?? false)
                                }
                            />
                        )
                    }
                    data={{
                        id,
                        name,
                        category: cardCategoryId as Category,
                        cardTypeName,
                        cardComponentType,
                        organisation,
                    }}
                    swotColor={swotColor}
                    expanded={expanded}
                    subHeader={showPage}
                    handleClose={handleClose}
                    handleHeaderClick={handleHeaderClick}
                    activeId={activeId}
                    {...(cardComponentType === CardComponentType.CARD &&
                        !hideHelp && {
                            setHelpTextModal,
                        })}
                    multiSelectedCards={multiSelectedCards ?? []}
                >
                    {props.children || (
                        <CardActionArea
                            {...(onDoubleClick && {
                                onDoubleClick: onDoubleClick,
                            })}
                            {...{
                                onClick:
                                    !link && !mediumCard
                                        ? (
                                              e: React.MouseEvent<HTMLElement>
                                          ) => {
                                              e.stopPropagation();
                                              handleClick(CardPage.DETAILS, e);
                                          }
                                        : handleSelect
                                        ? () => handleSelect(data)
                                        : undefined,
                            }}
                            sx={{
                                overflow: 'hidden',
                                borderRadius: 'initial',
                                cursor: activeId ? 'grabbing' : 'pointer',
                            }}
                        >
                            <CardSummary
                                description={
                                    showMenu
                                        ? truncateText(
                                              description as string,
                                              80
                                          )
                                        : description
                                }
                                name={name}
                                categoryId={cardCategoryId as Category}
                                cardTypeId={cardTypeId}
                                mediumCard={mediumCard}
                                smallCard={smallCard}
                                workbookCard
                                swotGrid={swotGrid}
                                swotColor={swotColor}
                                fadeOut={
                                    (multiSelectedCards?.length
                                        ? true
                                        : false) &&
                                    (!isSelected ?? false)
                                }
                            />
                        </CardActionArea>
                    )}

                    {cardComponentType === CardComponentType.CARD_SET && (
                        <>
                            <StyledLayerOne categoryId={cardCategoryId} />
                        </>
                    )}

                    {menuAnchor && (menuItems.length || handleDelete) && (
                        <CardMenu
                            permissions={permissions ?? []}
                            anchor={menuAnchor}
                            data={{
                                organisation: organisation || '',
                                id,
                            }}
                            {...(menuItems.length && {
                                menuItems: menuItems,
                            })}
                            handleEdit={() => handleEdit && handleEdit(id)}
                            handleDelete={handleDelete}
                            setMenu={setMenu}
                            multiDelete={isSelected}
                        />
                    )}
                    {helpTextAnchor && (
                        <CardHelp
                            anchor={helpTextAnchor}
                            setHelpTextModal={setHelpTextModal}
                            setHelpTextAnchor={setHelpTextAnchor}
                            cardTypeId={cardTypeId}
                            cardCategoryId={cardCategoryId}
                        />
                    )}
                </CardBase>
            </Box>
        </>
    );
};

export default CardComponentSmall;
