import {
    adjustAlpha,
    blendWithGrey,
    createRadarChartData,
} from '../../helpers/charts';
import { Dataset, getRadarChartOptions } from './RadarChartOptions';
import { Card, ScoreData, ScoreDefinition } from '../../API';
import { ActiveElement } from 'chart.js';
import { ChartEvent } from 'chart.js';
import { Chart } from 'chart.js';
import { CustomDataPoint } from './BubbleChart';
import { AppContext, WorksheetContext } from '../contexts';
import { CardScoreData } from '../../helpers/worksheets';
import { Box, IconButton, useTheme } from '@mui/material';
import { useDroppable } from '@dnd-kit/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';

import 'highcharts/highcharts-more';
import { getWorkSheetCardMenuItems } from '../../helpers/worksheetCards';
import { UserPermissions } from '../../globals';
import CardMenu from '../cards/CardMenu';
import { useContext, useEffect, useState } from 'react';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { CardComponentData } from '../cards/cardTypes';
import { createOrUpdateScoreData } from '../../graphql/mutations';
import { generateClient } from 'aws-amplify/api';
const client = generateClient();

export interface RadarCardScore {
    value: number;
    scoreDefinition: string;
}

interface RadarChartProps {
    labels: string[];
    cards: Card[];
    setCards?: React.Dispatch<React.SetStateAction<Card[]>>;
    scoreDefinitions: ScoreDefinition[];
    thumbnail: boolean;
    onBubbleClick: (
        data: CustomDataPoint,
        position: { top: number; left: number }
    ) => void;
    setSelectedCard: React.Dispatch<React.SetStateAction<Card | null>>;
    data: CustomDataPoint[][];
    cardsArray: CardScoreData[] | undefined;
    pptView?: boolean;
    setActiveCardId?: React.Dispatch<React.SetStateAction<string | null>>;
    setRightPanel?: React.Dispatch<React.SetStateAction<boolean>>;
    activeCardId?: string | null;
    multiSelectedCards: Card[] | undefined;
    setMultiSelectedCards: React.Dispatch<React.SetStateAction<Card[]>>;
    activeCardIds?: (string | null)[];
    permissions?: UserPermissions[] | undefined;
    worksheetId?: string | undefined;
    handleCardClick?: (card: Card | CardComponentData, side: string) => void;
}
const RadarChart = ({
    labels,
    cards,
    setCards,
    scoreDefinitions,
    thumbnail,
    onBubbleClick,
    setSelectedCard,
    data,
    cardsArray,
    pptView,
    setActiveCardId,
    setRightPanel,
    activeCardId,
    multiSelectedCards,
    setMultiSelectedCards,
    activeCardIds,
    permissions,
    worksheetId,
    handleCardClick,
}: RadarChartProps) => {
    const { isOver, setNodeRef } = useDroppable({
        id: '3_',
    });
    const {
        handleCopy,
        handleDelete,
        handleRemoveFromWorkbook,
        setCardFrom,
        handleRemoveFromWorksheet,
        createCardRelationship,
    } = useContext(WorksheetContext);
    const theme = useTheme();
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e.stopPropagation();
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };

    const handleMultiSelect = (card: Card) => {
        setMultiSelectedCards((multiSelectedCards) => {
            let cards = [...multiSelectedCards];

            if (
                multiSelectedCards.find(
                    (selectedCard) => selectedCard?.id === card?.id
                )
            ) {
                cards = cards.filter((c) => c?.id !== card?.id);
            } else {
                cards.push(card);
            }

            return cards;
        });
    };
    const radarChartData = createRadarChartData(
        labels,
        cards,
        scoreDefinitions,
        data,
        cardsArray
    );

    const seriesData: Highcharts.SeriesAreaOptions[] =
        radarChartData.datasets.map((dataset:Dataset, i: number) => {
            const newDataSet = dataset as Dataset;
            return {
                type: 'area',
                fillOpacity: 0.2,
                name: newDataSet.label ?? '',
                originalCard: newDataSet.originalCard,
                data: newDataSet.data?.map((val: any, idx: number) => {
                    return {
                        y: typeof val?.value === 'number' ? val?.value : 0,
                        originalCard: newDataSet.originalCard,
                        scoreDefinitionId: val?.scoreDefinitionId,
                    };
                }),

                color: dataset.borderColor as string,
                fillColor: dataset.backgroundColor as string,
                pointPlacement: 'on',
            };
        });
    const finalSeriesData = seriesData.map((series) => {
        const areaCard = (series as any)?.originalCard;
        const isSelected = multiSelectedCards?.some(
            (c) => c.id === areaCard?.id
        );
        const isActive = activeCardIds?.includes(areaCard?.id);
        const originalColor = series.color as string;
        const originalFillColor = series.fillColor as string;
        const hasActiveCards = activeCardIds?.some((id) => id !== null);
        return {
            ...series,
            color: hasActiveCards
                ? isActive
                    ? originalColor
                    : adjustAlpha(originalFillColor, 0.09)
                : !multiSelectedCards || multiSelectedCards.length === 0
                ? originalColor
                : isSelected
                ? originalColor
                : blendWithGrey(originalColor, 0.5),

            fillColor: hasActiveCards
                ? isActive
                    ? originalColor
                    : adjustAlpha(originalFillColor, 0.05)
                : !multiSelectedCards || multiSelectedCards.length === 0
                ? originalFillColor
                : isSelected
                ? adjustAlpha(originalFillColor, 0.8)
                : adjustAlpha(originalFillColor, 0.09),

            custom: { originalCard: (series as any).originalCard },
        };
    });

    const updateScores = async (card: Card, cardScore: RadarCardScore) => {
        setCards &&
            setCards((prevCards) =>
                prevCards.map((c) => {
                    if (c.id !== card.id) return c;
                    return {
                        ...c,
                        toScoreData: c.toScoreData
                            ? {
                                  ...c.toScoreData,
                                  items: (c.toScoreData.items
                                      ? c.toScoreData.items.map((item) => {
                                            if (!item) return item;
                                            if (
                                                item.scoreDefinitionId !==
                                                cardScore.scoreDefinition
                                            )
                                                return item;
                                            if (
                                                !item.data ||
                                                item.data.length === 0
                                            )
                                                return item;
                                            let latestIndex = 0;
                                            let latestDate = new Date(
                                                item.data[0].date
                                            );
                                            for (
                                                let i = 1;
                                                i < item.data.length;
                                                i++
                                            ) {
                                                const currentDate = new Date(
                                                    item.data[i].date
                                                );
                                                if (currentDate > latestDate) {
                                                    latestDate = currentDate;
                                                    latestIndex = i;
                                                }
                                            }
                                            const updatedData = [...item.data];
                                            updatedData[latestIndex] = {
                                                ...updatedData[latestIndex],
                                                value: cardScore.value,
                                            };
                                            return {
                                                ...item,
                                                __typename: 'ScoreData',
                                                data: updatedData,
                                            };
                                        })
                                      : []) as (ScoreData | null)[],
                              }
                            : c.toScoreData,
                    };
                })
            );

        const currentDate = new Date().toISOString().split('T')[0];
        const scoreData = {
            input: {
                data: [{ date: currentDate, value: cardScore.value }],
                cardId: card?.id,
                organisation: card?.organisation,
                scoreDefinitionId: cardScore.scoreDefinition || '',
            },
        };

        try {
            await client.graphql({
                query: createOrUpdateScoreData,
                variables: scoreData,
            });
        } catch (err) {
            console.log(err);
        }
    };

    const options = getRadarChartOptions(
        radarChartData,
        finalSeriesData,
        handleMultiSelect,
        activeCardIds,
        handleCardClick,
        updateScores
    );
    const workbookPermissions = [UserPermissions.DELETE, UserPermissions.EDIT];
    const card = multiSelectedCards && multiSelectedCards[0];
    const menuItems = getWorkSheetCardMenuItems({
        activeCardIds,
        card,
        worksheetId,
        permissions,
        workbookPermissions,
        handleDelete,
        multiSelectedCards,
        handleRemoveFromWorkbook,
        handleRemoveFromWorksheet,
        createCardRelationship,
        handleCopy,
        setCardFrom,
    });

    return (
        <Box
            ref={setNodeRef}
            sx={{
                canvas: {
                    background: isOver ? '#99b7e4' : '',
                },
            }}
        >
            <HighchartsReact highcharts={Highcharts} options={options} />
            {multiSelectedCards && multiSelectedCards?.length > 0 && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 70,
                        right: 10,
                    }}
                >
                    {(menuItems.length > 0 ||
                        permissions?.includes(UserPermissions.EDIT)) && (
                        <IconButton
                            aria-label=""
                            id="menu-button"
                            onClick={setMenu}
                            sx={{
                                color: 'black',
                            }}
                        >
                            <MenuIcon width={'40px'} height={'40px'} />
                        </IconButton>
                    )}
                    {menuAnchor && (
                        <CardMenu
                            permissions={[
                                UserPermissions.EDIT,
                                UserPermissions.DELETE,
                            ]}
                            anchor={menuAnchor}
                            data={{
                                organisation:
                                    multiSelectedCards[0].organisation || '',
                                id: multiSelectedCards[0].id,
                            }}
                            menuItems={menuItems}
                            setMenu={setMenu}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default RadarChart;
