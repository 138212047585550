import { generateClient } from 'aws-amplify/api';
import {
    createRelationshipExt,
    deleteRelationshipExt,
} from '../graphql/mutations';
import {
    CreateRelationshipExtMutation,
    DeleteRelationshipExtMutation,
    EntityType,
    EventContext,
} from '../API';
import { fetchAuthSession } from '@aws-amplify/auth';
import { getUserOrganisation } from './auth';
const client = generateClient();

export const deleteRelationship = async (id?: string) => {
    if (!id) return;
    try {
        const res = (await client.graphql({
            query: deleteRelationshipExt,
            variables: {
                id: id,
            },
        })) as { data: DeleteRelationshipExtMutation };
    } catch (err) {
        console.log(err);
    }
};

export const createRelationship = async (
    sourceId: string,
    targetId: string,
    sourceType: EntityType,
    targetType: EntityType,
    contextId?: string,
    contextType?: EventContext
) => {
    const user = await fetchAuthSession();
    const userOrganisation = getUserOrganisation(user);

    const query = createRelationshipExt;

    const variables: any = {
        sourceId: sourceId,
        sourceType: sourceType,
        targetId: targetId,
        targetType: targetType,
        organisation: userOrganisation ?? '',
        ...(contextId && {
            _auditContextId: contextId,
        }),
        ...(contextType && {
            _auditContextType: contextType,
        }),
    };
    try {
        const res = (await client.graphql({
            query,
            variables: variables,
        })) as { data: CreateRelationshipExtMutation };
        return res;
    } catch (e) {
        console.log(e);
    }
};
