import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ActiveIcons } from '../../cards/views/CardTypeLayout';
import { ViewType } from '../../layouts/PageView';
import {
    CardComponentData,
    CardSetComponentData,
    SortActions,
    sortFieldToResponseMap,
    PageIdentifier,
} from '../../cards/cardTypes';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    AddOrRemoveCardsFromSetMutation,
    Card,
    CardSet,
    EntityType,
    Relationship,
} from '../../../API';
import useFilterCards from '../../../hooks/useFilterCards';
import { AppContext } from '../../contexts';
import { GraphQLResponse, queryData } from '../../../actions/QueryData';
import { getCardDataWithScoreData } from '../../../graphql/custom-queries';
import useIconStates from '../../../hooks/useIconStates';
import { getCard, getCardSets, listCardSets } from '../../../graphql/queries';
import PrimaryColumn from './PrimaryColumn';
import SecondaryColumn from './SecondaryColumn';
import { generateClient } from 'aws-amplify/api';
import { addOrRemoveCardsFromSet } from '../../../graphql/mutations';
import { getUserOrganisation } from '../../../helpers/auth';
import { filterCardsBySearchNameTypeCat } from '../../../actions/FilterCards';
import AddIcon from '@mui/icons-material/Add';
import { createRelationship } from '../../../helpers/relationships';
import { RelationshipFormType } from '../../cards/CardRelationships';
import { getCardSetPermissions } from '../../../helpers/permissions';
import { UserPermissions } from '../../../globals';

interface AddExistingCardsProps {
    workbook?: CardSet;
    addMultipleExistingCards?: (cards: Card[]) => void;
    setWorkbookCards?: React.Dispatch<React.SetStateAction<Card[]>>;
    handleModalClose: () => void;
    relationshipForm?: RelationshipFormType;
    targetCardId?: string;
    relatedCards?: Card[];
    setAllRelatedCards?: React.Dispatch<React.SetStateAction<Card[]>>;
    containingCardSets?: CardSet[];
    setContainingCardSets?: React.Dispatch<React.SetStateAction<CardSet[]>>;
    handleNewRelationshipModalClose?: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setCardRelationships?: React.Dispatch<
        React.SetStateAction<Relationship[] | undefined>
    >;
    showSnackbar?: (options: {
        message: string;
        severity: 'success' | 'error';
    }) => void;
}
const getInitialSelectBy = (
    relationshipForm: RelationshipFormType | undefined
): 'card' | 'card_set' => {
    if (relationshipForm === RelationshipFormType.CARD) {
        return 'card';
    } else if (relationshipForm === RelationshipFormType.CARD_SET) {
        return 'card_set';
    } else {
        const storedSelectBy = localStorage.getItem(
            'addExistingCards_selectBy'
        );
        if (storedSelectBy === 'card' || storedSelectBy === 'card_set') {
            return storedSelectBy;
        }
    }
    return 'card_set';
};
const getInitialSelectedViewType = (): ViewType => {
    const storedSelectedView = localStorage.getItem(
        'addExistingCards_viewType'
    );
    if (
        storedSelectedView === ViewType.GRID ||
        storedSelectedView === ViewType.LIST
    ) {
        return storedSelectedView;
    }
    return ViewType.GRID;
};
export const getInitialSortField = (
    key: string,
    defaultValue: SortActions
): SortActions => {
    return (localStorage.getItem(key) as SortActions) || defaultValue;
};

export const getInitialSortDirection = (
    key: string,
    defaultValue: 'ASC' | 'DESC'
): 'ASC' | 'DESC' => {
    return (localStorage.getItem(key) as 'ASC' | 'DESC') || defaultValue;
};

const AddExistingCards = ({
    workbook,
    addMultipleExistingCards,
    setWorkbookCards,
    handleModalClose,
    relationshipForm,
    targetCardId,
    relatedCards,
    setAllRelatedCards,
    containingCardSets,
    setContainingCardSets,
    handleNewRelationshipModalClose,
    setCardRelationships,
    showSnackbar,
}: AddExistingCardsProps) => {
    const {
        applyFilter,
        applySort,
        cardCategories,
        cardCategoryObject,
        cardTypes,
        cardTypeObject,
        user,
        users,
        showIllustrations,
    } = useContext(AppContext);
    const relatedCardIds = relatedCards
        ? new Set(relatedCards?.map((card) => card.id))
        : null;
    const [loading, setLoading] = useState(false);
    const [cards, setCards] = useState<Card[]>([]);
    const [selectBy, setSelectBy] = useState<'card' | 'card_set' | 'workbook'>(
        getInitialSelectBy(relationshipForm)
    );

    const [cardSets, setCardSets] = useState<CardSet[]>([]);
    const [clickedCardSets, setClickedCardSets] = useState<
        CardSetComponentData[]
    >([]);
    const [cardIdsToDisplay, setCardIdsToDisplay] = useState<string[]>([]);
    const [selectedCards, setSelectedCards] = useState<Card[]>([]);
    const [cardsToDisplay, setCardsToDisplay] = useState<Card[]>([]);
    const [existingWorkbookCards, setExistingWorkbookCards] = useState<Card[]>(
        []
    );
    const [selectedViewType, setSelectedViewType] = useState<ViewType>(
        getInitialSelectedViewType()
    );
    const [cardFilterCriteria, setCardFilterCriteria] = useFilterCards(
        `filter_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD}`
    );
    const [cardSetFilterCriteria, setCardSetFilterCriteria] = useFilterCards(
        `filter_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD_SET}`
    );
    const [activeIcons, setActiveIcons] = useIconStates('CardsControls');
    const [cardSortField, setCardSortField] = useState<SortActions>(() =>
        getInitialSortField(
            `selectedSortAction_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD}`,
            SortActions.LAST_MODIFIED_RECENT_FIRST
        )
    );
    const [cardSortDirection, setCardSortDirection] = useState<'ASC' | 'DESC'>(
        () =>
            getInitialSortDirection(
                `selectedSortDirection_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD}`,
                'DESC'
            )
    );
    const [cardSetSortField, setCardSetSortField] = useState<SortActions>(() =>
        getInitialSortField(
            `selectedSortAction_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD_SET}`,
            SortActions.LAST_MODIFIED_RECENT_FIRST
        )
    );
    const [cardSetSortDirection, setCardSetSortDirection] = useState<
        'ASC' | 'DESC'
    >(() =>
        getInitialSortDirection(
            `selectedSortDirection_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD_SET}`,
            'DESC'
        )
    );
    const [currentUser, setCurrentUser] = useState('');
    const [cardsToken, setCardsToken] = useState<string | null>('');
    const [cardSetToken, setCardSetToken] = useState<string | null>('');
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const client = generateClient();

    const fetchCards = async (initialLoad?: boolean) => {
        if (!cardSortDirection || !cardSortField) return;

        try {
            const { data, nextToken } = await queryData(
                initialLoad ?? false,
                cardSortDirection,
                cardsToken,
                activeIcons,
                getCardDataWithScoreData,
                sortFieldToResponseMap[cardSortField],
                'getCardData',
                cardFilterCriteria,
                setActiveIcons,
                '',
                user
            );

            const existingCardIds = existingWorkbookCards.map(
                (card) => card.id
            );
            const filteredData = (data as Card[]).filter(
                (card) => !existingCardIds.includes(card.id)
            );

            if (initialLoad) {
                setCards(filteredData);
            } else {
                setCards((cards) => {
                    return [...cards, ...filteredData];
                });
            }
            setCardsToken(nextToken);
        } catch (err) {
            console.log(err);
        }
    };

    const fetchCardSets = async (initialLoad?: boolean) => {
        if (!cardSetSortDirection || !cardSetSortField) return;
        try {
            const { data, nextToken } = await queryData(
                initialLoad ?? false,
                cardSetSortDirection,
                cardSetToken,
                activeIcons,
                getCardSets,
                sortFieldToResponseMap[cardSetSortField],
                'getCardSets',
                cardSetFilterCriteria,
                setActiveIcons,
                '',
                user
            );
            const filteredData = data.filter(
                (item) => item.id !== workbook?.id
            );
            if (initialLoad) {
                setCardSets(filteredData as CardSet[]);
            } else {
                setCardSets((cardSets) => {
                    return [...cardSets, ...(filteredData as CardSet[])];
                });
            }
            setCardSetToken(nextToken);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        if (currentUser) {
            if (searchTerm) {
                getBySearchTerm(searchTerm, selectBy);
            } else {
                fetchCards(true);
            }
        }
    }, [
        currentUser,
        activeIcons,
        cardSortField,
        cardSortDirection,
        applyFilter,
        existingWorkbookCards,
    ]);
    useEffect(() => {
        if (currentUser) {
            fetchCardSets(true);
        }
    }, [
        selectBy,
        currentUser,
        activeIcons,
        cardSetSortField,
        cardSetSortDirection,
        applyFilter,
    ]);

    useEffect(() => {
        if (user) {
            setCurrentUser(user?.tokens?.idToken?.payload.sub ?? '');
        }

        const storedSelectedView = localStorage.getItem(
            'addExistingCards_viewType'
        ) as ViewType;
        if (storedSelectedView) {
            setSelectedViewType(storedSelectedView);
        }
        const storedSelectBy = localStorage.getItem(
            'addExistingCards_selectBy'
        ) as 'card' | 'card_set';
        if (storedSelectBy) {
            setSelectBy(storedSelectBy);
        }
        const sortAction = localStorage.getItem(
            `selectedSortAction_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD}`
        ) as SortActions;
        if (sortAction) {
            setCardSortField(sortAction);
        }
        const sortDirection = localStorage.getItem(
            `selectedSortDirection_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD}`
        ) as 'ASC' | 'DESC';
        if (sortDirection) {
            setCardSortDirection(sortDirection);
        }
        const sortActionCardSets = localStorage.getItem(
            `selectedSortAction_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD_SET}`
        ) as SortActions;
        if (sortActionCardSets) {
            setCardSetSortField(sortActionCardSets);
        }
        const sortDirectionCardSets = localStorage.getItem(
            `selectedSortDirection_${PageIdentifier.ADD_EXISTING_CARD_FORM_BY_CARD_SET}`
        ) as 'ASC' | 'DESC';
        if (sortDirectionCardSets) {
            setCardSetSortDirection(sortDirectionCardSets);
        }
    }, [applySort, user]);

    const toggleIcon = (
        iconName: keyof ActiveIcons,
        selectedSortAction: string
    ) => {
        if (setActiveIcons) {
            setActiveIcons((prevState) => ({
                ...prevState,
                [iconName]:
                    iconName === 'Sort'
                        ? selectedSortAction ===
                          SortActions.LAST_MODIFIED_RECENT_FIRST
                            ? false
                            : true
                        : !prevState[iconName],
            }));
        }
    };

    useEffect(() => {
        const filteredCardSets = cardSets.filter((set) =>
            clickedCardSets.some((clickedSet) => clickedSet.id === set.id)
        );

        const cardIdsToDisplaySet = new Set<string>();
        filteredCardSets.forEach((item) => {
            item?.toCards?.items?.forEach((card: any) => {
                if (card) {
                    cardIdsToDisplaySet.add(card.cardId);
                }
            });
        });

        setCardIdsToDisplay(Array.from(cardIdsToDisplaySet).map((id) => id));
    }, [clickedCardSets, cardSets]);

    useEffect(() => {
        const existingCards = workbook?.toCards?.items
            ?.map((item) => item?.card)
            .filter((card): card is Card => card !== undefined);

        if (existingCards) {
            setExistingWorkbookCards(existingCards);
        }
    }, [workbook]);

    const handleSelect = (item: CardComponentData | CardSetComponentData) => {
        if (
            selectBy === 'card_set' ||
            relationshipForm === RelationshipFormType.CARD_SET
        ) {
            setClickedCardSets((prevClickedCardSets) => {
                const isItemExists = prevClickedCardSets.some(
                    (card) => card.id === item.id
                );
                if (isItemExists) {
                    return prevClickedCardSets.filter(
                        (card) => card.id !== item.id
                    );
                } else {
                    return [...prevClickedCardSets, item];
                }
            });
        } else {
            setCardIdsToDisplay((prevCardIdsToDisplay) => {
                const isItemExists = prevCardIdsToDisplay.some(
                    (id) => id === item.id
                );
                if (isItemExists) {
                    return prevCardIdsToDisplay.filter((id) => id !== item.id);
                } else {
                    return [...prevCardIdsToDisplay, item.id];
                }
            });
        }
    };
    const addCardToCardSet = async (cardSetId: string): Promise<void> => {
        if (!targetCardId) return;
        try {
            await client.graphql({
                query: addOrRemoveCardsFromSet,
                variables: {
                    cardSetId: cardSetId,
                    cardIds: [targetCardId],
                },
            });
        } catch (err) {
            console.error(`Error adding card to set ${cardSetId}:`, err);
        }
    };
    const handleNewCardSetRelationships = async () => {
        try {
            const promises = clickedCardSets.map((cardSet) =>
                addCardToCardSet(cardSet.id)
            );

            await Promise.all(promises);
            const matchingItems = clickedCardSets
                .map((clickedCardSet) =>
                    cardSets.find((cardSet) => cardSet.id === clickedCardSet.id)
                )
                .filter(Boolean);
            if (setContainingCardSets) {
                setContainingCardSets((prevCardSets = []) => [
                    ...matchingItems.filter(
                        (item): item is CardSet => item !== undefined
                    ),
                    ...prevCardSets,
                ]);
            }
            showSnackbar &&
                showSnackbar({
                    message: 'Card added successfully',
                    severity: 'success',
                });
            handleNewRelationshipModalClose &&
                handleNewRelationshipModalClose(false);
        } catch (error) {
            console.error('Error while processing card sets:', error);
            showSnackbar &&
                showSnackbar({
                    message: 'Error while adding the card',
                    severity: 'error',
                });
        }
    };

    const addCardsToWorkbook = async () => {
        setLoading(true);
        try {
            const res = (await client.graphql({
                query: addOrRemoveCardsFromSet,
                variables: {
                    cardSetId: workbook!.id,
                    cardIds: selectedCards.map((item) => item.id),
                },
            })) as { data: AddOrRemoveCardsFromSetMutation };

            if (res) {
                const updatedExistingCards = [
                    ...existingWorkbookCards,
                    ...selectedCards,
                ];
                setExistingWorkbookCards(updatedExistingCards);
                setWorkbookCards && setWorkbookCards(updatedExistingCards);
                addMultipleExistingCards &&
                    addMultipleExistingCards(selectedCards);
                setSelectedCards([]);
            }
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    const getBySearchTerm = async (
        searchTerm: string,
        selectBy: 'card' | 'card_set' | 'workbook'
    ) => {
        const userGroup = user ? await getUserOrganisation(user) : '';
        setCardsToken('');
        if (!searchTerm || searchTerm.length < 3) return;

        if (selectBy === 'card') {
            const { data }: { data: Card[]; nextToken?: string } =
                await filterCardsBySearchNameTypeCat({
                    searchTerm,
                    cardCategories,
                    cardTypes,
                    users,
                    organistion: userGroup,
                });

            const existingCardIds = existingWorkbookCards.map(
                (card) => card.id
            );

            let filteredData = data
                .filter((card) => !existingCardIds.includes(card.id))
                .map((item) => {
                    return {
                        ...item,
                        toCardType: cardTypeObject[item.cardToCardTypeId],
                        toCardCategory:
                            cardCategoryObject[item.cardToCardCategoryId],
                    };
                });

            setCards(filteredData.splice(0, 8) as Card[]);
        } else {
            const { data }: { data: CardSet[]; nextToken?: string } =
                await filterCardsBySearchNameTypeCat({
                    searchTerm,
                    cardCategories,
                    cardTypes,
                    users,
                    organistion: userGroup,
                    cardSet: true,
                });

            setCardSets(data);
        }
    };

    useEffect(() => {
        const fetchCards = async () => {
            const userGroup = await getUserOrganisation(user);
            try {
                const cardPromises = cardIdsToDisplay.map((cardId) =>
                    client
                        .graphql({
                            query: getCard,
                            variables: {
                                id: cardId,
                                organisation: userGroup,
                            },
                        })
                        .then((response) => response.data.getCard)
                );
                const cards = await Promise.all(cardPromises);
                if (cards) {
                    setCardsToDisplay(
                        cards.filter((card) => card !== null) as Card[]
                    );
                }
            } catch (e) {
                console.log(e);
            }
        };
        fetchCards();
    }, [cardIdsToDisplay]);
    const handleClear = () => {
        setCardIdsToDisplay([]);
        setCardsToDisplay([]);
        setClickedCardSets([]);
    };

    const throttle = (
        func: (
            searchTerm: string,
            selectBy: 'card' | 'card_set' | 'workbook'
        ) => void,
        limit: any
    ) => {
        let inThrottle = false;

        return (
            ...args: [
                searchTerm: string,
                selectBy: 'card' | 'card_set' | 'workbook'
            ]
        ) => {
            if (!inThrottle) {
                func(...args);
                inThrottle = true;
                setTimeout(() => (inThrottle = false), limit);
            }
        };
    };

    const throttledAPICall: (
        searchTerm: string,
        selectBy: 'card' | 'card_set' | 'workbook'
    ) => void = useCallback(
        throttle((searchTerm: string, selectBy) => {
            getBySearchTerm(searchTerm, selectBy);
        }, 150),
        []
    );

    useEffect(() => {
        if (searchTerm !== null) {
            if (searchTerm) {
                throttledAPICall(searchTerm, selectBy);
            } else {
                setCards([]);
                setCardSets([]);
                fetchCards();
                fetchCardSets();
            }
        }
    }, [searchTerm, selectBy]);

    const relateNewCards = async () => {
        if (!targetCardId) return;

        try {
            const relationshipPromises = cardIdsToDisplay.map((cardId) =>
                createRelationship(
                    cardId,
                    targetCardId,
                    EntityType.Card,
                    EntityType.Card
                ).then((result) => ({
                    cardId,
                    relationship: result?.data
                        ?.createRelationshipExt as Relationship,
                }))
            );
            const relationships = await Promise.all(relationshipPromises);

            if (relationships && setCardRelationships) {
                const newRelationships = relationships.map(
                    (item) => item.relationship
                );
                setCardRelationships((prevRelationships = []) => [
                    ...newRelationships,
                    ...prevRelationships,
                ]);
            }
            const newToSourceCards = relationships
                .map(({ relationship }) => relationship.toSourceCard)
                .filter((card): card is Card => card != null);
            setAllRelatedCards &&
                setAllRelatedCards((prevCreatedFromCards) => [
                    ...newToSourceCards,
                    ...prevCreatedFromCards,
                ]);
            showSnackbar &&
                showSnackbar({
                    message: 'Card related succesfully',
                    severity: 'success',
                });

            handleNewRelationshipModalClose &&
                handleNewRelationshipModalClose(false);
        } catch (error) {
            console.error('Failed to create one or more relationships', error);
            showSnackbar &&
                showSnackbar({
                    message: 'Error while relating the card',
                    severity: 'error',
                });
        }
    };
    return (
        <Box
            sx={{ overflow: 'hidden', height: '100%', position: 'relative' }}
            className={!showIllustrations ? 'hide-illustrations' : ''}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                }}
            >
                <IconButton onClick={() => handleModalClose()}>
                    <CloseIcon sx={{ color: 'inherit' }} />
                </IconButton>
            </Box>
            <Box sx={{ p: 2 }}>
                <Typography
                    variant="h5"
                    sx={{
                        marginBottom: 0,
                        textAlign: 'left',
                    }}
                >
                    {relationshipForm === RelationshipFormType.CARD
                        ? 'Select one or more cards to relate to this card'
                        : relationshipForm === RelationshipFormType.CARD_SET
                        ? 'Select one or more card sets to add this card'
                        : 'Add cards to workbook'}
                </Typography>
            </Box>
            <Grid container spacing={0} sx={{ height: '100%' }}>
                <Grid
                    item
                    xs={12}
                    md={relationshipForm ? 12 : 8.5}
                    sx={{ height: '83%', borderRight: 'solid 1px #ccc' }}
                >
                    <PrimaryColumn
                        selectBy={selectBy}
                        setSelectBy={setSelectBy}
                        activeIcons={activeIcons}
                        toggleIcon={toggleIcon}
                        selectedViewType={selectedViewType}
                        setSelectedViewType={setSelectedViewType}
                        cardFilterCriteria={cardFilterCriteria}
                        setCardFilterCriteria={setCardFilterCriteria}
                        cardSetFilterCriteria={cardSetFilterCriteria}
                        setCardSetFilterCriteria={setCardSetFilterCriteria}
                        cards={
                            relatedCardIds
                                ? cards.filter(
                                      (card) =>
                                          !relatedCardIds.has(card.id) &&
                                          card.id !== targetCardId
                                  )
                                : cards
                        }
                        cardSets={
                            containingCardSets
                                ? cardSets.filter(
                                      (cardSet) =>
                                          !containingCardSets.some(
                                              (containingCardSet: CardSet) =>
                                                  containingCardSet.id ===
                                                  cardSet.id
                                          ) &&
                                          getCardSetPermissions(
                                              user?.userSub || '',
                                              cardSet
                                          ).includes(UserPermissions.EDIT)
                                  )
                                : cardSets
                        }
                        handleSelect={handleSelect}
                        clickedItems={
                            selectBy === 'card_set' ||
                            relationshipForm === RelationshipFormType.CARD_SET
                                ? clickedCardSets
                                : cardsToDisplay
                        }
                        onLoadMore={
                            selectBy === 'card_set' ? fetchCardSets : fetchCards
                        }
                        token={
                            selectBy === 'card_set' ? cardSetToken : cardsToken
                        }
                        loading={loading}
                        existingWorkbookCards={existingWorkbookCards}
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        title={'Cards'}
                        relationshipForm={relationshipForm}
                    />
                    {relationshipForm && (
                        <Button
                            disabled={loading}
                            variant="contained"
                            type="button"
                            sx={{
                                justifySelf: 'right',
                                ml:
                                    relationshipForm ===
                                    RelationshipFormType.CARD
                                        ? '82%'
                                        : '85%',
                                width: 'fit-content',
                                borderRadius: 2,
                            }}
                            onClick={() => {
                                if (
                                    relationshipForm ===
                                    RelationshipFormType.CARD
                                ) {
                                    relateNewCards();
                                } else {
                                    handleNewCardSetRelationships();
                                }
                            }}
                        >
                            {loading ? (
                                <CircularProgress color="inherit" size="1rem" />
                            ) : relationshipForm ===
                              RelationshipFormType.CARD ? (
                                'Relate'
                            ) : (
                                <AddIcon />
                            )}
                        </Button>
                    )}
                </Grid>
                {!relationshipForm && (
                    <Grid item xs={12} md={3.5} sx={{ height: '100%' }}>
                        <SecondaryColumn
                            selectedCards={selectedCards}
                            setSelectedCards={setSelectedCards}
                            addCardsToWorkbook={addCardsToWorkbook}
                            existingWorkbookCards={existingWorkbookCards}
                            loading={loading}
                            cardsToDisplay={cardsToDisplay}
                            handleClear={handleClear}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};

export default AddExistingCards;
