import {
    PropsWithChildren,
    useState,
    useEffect,
    useMemo,
    useContext,
} from 'react';
import { Box, darken, styled } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import CardActionArea from '@mui/material/CardActionArea';

import {
    Category,
    getCategoryHex,
    getCategoryHexText,
} from '../../helpers/category';
import { getScoreColorSx } from '../../helpers/scores';
import CardActions from './CardActions';
import HeaderScore from '../playbooks/HeaderScore';
import CardBase from './CardBase';
import CardMenu from './CardMenu';
import CardSummary from './CardSummary';
import {
    CardComponentProps,
    CardComponentType,
    CardPage,
    PageIdentifier,
} from './cardTypes';
import { getUserPermissions } from '../../helpers/permissions';
import { UserPermissions } from '../../globals';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import CreateCardSet from '../forms/CreateCardSet/index';
import CardHelp from './CardHelp';
import { getCardMenu } from '../../helpers/card';
import { AppContext, WorksheetContext } from '../contexts';
import { Card } from '../../API';
import {
    getCardMenuItems,
    getWorkbookCardMenuItems,
} from '../../helpers/worksheetCards';

const StyledLayerOne = styled('div')<{ categoryId: string }>(
    ({ categoryId }) => ({
        borderRadius: '16px',
        backgroundColor: darken(getCategoryHex(categoryId), 0.1),
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
        position: 'absolute',
        zIndex: '-1',
        width: '100%',
        height: '100%',
        top: '0.150rem',
        left: '0.250rem',
    })
);

const StyledLayerTwo = styled('div')<{ categoryId: string }>(
    ({ categoryId }) => ({
        borderRadius: '16px',
        backgroundColor: darken(getCategoryHex(categoryId), 0.2),
        boxShadow: '0px 0px 13px rgba(0,0,0,0.40)',
        position: 'absolute',
        zIndex: '-2',
        width: '100%',
        height: '100%',
        top: '0.350rem',
        left: '0.450rem',
    })
);

export const Lightbox = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(5px)',
    zIndex: 1300,
}));

const CardComponent = (props: PropsWithChildren<CardComponentProps>) => {
    const { handleRemoveFromWorkbook, handleRemoveFromWorksheet } =
        useContext(WorksheetContext);

    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const [helpTextAnchor, setHelpTextAnchor] = useState<HTMLElement | null>();
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const [cardSetModalOpen, setCardSetModalOpen] = useState<boolean>(false);
    const { user } = useContext(AppContext);
    const {
        data,
        onClick,
        showPage,
        handleEdit,
        expanded,
        addCardToWorksheet,
        handleDelete,
        handleRemove,
        handleConvert,
        createCardRelationship,
        handleCopy,
        handleClose,
        setCardFrom,
        deletedCard,
        multiSelectedCards,
        setMultiSelectedCards,
        cardsObject,
        pageIdentifier,
        mediumCard,
        handleSelect,
        clickedItems,
        worksheetCardsObject,
        workbookPermissions,
        worksheetCard,
        activeCardId,
        setActiveCardId,
        addToMinimized,
        menuItems,
    } = props;

    const navigate = useNavigate();
    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };
    const setHelpTextModal = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setHelpTextAnchor(e.currentTarget);
        } else {
            setHelpTextAnchor(null);
        }
    };

    const {
        id,
        description,
        // cardCategory,
        cardComponentType,
        cardCategoryId,
        cardToCardCategoryId,
        cardTypeId,
        cardTypeName,
        link,
        name,
        scoreDefinition,
        scoreValue,
        scoreValues,
        organisation,
        scoreName,
    } = data;
    const isSelected = useMemo(() => {
        if (clickedItems) {
            return clickedItems.some((card) => card.id === id);
        } else if (multiSelectedCards) {
            return multiSelectedCards.some((card) => card.id === id);
        }
    }, [multiSelectedCards, id]);

    const textColor = getCategoryHexText(cardCategoryId);

    const handleClick = (cardPage?: CardPage) => {
        if (!!onClick) {
            onClick(data.id, cardPage);
        }
    };
    const setMenuOpen = () => {
        setMenuAnchor(null);
        setCardSetModalOpen(true);
    };

    const cardMenuItems =
        pageIdentifier === PageIdentifier.WORKBOOK_INSIDE
            ? cardsObject
                ? getWorkbookCardMenuItems({
                      card: cardsObject[data?.id],
                      cardsObject,
                      convert: handleConvert,
                      createCardRelationship,
                      setCardFrom,
                      handleCopy,
                      handleDelete,
                      handleRemove,
                      multiSelectedCards,
                  })
                : []
            : menuItems && menuItems?.length > 0
            ? menuItems
            : cardsObject
            ? getCardMenuItems({
                  createCardSet: setMenuOpen,
                  card: cardsObject[data?.id],
                  permissions: getUserPermissions(
                      user?.tokens.idToken.payload.sub as string,
                      cardsObject[data?.id]
                  ),
                  cardsObject,
                  convert: handleConvert,
                  createCardRelationship,
                  setCardFrom,
                  handleCopy,
                  handleDelete,
                  handleRemove,
                  multiSelectedCards,
              })
            : [];

    const onCreateCardSet = (id?: string) => {
        setCardSetModalOpen(false);
        navigate(`/card-sets${id ? `/${id}` : ``}`);
    };

    useEffect(() => {
        if (user) {
            const userPermissions = getUserPermissions(
                user?.tokens?.idToken?.payload?.sub ?? '',
                data
            );

            setPermissions(userPermissions);
        }
    }, [data, user]);

    const menuActions = getCardMenu({
        handleClick,
        link,
        showPage,
        deletedCard,
        cardComponentType,
        scoreDefinition,
    });

    const handleHeaderClick = () => {
        if (
            cardComponentType === CardComponentType.CARD &&
            setMultiSelectedCards &&
            organisation &&
            permissions.includes(UserPermissions.DELETE)
        ) {
            setMultiSelectedCards((prevCards) => {
                const newCard = {
                    id: id,
                    organisation: organisation,
                    cardTypeId: cardsObject
                        ? cardsObject[id].toCardType.id
                        : undefined,
                    cardCategoryId: cardsObject
                        ? cardsObject[id].toCardCategory.id
                        : undefined,
                };
                const index = prevCards.findIndex((card) => card.id === id);

                if (index > -1) {
                    return [
                        ...prevCards.slice(0, index),
                        ...prevCards.slice(index + 1),
                    ];
                } else {
                    return [...prevCards, newCard];
                }
            });
        }
    };

    return (
        <>
            {helpTextAnchor && <Lightbox />}
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}
            >
                <CardBase
                    clickedItems={clickedItems}
                    multiSelectedCards={multiSelectedCards ?? []}
                    mediumCard={mediumCard}
                    worksheetCard={worksheetCard}
                    headerRight={
                        scoreName && (
                            <HeaderScore
                                borderColor={getScoreColorSx(
                                    data.scoreValue,
                                    data.scoreDefinition?.minimumValue,
                                    data.scoreDefinition?.maximumValue,
                                    typeof scoreValue === 'number' &&
                                        scoreValue < 0,
                                    true
                                )}
                                name={scoreName}
                                value={scoreValue}
                                type={data.scoreDefinition?.scoreType}
                                mediumCard={mediumCard}
                                cardTypeName={cardTypeName}
                                fadeOut={
                                    (clickedItems?.length &&
                                        !clickedItems?.find(
                                            (card) => card.id === id
                                        )) ||
                                    ((multiSelectedCards?.length
                                        ? true
                                        : false) &&
                                        (!isSelected ?? false))
                                }
                            />
                        )
                    }
                    data={{
                        id,
                        name,
                        category: cardCategoryId as Category,
                        cardTypeName,
                        cardComponentType,
                        organisation,
                    }}
                    expanded={expanded}
                    subHeader={showPage}
                    handleClose={handleClose}
                    handleHeaderClick={handleHeaderClick}
                    permissions={permissions}
                    {...(cardComponentType === CardComponentType.CARD && {
                        setHelpTextModal,
                    })}
                    activeCardId={activeCardId}
                    setActiveCardId={setActiveCardId}
                    addToMinimized={addToMinimized}
                    hideHelp={worksheetCard}
                    isSelected={isSelected}
                >
                    {props.children || (
                        <CardActionArea
                            {...{
                                onClick:
                                    link && !mediumCard
                                        ? () => {
                                              window.scroll(0, 0);
                                              navigate(link);
                                          }
                                        : !link && !mediumCard
                                        ? () => handleClick(CardPage.DETAILS)
                                        : handleSelect
                                        ? () => handleSelect(data)
                                        : undefined,
                            }}
                            sx={{
                                overflow: 'hidden',
                                borderRadius: 'initial',
                                cursor: 'pointer',
                            }}
                        >
                            <CardSummary
                                description={description}
                                name={name}
                                categoryId={cardCategoryId as Category}
                                cardTypeId={cardTypeId}
                                mediumCard={mediumCard}
                                fadeOut={
                                    (clickedItems?.length &&
                                        !clickedItems?.find(
                                            (card) => card.id === id
                                        )) ||
                                    ((multiSelectedCards?.length
                                        ? true
                                        : false) &&
                                        (!isSelected ?? false))
                                }
                            />
                        </CardActionArea>
                    )}

                    {cardComponentType === CardComponentType.CARD_SET && (
                        <>
                            <StyledLayerOne categoryId={cardCategoryId} />
                            {!mediumCard && (
                                <StyledLayerTwo categoryId={cardCategoryId} />
                            )}
                        </>
                    )}

                    {!mediumCard && (
                        <CardActions
                            textColor={textColor}
                            items={menuActions}
                            setMenu={setMenu}
                            expanded={expanded}
                            deletedCard={deletedCard}
                            multiDelete={isSelected}
                            worksheetCard={worksheetCard}
                            fadeOut={
                                (clickedItems?.length &&
                                    !clickedItems?.find(
                                        (card) => card.id === id
                                    )) ||
                                ((multiSelectedCards?.length ? true : false) &&
                                    (!isSelected ?? false))
                            }
                        />
                    )}

                    {menuAnchor && cardMenuItems?.length !== 0 && (
                        <CardMenu
                            permissions={permissions}
                            anchor={menuAnchor}
                            data={{
                                organisation: organisation || '',
                                id,
                            }}
                            {...(cardMenuItems?.length !== 0 && {
                                menuItems: cardMenuItems,
                            })}
                            handleEdit={() => handleEdit && handleEdit(id)}
                            setMenu={setMenu}
                            multiDelete={isSelected}
                        />
                    )}
                    {helpTextAnchor && (
                        <CardHelp
                            anchor={helpTextAnchor}
                            setHelpTextModal={setHelpTextModal}
                            setHelpTextAnchor={setHelpTextAnchor}
                            cardTypeId={cardTypeId}
                            cardCategoryId={cardCategoryId}
                        />
                    )}
                </CardBase>
                <StyledModal
                    key="modal"
                    open={cardSetModalOpen}
                    onClose={() => setCardSetModalOpen(false)}
                    sx={{ zIndex: 1401 }}
                >
                    <Box>
                        {cardSetModalOpen && (
                            <ModalContainer sx={{ maxWidth: '35rem' }}>
                                <CreateCardSet
                                    cardSetCopy={{
                                        cardSetToCardCategoryId: cardCategoryId,
                                        cardSetToCardTypeId: cardTypeId,
                                    }}
                                    cardSet={undefined}
                                    handleClose={onCreateCardSet}
                                    cardId={id}
                                    multiSelectedCards={multiSelectedCards}
                                />
                            </ModalContainer>
                        )}
                    </Box>
                </StyledModal>
            </Box>
        </>
    );
};

export default CardComponent;
