/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const addOrRemoveCardsFromSet = /* GraphQL */ `mutation AddOrRemoveCardsFromSet($cardSetId: ID!, $cardIds: [ID!]) {
  addOrRemoveCardsFromSet(cardSetId: $cardSetId, cardIds: $cardIds)
}
` as GeneratedMutation<
  APITypes.AddOrRemoveCardsFromSetMutationVariables,
  APITypes.AddOrRemoveCardsFromSetMutation
>;
export const createCardType = /* GraphQL */ `mutation CreateCardType(
  $input: CreateCardTypeInput!
  $condition: ModelCardTypeConditionInput
) {
  createCardType(input: $input, condition: $condition) {
    id
    name
    cardCategoryID
    toCards {
      items {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      nextToken
      __typename
    }
    attributeDefinitions {
      id
      name
      attributeType
      configuration
      __typename
    }
    scoreDefinitions {
      id
      name
      shortName
      description
      scoreType
      minimumValue
      maximumValue
      target
      __typename
    }
    defaultScoreDefinitionId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCardTypeMutationVariables,
  APITypes.CreateCardTypeMutation
>;
export const updateCardType = /* GraphQL */ `mutation UpdateCardType(
  $input: UpdateCardTypeInput!
  $condition: ModelCardTypeConditionInput
) {
  updateCardType(input: $input, condition: $condition) {
    id
    name
    cardCategoryID
    toCards {
      items {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      nextToken
      __typename
    }
    attributeDefinitions {
      id
      name
      attributeType
      configuration
      __typename
    }
    scoreDefinitions {
      id
      name
      shortName
      description
      scoreType
      minimumValue
      maximumValue
      target
      __typename
    }
    defaultScoreDefinitionId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCardTypeMutationVariables,
  APITypes.UpdateCardTypeMutation
>;
export const deleteCardType = /* GraphQL */ `mutation DeleteCardType(
  $input: DeleteCardTypeInput!
  $condition: ModelCardTypeConditionInput
) {
  deleteCardType(input: $input, condition: $condition) {
    id
    name
    cardCategoryID
    toCards {
      items {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      nextToken
      __typename
    }
    attributeDefinitions {
      id
      name
      attributeType
      configuration
      __typename
    }
    scoreDefinitions {
      id
      name
      shortName
      description
      scoreType
      minimumValue
      maximumValue
      target
      __typename
    }
    defaultScoreDefinitionId
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCardTypeMutationVariables,
  APITypes.DeleteCardTypeMutation
>;
export const createCardCategory = /* GraphQL */ `mutation CreateCardCategory(
  $input: CreateCardCategoryInput!
  $condition: ModelCardCategoryConditionInput
) {
  createCardCategory(input: $input, condition: $condition) {
    id
    name
    description
    toCardTypes {
      items {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCardCategoryMutationVariables,
  APITypes.CreateCardCategoryMutation
>;
export const updateCardCategory = /* GraphQL */ `mutation UpdateCardCategory(
  $input: UpdateCardCategoryInput!
  $condition: ModelCardCategoryConditionInput
) {
  updateCardCategory(input: $input, condition: $condition) {
    id
    name
    description
    toCardTypes {
      items {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCardCategoryMutationVariables,
  APITypes.UpdateCardCategoryMutation
>;
export const deleteCardCategory = /* GraphQL */ `mutation DeleteCardCategory(
  $input: DeleteCardCategoryInput!
  $condition: ModelCardCategoryConditionInput
) {
  deleteCardCategory(input: $input, condition: $condition) {
    id
    name
    description
    toCardTypes {
      items {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCardCategoryMutationVariables,
  APITypes.DeleteCardCategoryMutation
>;
export const createCard = /* GraphQL */ `mutation CreateCard(
  $input: CreateCardInput!
  $condition: ModelCardConditionInput
) {
  createCard(input: $input, condition: $condition) {
    organisation
    id
    owner
    createdBy
    editors
    orgEdit
    name
    capitalName
    toCardCategory {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    description
    attributes {
      attributeDefinitionID
      value
      __typename
    }
    toAttributes {
      items {
        cardId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        attributeDefinitionId
        organisation
        value
        refToCardId
        refCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        refToCardSetId
        refCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toScoreData {
      items {
        cardId
        id
        scoreDefinitionId
        data {
          value
          date
          comment
          commentDate
          commentAuthor
          __typename
        }
        organisation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toCardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    toCardSets {
      items {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    updatedBy
    deleteAfter
    _auditContextType
    _auditContextId
    createdFromId
    createdFromIds
    cardTypeToCardsId
    cardToCardCategoryId
    cardToCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCardMutationVariables,
  APITypes.CreateCardMutation
>;
export const updateCard = /* GraphQL */ `mutation UpdateCard(
  $input: UpdateCardInput!
  $condition: ModelCardConditionInput
) {
  updateCard(input: $input, condition: $condition) {
    organisation
    id
    owner
    createdBy
    editors
    orgEdit
    name
    capitalName
    toCardCategory {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    description
    attributes {
      attributeDefinitionID
      value
      __typename
    }
    toAttributes {
      items {
        cardId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        attributeDefinitionId
        organisation
        value
        refToCardId
        refCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        refToCardSetId
        refCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toScoreData {
      items {
        cardId
        id
        scoreDefinitionId
        data {
          value
          date
          comment
          commentDate
          commentAuthor
          __typename
        }
        organisation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toCardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    toCardSets {
      items {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    updatedBy
    deleteAfter
    _auditContextType
    _auditContextId
    createdFromId
    createdFromIds
    cardTypeToCardsId
    cardToCardCategoryId
    cardToCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCardMutationVariables,
  APITypes.UpdateCardMutation
>;
export const deleteCard = /* GraphQL */ `mutation DeleteCard(
  $input: DeleteCardInput!
  $condition: ModelCardConditionInput
) {
  deleteCard(input: $input, condition: $condition) {
    organisation
    id
    owner
    createdBy
    editors
    orgEdit
    name
    capitalName
    toCardCategory {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    description
    attributes {
      attributeDefinitionID
      value
      __typename
    }
    toAttributes {
      items {
        cardId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        attributeDefinitionId
        organisation
        value
        refToCardId
        refCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        refToCardSetId
        refCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toScoreData {
      items {
        cardId
        id
        scoreDefinitionId
        data {
          value
          date
          comment
          commentDate
          commentAuthor
          __typename
        }
        organisation
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toCardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    toCardSets {
      items {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    createdAt
    updatedAt
    updatedBy
    deleteAfter
    _auditContextType
    _auditContextId
    createdFromId
    createdFromIds
    cardTypeToCardsId
    cardToCardCategoryId
    cardToCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCardMutationVariables,
  APITypes.DeleteCardMutation
>;
export const createCardAttribute = /* GraphQL */ `mutation CreateCardAttribute(
  $input: CreateCardAttributeInput!
  $condition: ModelCardAttributeConditionInput
) {
  createCardAttribute(input: $input, condition: $condition) {
    cardId
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    attributeDefinitionId
    organisation
    value
    refToCardId
    refCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    refToCardSetId
    refCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCardAttributeMutationVariables,
  APITypes.CreateCardAttributeMutation
>;
export const updateCardAttribute = /* GraphQL */ `mutation UpdateCardAttribute(
  $input: UpdateCardAttributeInput!
  $condition: ModelCardAttributeConditionInput
) {
  updateCardAttribute(input: $input, condition: $condition) {
    cardId
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    attributeDefinitionId
    organisation
    value
    refToCardId
    refCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    refToCardSetId
    refCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCardAttributeMutationVariables,
  APITypes.UpdateCardAttributeMutation
>;
export const deleteCardAttribute = /* GraphQL */ `mutation DeleteCardAttribute(
  $input: DeleteCardAttributeInput!
  $condition: ModelCardAttributeConditionInput
) {
  deleteCardAttribute(input: $input, condition: $condition) {
    cardId
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    attributeDefinitionId
    organisation
    value
    refToCardId
    refCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    refToCardSetId
    refCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCardAttributeMutationVariables,
  APITypes.DeleteCardAttributeMutation
>;
export const createCardSet = /* GraphQL */ `mutation CreateCardSet(
  $input: CreateCardSetInput!
  $condition: ModelCardSetConditionInput
) {
  createCardSet(input: $input, condition: $condition) {
    organisation
    id
    type
    name
    capitalName
    description
    owner
    createdBy
    editors
    orgEdit
    toCards {
      items {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toCardCategory {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    toCardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    updatedBy
    hiddenWorksheets
    copiedFrom
    counts {
      comments
      worksheets
      cards
      __typename
    }
    processProgress {
      stageId
      progress
      __typename
    }
    cardSetToCardCategoryId
    cardSetToCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCardSetMutationVariables,
  APITypes.CreateCardSetMutation
>;
export const updateCardSet = /* GraphQL */ `mutation UpdateCardSet(
  $input: UpdateCardSetInput!
  $condition: ModelCardSetConditionInput
) {
  updateCardSet(input: $input, condition: $condition) {
    organisation
    id
    type
    name
    capitalName
    description
    owner
    createdBy
    editors
    orgEdit
    toCards {
      items {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toCardCategory {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    toCardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    updatedBy
    hiddenWorksheets
    copiedFrom
    counts {
      comments
      worksheets
      cards
      __typename
    }
    processProgress {
      stageId
      progress
      __typename
    }
    cardSetToCardCategoryId
    cardSetToCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCardSetMutationVariables,
  APITypes.UpdateCardSetMutation
>;
export const deleteCardSet = /* GraphQL */ `mutation DeleteCardSet(
  $input: DeleteCardSetInput!
  $condition: ModelCardSetConditionInput
) {
  deleteCardSet(input: $input, condition: $condition) {
    organisation
    id
    type
    name
    capitalName
    description
    owner
    createdBy
    editors
    orgEdit
    toCards {
      items {
        organisation
        id
        cardId
        cardSetId
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdBy
        updatedBy
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    toCardCategory {
      id
      name
      description
      toCardTypes {
        items {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      owner
      __typename
    }
    toCardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    createdAt
    updatedAt
    updatedBy
    hiddenWorksheets
    copiedFrom
    counts {
      comments
      worksheets
      cards
      __typename
    }
    processProgress {
      stageId
      progress
      __typename
    }
    cardSetToCardCategoryId
    cardSetToCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCardSetMutationVariables,
  APITypes.DeleteCardSetMutation
>;
export const createCardsCardSets = /* GraphQL */ `mutation CreateCardsCardSets(
  $input: CreateCardsCardSetsInput!
  $condition: ModelCardsCardSetsConditionInput
) {
  createCardsCardSets(input: $input, condition: $condition) {
    organisation
    id
    cardId
    cardSetId
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCardsCardSetsMutationVariables,
  APITypes.CreateCardsCardSetsMutation
>;
export const updateCardsCardSets = /* GraphQL */ `mutation UpdateCardsCardSets(
  $input: UpdateCardsCardSetsInput!
  $condition: ModelCardsCardSetsConditionInput
) {
  updateCardsCardSets(input: $input, condition: $condition) {
    organisation
    id
    cardId
    cardSetId
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCardsCardSetsMutationVariables,
  APITypes.UpdateCardsCardSetsMutation
>;
export const deleteCardsCardSets = /* GraphQL */ `mutation DeleteCardsCardSets(
  $input: DeleteCardsCardSetsInput!
  $condition: ModelCardsCardSetsConditionInput
) {
  deleteCardsCardSets(input: $input, condition: $condition) {
    organisation
    id
    cardId
    cardSetId
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdBy
    updatedBy
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCardsCardSetsMutationVariables,
  APITypes.DeleteCardsCardSetsMutation
>;
export const createComment = /* GraphQL */ `mutation CreateComment(
  $input: CreateCommentInput!
  $condition: ModelCommentConditionInput
) {
  createComment(input: $input, condition: $condition) {
    contextId
    id
    organisation
    contextType
    createdAt
    createdBy
    updatedBy
    message
    reactions {
      items {
        commentId
        commentContextId
        commentContextType
        commentCreatedAt
        organisation
        comment {
          contextId
          id
          organisation
          contextType
          createdAt
          createdBy
          updatedBy
          message
          updatedAt
          __typename
        }
        createdAt
        createdBy
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentMutationVariables,
  APITypes.CreateCommentMutation
>;
export const updateComment = /* GraphQL */ `mutation UpdateComment(
  $input: UpdateCommentInput!
  $condition: ModelCommentConditionInput
) {
  updateComment(input: $input, condition: $condition) {
    contextId
    id
    organisation
    contextType
    createdAt
    createdBy
    updatedBy
    message
    reactions {
      items {
        commentId
        commentContextId
        commentContextType
        commentCreatedAt
        organisation
        comment {
          contextId
          id
          organisation
          contextType
          createdAt
          createdBy
          updatedBy
          message
          updatedAt
          __typename
        }
        createdAt
        createdBy
        updatedAt
        owner
        __typename
      }
      nextToken
      __typename
    }
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentMutationVariables,
  APITypes.UpdateCommentMutation
>;
export const createCommentReaction = /* GraphQL */ `mutation CreateCommentReaction(
  $input: CreateCommentReactionInput!
  $condition: ModelCommentReactionConditionInput
) {
  createCommentReaction(input: $input, condition: $condition) {
    commentId
    commentContextId
    commentContextType
    commentCreatedAt
    organisation
    comment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    createdBy
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCommentReactionMutationVariables,
  APITypes.CreateCommentReactionMutation
>;
export const updateCommentReaction = /* GraphQL */ `mutation UpdateCommentReaction(
  $input: UpdateCommentReactionInput!
  $condition: ModelCommentReactionConditionInput
) {
  updateCommentReaction(input: $input, condition: $condition) {
    commentId
    commentContextId
    commentContextType
    commentCreatedAt
    organisation
    comment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    createdBy
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCommentReactionMutationVariables,
  APITypes.UpdateCommentReactionMutation
>;
export const deleteCommentReaction = /* GraphQL */ `mutation DeleteCommentReaction(
  $input: DeleteCommentReactionInput!
  $condition: ModelCommentReactionConditionInput
) {
  deleteCommentReaction(input: $input, condition: $condition) {
    commentId
    commentContextId
    commentContextType
    commentCreatedAt
    organisation
    comment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    createdAt
    createdBy
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCommentReactionMutationVariables,
  APITypes.DeleteCommentReactionMutation
>;
export const createNotification = /* GraphQL */ `mutation CreateNotification(
  $input: CreateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  createNotification(input: $input, condition: $condition) {
    organisation
    createdAt
    id
    type
    entity
    userId
    deleteAfter
    cardId
    toCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSetId
    cardSetType
    toCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    cardsCardSetsId
    toCardsCardSets {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    scoreDefinitionId
    toScoreData {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookPageId
    commentContextId
    commentCreatedAt
    commentId
    toComment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    commentReactionCreatedBy
    toCommentReaction {
      commentId
      commentContextId
      commentContextType
      commentCreatedAt
      organisation
      comment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      createdBy
      updatedAt
      owner
      __typename
    }
    worksheetId
    toWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          _auditContextType
          _auditContextId
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    relationshipId
    toRelationship {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _auditContextType
      _auditContextId
      __typename
    }
    relationshipInfo
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateNotificationMutationVariables,
  APITypes.CreateNotificationMutation
>;
export const updateNotification = /* GraphQL */ `mutation UpdateNotification(
  $input: UpdateNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  updateNotification(input: $input, condition: $condition) {
    organisation
    createdAt
    id
    type
    entity
    userId
    deleteAfter
    cardId
    toCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSetId
    cardSetType
    toCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    cardsCardSetsId
    toCardsCardSets {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    scoreDefinitionId
    toScoreData {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookPageId
    commentContextId
    commentCreatedAt
    commentId
    toComment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    commentReactionCreatedBy
    toCommentReaction {
      commentId
      commentContextId
      commentContextType
      commentCreatedAt
      organisation
      comment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      createdBy
      updatedAt
      owner
      __typename
    }
    worksheetId
    toWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          _auditContextType
          _auditContextId
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    relationshipId
    toRelationship {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _auditContextType
      _auditContextId
      __typename
    }
    relationshipInfo
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateNotificationMutationVariables,
  APITypes.UpdateNotificationMutation
>;
export const deleteNotification = /* GraphQL */ `mutation DeleteNotification(
  $input: DeleteNotificationInput!
  $condition: ModelNotificationConditionInput
) {
  deleteNotification(input: $input, condition: $condition) {
    organisation
    createdAt
    id
    type
    entity
    userId
    deleteAfter
    cardId
    toCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    cardSetId
    cardSetType
    toCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    cardsCardSetsId
    toCardsCardSets {
      organisation
      id
      cardId
      cardSetId
      card {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      cardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    scoreDefinitionId
    toScoreData {
      cardId
      id
      scoreDefinitionId
      data {
        value
        date
        comment
        commentDate
        commentAuthor
        __typename
      }
      organisation
      createdBy
      updatedBy
      createdAt
      updatedAt
      __typename
    }
    playbookPageId
    commentContextId
    commentCreatedAt
    commentId
    toComment {
      contextId
      id
      organisation
      contextType
      createdAt
      createdBy
      updatedBy
      message
      reactions {
        items {
          commentId
          commentContextId
          commentContextType
          commentCreatedAt
          organisation
          createdAt
          createdBy
          updatedAt
          owner
          __typename
        }
        nextToken
        __typename
      }
      updatedAt
      __typename
    }
    commentReactionCreatedBy
    toCommentReaction {
      commentId
      commentContextId
      commentContextType
      commentCreatedAt
      organisation
      comment {
        contextId
        id
        organisation
        contextType
        createdAt
        createdBy
        updatedBy
        message
        reactions {
          nextToken
          __typename
        }
        updatedAt
        __typename
      }
      createdAt
      createdBy
      updatedAt
      owner
      __typename
    }
    worksheetId
    toWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          _auditContextType
          _auditContextId
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    relationshipId
    toRelationship {
      organisation
      id
      type
      sourceId
      sourceType
      sourceCardId
      toSourceCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      sourceCardSetId
      toSourceCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      sourceWorksheetId
      toSourceWorksheet {
        organisation
        id
        parentId
        name
        commentary
        typeId
        ordinal
        createdAt
        createdBy
        updatedAt
        updatedBy
        toDataSources {
          nextToken
          __typename
        }
        options
        __typename
      }
      sourcePlaybookPageId
      toSourcePlaybookPage {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      targetId
      targetType
      targetCardId
      toTargetCard {
        organisation
        id
        owner
        createdBy
        editors
        orgEdit
        name
        capitalName
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        description
        attributes {
          attributeDefinitionID
          value
          __typename
        }
        toAttributes {
          nextToken
          __typename
        }
        toScoreData {
          nextToken
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardSets {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        deleteAfter
        _auditContextType
        _auditContextId
        createdFromId
        createdFromIds
        cardTypeToCardsId
        cardToCardCategoryId
        cardToCardTypeId
        __typename
      }
      targetCardSetId
      toTargetCardSet {
        organisation
        id
        type
        name
        capitalName
        description
        owner
        createdBy
        editors
        orgEdit
        toCards {
          nextToken
          __typename
        }
        toCardCategory {
          id
          name
          description
          createdAt
          updatedAt
          owner
          __typename
        }
        toCardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        createdAt
        updatedAt
        updatedBy
        hiddenWorksheets
        copiedFrom
        counts {
          comments
          worksheets
          cards
          __typename
        }
        processProgress {
          stageId
          progress
          __typename
        }
        cardSetToCardCategoryId
        cardSetToCardTypeId
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _auditContextType
      _auditContextId
      __typename
    }
    relationshipInfo
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteNotificationMutationVariables,
  APITypes.DeleteNotificationMutation
>;
export const createPlaybook = /* GraphQL */ `mutation CreatePlaybook(
  $input: CreatePlaybookInput!
  $condition: ModelPlaybookConditionInput
) {
  createPlaybook(input: $input, condition: $condition) {
    organisation
    id
    name
    description
    toPlaybookPages {
      items {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      nextToken
      __typename
    }
    owner
    editors
    orgEdit
    createdAt
    createdBy
    updatedAt
    updatedBy
    capitalName
    workbooks
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlaybookMutationVariables,
  APITypes.CreatePlaybookMutation
>;
export const updatePlaybook = /* GraphQL */ `mutation UpdatePlaybook(
  $input: UpdatePlaybookInput!
  $condition: ModelPlaybookConditionInput
) {
  updatePlaybook(input: $input, condition: $condition) {
    organisation
    id
    name
    description
    toPlaybookPages {
      items {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      nextToken
      __typename
    }
    owner
    editors
    orgEdit
    createdAt
    createdBy
    updatedAt
    updatedBy
    capitalName
    workbooks
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlaybookMutationVariables,
  APITypes.UpdatePlaybookMutation
>;
export const deletePlaybook = /* GraphQL */ `mutation DeletePlaybook(
  $input: DeletePlaybookInput!
  $condition: ModelPlaybookConditionInput
) {
  deletePlaybook(input: $input, condition: $condition) {
    organisation
    id
    name
    description
    toPlaybookPages {
      items {
        organisation
        id
        cardType {
          id
          name
          cardCategoryID
          defaultScoreDefinitionId
          createdAt
          updatedAt
          owner
          __typename
        }
        commentary
        playbookPageType
        worksheetId
        ordinal
        playbookId
        toPlaybook {
          organisation
          id
          name
          description
          owner
          editors
          orgEdit
          createdAt
          createdBy
          updatedAt
          updatedBy
          capitalName
          workbooks
          __typename
        }
        workbookId
        title
        toCardSets {
          nextToken
          __typename
        }
        include
        options
        createdAt
        createdBy
        updatedAt
        updatedBy
        cardSets
        workbooks
        cards
        worksheets
        playbookPageCardTypeId
        __typename
      }
      nextToken
      __typename
    }
    owner
    editors
    orgEdit
    createdAt
    createdBy
    updatedAt
    updatedBy
    capitalName
    workbooks
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlaybookMutationVariables,
  APITypes.DeletePlaybookMutation
>;
export const createPlaybookPage = /* GraphQL */ `mutation CreatePlaybookPage(
  $input: CreatePlaybookPageInput!
  $condition: ModelPlaybookPageConditionInput
) {
  createPlaybookPage(input: $input, condition: $condition) {
    organisation
    id
    cardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    commentary
    playbookPageType
    worksheetId
    ordinal
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    workbookId
    title
    toCardSets {
      items {
        organisation
        id
        cardSetId
        cardId
        playbookPageId
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        playbookPage {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    include
    options
    createdAt
    createdBy
    updatedAt
    updatedBy
    cardSets
    workbooks
    cards
    worksheets
    playbookPageCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlaybookPageMutationVariables,
  APITypes.CreatePlaybookPageMutation
>;
export const updatePlaybookPage = /* GraphQL */ `mutation UpdatePlaybookPage(
  $input: UpdatePlaybookPageInput!
  $condition: ModelPlaybookPageConditionInput
) {
  updatePlaybookPage(input: $input, condition: $condition) {
    organisation
    id
    cardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    commentary
    playbookPageType
    worksheetId
    ordinal
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    workbookId
    title
    toCardSets {
      items {
        organisation
        id
        cardSetId
        cardId
        playbookPageId
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        playbookPage {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    include
    options
    createdAt
    createdBy
    updatedAt
    updatedBy
    cardSets
    workbooks
    cards
    worksheets
    playbookPageCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlaybookPageMutationVariables,
  APITypes.UpdatePlaybookPageMutation
>;
export const deletePlaybookPage = /* GraphQL */ `mutation DeletePlaybookPage(
  $input: DeletePlaybookPageInput!
  $condition: ModelPlaybookPageConditionInput
) {
  deletePlaybookPage(input: $input, condition: $condition) {
    organisation
    id
    cardType {
      id
      name
      cardCategoryID
      toCards {
        items {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      attributeDefinitions {
        id
        name
        attributeType
        configuration
        __typename
      }
      scoreDefinitions {
        id
        name
        shortName
        description
        scoreType
        minimumValue
        maximumValue
        target
        __typename
      }
      defaultScoreDefinitionId
      createdAt
      updatedAt
      owner
      __typename
    }
    commentary
    playbookPageType
    worksheetId
    ordinal
    playbookId
    toPlaybook {
      organisation
      id
      name
      description
      toPlaybookPages {
        items {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        nextToken
        __typename
      }
      owner
      editors
      orgEdit
      createdAt
      createdBy
      updatedAt
      updatedBy
      capitalName
      workbooks
      __typename
    }
    workbookId
    title
    toCardSets {
      items {
        organisation
        id
        cardSetId
        cardId
        playbookPageId
        cardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        card {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        playbookPage {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
    include
    options
    createdAt
    createdBy
    updatedAt
    updatedBy
    cardSets
    workbooks
    cards
    worksheets
    playbookPageCardTypeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlaybookPageMutationVariables,
  APITypes.DeletePlaybookPageMutation
>;
export const createPlaybookPageCardSets = /* GraphQL */ `mutation CreatePlaybookPageCardSets(
  $input: CreatePlaybookPageCardSetsInput!
  $condition: ModelPlaybookPageCardSetsConditionInput
) {
  createPlaybookPageCardSets(input: $input, condition: $condition) {
    organisation
    id
    cardSetId
    cardId
    playbookPageId
    cardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    playbookPage {
      organisation
      id
      cardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentary
      playbookPageType
      worksheetId
      ordinal
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      workbookId
      title
      toCardSets {
        items {
          organisation
          id
          cardSetId
          cardId
          playbookPageId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      include
      options
      createdAt
      createdBy
      updatedAt
      updatedBy
      cardSets
      workbooks
      cards
      worksheets
      playbookPageCardTypeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlaybookPageCardSetsMutationVariables,
  APITypes.CreatePlaybookPageCardSetsMutation
>;
export const updatePlaybookPageCardSets = /* GraphQL */ `mutation UpdatePlaybookPageCardSets(
  $input: UpdatePlaybookPageCardSetsInput!
  $condition: ModelPlaybookPageCardSetsConditionInput
) {
  updatePlaybookPageCardSets(input: $input, condition: $condition) {
    organisation
    id
    cardSetId
    cardId
    playbookPageId
    cardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    playbookPage {
      organisation
      id
      cardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentary
      playbookPageType
      worksheetId
      ordinal
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      workbookId
      title
      toCardSets {
        items {
          organisation
          id
          cardSetId
          cardId
          playbookPageId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      include
      options
      createdAt
      createdBy
      updatedAt
      updatedBy
      cardSets
      workbooks
      cards
      worksheets
      playbookPageCardTypeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlaybookPageCardSetsMutationVariables,
  APITypes.UpdatePlaybookPageCardSetsMutation
>;
export const deletePlaybookPageCardSets = /* GraphQL */ `mutation DeletePlaybookPageCardSets(
  $input: DeletePlaybookPageCardSetsInput!
  $condition: ModelPlaybookPageCardSetsConditionInput
) {
  deletePlaybookPageCardSets(input: $input, condition: $condition) {
    organisation
    id
    cardSetId
    cardId
    playbookPageId
    cardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    card {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    playbookPage {
      organisation
      id
      cardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentary
      playbookPageType
      worksheetId
      ordinal
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      workbookId
      title
      toCardSets {
        items {
          organisation
          id
          cardSetId
          cardId
          playbookPageId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      include
      options
      createdAt
      createdBy
      updatedAt
      updatedBy
      cardSets
      workbooks
      cards
      worksheets
      playbookPageCardTypeId
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlaybookPageCardSetsMutationVariables,
  APITypes.DeletePlaybookPageCardSetsMutation
>;
export const createOrganisation = /* GraphQL */ `mutation CreateOrganisation(
  $input: CreateOrganisationInput!
  $condition: ModelOrganisationConditionInput
) {
  createOrganisation(input: $input, condition: $condition) {
    id
    name
    owningUserProfileID
    edition
    logo
    aiKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganisationMutationVariables,
  APITypes.CreateOrganisationMutation
>;
export const updateOrganisation = /* GraphQL */ `mutation UpdateOrganisation(
  $input: UpdateOrganisationInput!
  $condition: ModelOrganisationConditionInput
) {
  updateOrganisation(input: $input, condition: $condition) {
    id
    name
    owningUserProfileID
    edition
    logo
    aiKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganisationMutationVariables,
  APITypes.UpdateOrganisationMutation
>;
export const deleteOrganisation = /* GraphQL */ `mutation DeleteOrganisation(
  $input: DeleteOrganisationInput!
  $condition: ModelOrganisationConditionInput
) {
  deleteOrganisation(input: $input, condition: $condition) {
    id
    name
    owningUserProfileID
    edition
    logo
    aiKey
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganisationMutationVariables,
  APITypes.DeleteOrganisationMutation
>;
export const createUserProfile = /* GraphQL */ `mutation CreateUserProfile(
  $input: CreateUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  createUserProfile(input: $input, condition: $condition) {
    organisation
    id
    email
    firstName
    lastName
    telephone
    title
    department
    location
    status
    lastStatusChange
    preferences
    avatar
    lastNotificationSeen
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserProfileMutationVariables,
  APITypes.CreateUserProfileMutation
>;
export const updateUserProfile = /* GraphQL */ `mutation UpdateUserProfile(
  $input: UpdateUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  updateUserProfile(input: $input, condition: $condition) {
    organisation
    id
    email
    firstName
    lastName
    telephone
    title
    department
    location
    status
    lastStatusChange
    preferences
    avatar
    lastNotificationSeen
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserProfileMutationVariables,
  APITypes.UpdateUserProfileMutation
>;
export const deleteUserProfile = /* GraphQL */ `mutation DeleteUserProfile(
  $input: DeleteUserProfileInput!
  $condition: ModelUserProfileConditionInput
) {
  deleteUserProfile(input: $input, condition: $condition) {
    organisation
    id
    email
    firstName
    lastName
    telephone
    title
    department
    location
    status
    lastStatusChange
    preferences
    avatar
    lastNotificationSeen
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserProfileMutationVariables,
  APITypes.DeleteUserProfileMutation
>;
export const addUser = /* GraphQL */ `mutation AddUser($email: String!, $firstName: String, $lastName: String) {
  addUser(email: $email, firstName: $firstName, lastName: $lastName)
}
` as GeneratedMutation<
  APITypes.AddUserMutationVariables,
  APITypes.AddUserMutation
>;
export const convertCard = /* GraphQL */ `mutation ConvertCard(
  $cardId: ID!
  $newCardTypeId: ID!
  $name: String
  $description: String
  $attributes: [AttributeInput]
  $_auditContextType: EventContext
  $_auditContextId: String
) {
  convertCard(
    cardId: $cardId
    newCardTypeId: $newCardTypeId
    name: $name
    description: $description
    attributes: $attributes
    _auditContextType: $_auditContextType
    _auditContextId: $_auditContextId
  )
}
` as GeneratedMutation<
  APITypes.ConvertCardMutationVariables,
  APITypes.ConvertCardMutation
>;
export const copyCard = /* GraphQL */ `mutation CopyCard(
  $originalCardId: ID
  $_auditContextType: EventContext
  $_auditContextId: String
) {
  copyCard(
    originalCardId: $originalCardId
    _auditContextType: $_auditContextType
    _auditContextId: $_auditContextId
  )
}
` as GeneratedMutation<
  APITypes.CopyCardMutationVariables,
  APITypes.CopyCardMutation
>;
export const copyCardSet = /* GraphQL */ `mutation CopyCardSet($originalCardSetId: ID, $options: CopyCardSetOptions) {
  copyCardSet(originalCardSetId: $originalCardSetId, options: $options)
}
` as GeneratedMutation<
  APITypes.CopyCardSetMutationVariables,
  APITypes.CopyCardSetMutation
>;
export const createOrUpdateScoreData = /* GraphQL */ `mutation CreateOrUpdateScoreData($input: CreateOrUpdateScoreDataInput) {
  createOrUpdateScoreData(input: $input)
}
` as GeneratedMutation<
  APITypes.CreateOrUpdateScoreDataMutationVariables,
  APITypes.CreateOrUpdateScoreDataMutation
>;
export const createRelationshipExt = /* GraphQL */ `mutation CreateRelationshipExt(
  $sourceId: ID!
  $sourceType: EntityType!
  $targetId: ID!
  $targetType: EntityType!
  $type: RelationshipType
  $_auditContextType: EventContext
  $_auditContextId: String
) {
  createRelationshipExt(
    sourceId: $sourceId
    sourceType: $sourceType
    targetId: $targetId
    targetType: $targetType
    type: $type
    _auditContextType: $_auditContextType
    _auditContextId: $_auditContextId
  ) {
    organisation
    id
    type
    sourceId
    sourceType
    sourceCardId
    toSourceCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    sourceCardSetId
    toSourceCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    sourceWorksheetId
    toSourceWorksheet {
      organisation
      id
      parentId
      name
      commentary
      typeId
      ordinal
      createdAt
      createdBy
      updatedAt
      updatedBy
      toDataSources {
        items {
          organisation
          id
          type
          sourceId
          sourceType
          sourceCardId
          sourceCardSetId
          sourceWorksheetId
          sourcePlaybookPageId
          targetId
          targetType
          targetCardId
          targetCardSetId
          createdAt
          createdBy
          updatedAt
          updatedBy
          _auditContextType
          _auditContextId
          __typename
        }
        nextToken
        __typename
      }
      options
      __typename
    }
    sourcePlaybookPageId
    toSourcePlaybookPage {
      organisation
      id
      cardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      commentary
      playbookPageType
      worksheetId
      ordinal
      playbookId
      toPlaybook {
        organisation
        id
        name
        description
        toPlaybookPages {
          nextToken
          __typename
        }
        owner
        editors
        orgEdit
        createdAt
        createdBy
        updatedAt
        updatedBy
        capitalName
        workbooks
        __typename
      }
      workbookId
      title
      toCardSets {
        items {
          organisation
          id
          cardSetId
          cardId
          playbookPageId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      include
      options
      createdAt
      createdBy
      updatedAt
      updatedBy
      cardSets
      workbooks
      cards
      worksheets
      playbookPageCardTypeId
      __typename
    }
    targetId
    targetType
    targetCardId
    toTargetCard {
      organisation
      id
      owner
      createdBy
      editors
      orgEdit
      name
      capitalName
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      description
      attributes {
        attributeDefinitionID
        value
        __typename
      }
      toAttributes {
        items {
          cardId
          attributeDefinitionId
          organisation
          value
          refToCardId
          refToCardSetId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toScoreData {
        items {
          cardId
          id
          scoreDefinitionId
          organisation
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardSets {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      deleteAfter
      _auditContextType
      _auditContextId
      createdFromId
      createdFromIds
      cardTypeToCardsId
      cardToCardCategoryId
      cardToCardTypeId
      __typename
    }
    targetCardSetId
    toTargetCardSet {
      organisation
      id
      type
      name
      capitalName
      description
      owner
      createdBy
      editors
      orgEdit
      toCards {
        items {
          organisation
          id
          cardId
          cardSetId
          createdBy
          updatedBy
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      toCardCategory {
        id
        name
        description
        toCardTypes {
          nextToken
          __typename
        }
        createdAt
        updatedAt
        owner
        __typename
      }
      toCardType {
        id
        name
        cardCategoryID
        toCards {
          nextToken
          __typename
        }
        attributeDefinitions {
          id
          name
          attributeType
          configuration
          __typename
        }
        scoreDefinitions {
          id
          name
          shortName
          description
          scoreType
          minimumValue
          maximumValue
          target
          __typename
        }
        defaultScoreDefinitionId
        createdAt
        updatedAt
        owner
        __typename
      }
      createdAt
      updatedAt
      updatedBy
      hiddenWorksheets
      copiedFrom
      counts {
        comments
        worksheets
        cards
        __typename
      }
      processProgress {
        stageId
        progress
        __typename
      }
      cardSetToCardCategoryId
      cardSetToCardTypeId
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    _auditContextType
    _auditContextId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRelationshipExtMutationVariables,
  APITypes.CreateRelationshipExtMutation
>;
export const createWorksheetExt = /* GraphQL */ `mutation CreateWorksheetExt(
  $parentId: ID!
  $typeId: String!
  $ordinal: Int!
  $name: String
  $commentary: String
  $options: AWSJSON
) {
  createWorksheetExt(
    parentId: $parentId
    typeId: $typeId
    ordinal: $ordinal
    name: $name
    commentary: $commentary
    options: $options
  ) {
    organisation
    id
    parentId
    name
    commentary
    typeId
    ordinal
    createdAt
    createdBy
    updatedAt
    updatedBy
    toDataSources {
      items {
        organisation
        id
        type
        sourceId
        sourceType
        sourceCardId
        toSourceCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        sourceCardSetId
        toSourceCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        sourceWorksheetId
        toSourceWorksheet {
          organisation
          id
          parentId
          name
          commentary
          typeId
          ordinal
          createdAt
          createdBy
          updatedAt
          updatedBy
          options
          __typename
        }
        sourcePlaybookPageId
        toSourcePlaybookPage {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        targetId
        targetType
        targetCardId
        toTargetCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        targetCardSetId
        toTargetCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        _auditContextType
        _auditContextId
        __typename
      }
      nextToken
      __typename
    }
    options
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWorksheetExtMutationVariables,
  APITypes.CreateWorksheetExtMutation
>;
export const deleteCommentExt = /* GraphQL */ `mutation DeleteCommentExt($contextId: ID!, $createdAt: String!, $id: ID!) {
  deleteCommentExt(contextId: $contextId, createdAt: $createdAt, id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteCommentExtMutationVariables,
  APITypes.DeleteCommentExtMutation
>;
export const deleteRelationshipExt = /* GraphQL */ `mutation DeleteRelationshipExt($id: ID!) {
  deleteRelationshipExt(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteRelationshipExtMutationVariables,
  APITypes.DeleteRelationshipExtMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser($userToDeleteId: ID!, $newOwnerId: ID) {
  deleteUser(userToDeleteId: $userToDeleteId, newOwnerId: $newOwnerId)
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const deleteWorksheetExt = /* GraphQL */ `mutation DeleteWorksheetExt($id: ID!) {
  deleteWorksheetExt(id: $id)
}
` as GeneratedMutation<
  APITypes.DeleteWorksheetExtMutationVariables,
  APITypes.DeleteWorksheetExtMutation
>;
export const updateWorksheetExt = /* GraphQL */ `mutation UpdateWorksheetExt(
  $id: ID!
  $ordinal: Int
  $name: String
  $commentary: String
  $options: AWSJSON
) {
  updateWorksheetExt(
    id: $id
    ordinal: $ordinal
    name: $name
    commentary: $commentary
    options: $options
  ) {
    organisation
    id
    parentId
    name
    commentary
    typeId
    ordinal
    createdAt
    createdBy
    updatedAt
    updatedBy
    toDataSources {
      items {
        organisation
        id
        type
        sourceId
        sourceType
        sourceCardId
        toSourceCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        sourceCardSetId
        toSourceCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        sourceWorksheetId
        toSourceWorksheet {
          organisation
          id
          parentId
          name
          commentary
          typeId
          ordinal
          createdAt
          createdBy
          updatedAt
          updatedBy
          options
          __typename
        }
        sourcePlaybookPageId
        toSourcePlaybookPage {
          organisation
          id
          commentary
          playbookPageType
          worksheetId
          ordinal
          playbookId
          workbookId
          title
          include
          options
          createdAt
          createdBy
          updatedAt
          updatedBy
          cardSets
          workbooks
          cards
          worksheets
          playbookPageCardTypeId
          __typename
        }
        targetId
        targetType
        targetCardId
        toTargetCard {
          organisation
          id
          owner
          createdBy
          editors
          orgEdit
          name
          capitalName
          description
          createdAt
          updatedAt
          updatedBy
          deleteAfter
          _auditContextType
          _auditContextId
          createdFromId
          createdFromIds
          cardTypeToCardsId
          cardToCardCategoryId
          cardToCardTypeId
          __typename
        }
        targetCardSetId
        toTargetCardSet {
          organisation
          id
          type
          name
          capitalName
          description
          owner
          createdBy
          editors
          orgEdit
          createdAt
          updatedAt
          updatedBy
          hiddenWorksheets
          copiedFrom
          cardSetToCardCategoryId
          cardSetToCardTypeId
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        _auditContextType
        _auditContextId
        __typename
      }
      nextToken
      __typename
    }
    options
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateWorksheetExtMutationVariables,
  APITypes.UpdateWorksheetExtMutation
>;
export const generateSystemData = /* GraphQL */ `mutation GenerateSystemData($params: [GenerateDataParam]) {
  generateSystemData(params: $params)
}
` as GeneratedMutation<
  APITypes.GenerateSystemDataMutationVariables,
  APITypes.GenerateSystemDataMutation
>;
