import Highcharts from 'highcharts';
import { EditablePageValues } from '../playbooks/EditablePlaybookPage';

export const getPieChartOptions = (
    title: string,
    seriesData: any[],
    playbookPageForm?: EditablePageValues,
    setActivePieLabel?: React.Dispatch<React.SetStateAction<string>>
) => {
    const pieChartOptions: Highcharts.Options = {
        chart: {
            type: 'pie',
            height: '100%',
        },
        title: {
            text: title,
            style: {
                fontSize: playbookPageForm ? '12px' : '6px',
            },
            align: 'left',
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: false,
                },
                size: '90%',
                point: {
                    events: {
                        click: function () {
                            setActivePieLabel && setActivePieLabel(this.name);
                        },
                    },
                },
            },
        },
        legend: {
            enabled: true,
            align: 'right',
            verticalAlign: 'middle',
            layout: 'vertical',
            itemStyle: {
                fontSize: playbookPageForm ? '12px' : '6px',
            },
            symbolHeight: playbookPageForm ? 15 : 6,
            symbolWidth: playbookPageForm ? 15 : 6,
        },
        series: [
            {
                type: 'pie',
                name: title,
                data: seriesData,
            },
        ],
        credits: {
            enabled: false,
        },
    };

    return pieChartOptions;
};
