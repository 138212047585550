import {
    Alert,
    Box,
    Divider,
    Grid,
    IconButton,
    Snackbar,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
    CardComponentType,
    CardPage,
    PageIdentifier,
    SortActions,
    ViewType,
    sortFieldToResponseMap,
} from '../../components/cards/cardTypes';
import useFilterCards from '../../hooks/useFilterCards';
import { useContext, useEffect, useMemo, useState } from 'react';
import { AuthSession, UserPermissions } from '../../globals';
import useIconStates from '../../hooks/useIconStates';
import { ActiveIcons } from '../../components/cards/views/CardTypeLayout';
import { alpha, styled } from '@mui/material/styles';
import { ReactComponent as PencilIcon } from '../../assets/icons/Pencil.svg';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { AppContext, WorkbookContext } from '../../components/contexts';
import AddIcon from '@mui/icons-material/Add';
import { getUserOrganisation } from '../../helpers/auth';
import { useNavigate, useParams } from 'react-router-dom';
import { GraphQLQuery, generateClient } from 'aws-amplify/api';
import Fooda from '../../assets/images/fooda.png';

import {
    getCard,
    getWorksheet,
    worksheetsByParentId,
} from '../../graphql/queries';
import * as subscriptions from '../../graphql/subscriptions';
import {
    AddOrRemoveCardsFromSetMutation,
    Card,
    CardSet,
    CreateWorksheetExtMutation,
    DeleteWorksheetExtMutation,
    EntityType,
    EventContext,
    GetCardQuery,
    GetCardSetWithCardsQuery,
    GetWorksheetQuery,
    ModelSortDirection,
    UpdateCardMutation,
    UpdateCardSetMutation,
    Worksheet,
    WorksheetsByParentIdQuery,
} from '../../API';
import { v4 as uuidv4 } from 'uuid';
import Grid2 from '@mui/material/Unstable_Grid2';
import { queryData } from '../../actions/QueryData';
import {
    getCardDataWithScoreData,
    getCardSetWithCards,
} from '../../graphql/custom-queries';
import { CardToDelete, cardToCardComponentProps } from '../cards';
import CardComponentSmall from '../../components/cards/CardComponentSmall';

import { StyledModal } from '../../components/Modal';
import ModalContainer from '../../components/ModalContainer';
import CardSetModal, {
    modalBackdropStyles,
} from '../../components/cards/CardSetModal';
import CreateCard from '../../components/forms/CreateCard';
import WorksheetSelector from '../../components/forms/input-fields/WorksheetSelector';
import { FieldType } from '../../components/forms/input-fields/inputFieldTypes';
import { worksheets as worksheetTypes } from '../../components/forms/worksheets';

import { getChartSettings } from '../../helpers/worksheets';
import {
    addOrRemoveCardsFromSet,
    copyCard,
    createWorksheetExt,
    deleteWorksheetExt,
    updateCard,
    updateCardSet,
} from '../../graphql/mutations';
import ManagerControls from '../../components/cards/ManagerControls';
import { getContext, getFutureDate } from '../../helpers/utils';
import useSnackbar from '../../hooks/useSnackbar';
import RemoveCard from '../../components/cards/RemoveCard';
import AddExistingCards from '../../components/forms/AddExistingCard';
import AddCardMenu from '../../components/cards/AddCardMenu';
import SparkIcon from '../../assets/icons/spark-icon-transparent.gif';
import CreateSparkCard from '../../components/forms/CreateSparkCard';
import { addCardToCardSet } from '../../actions/CardSets';
import GhostCard from '../../components/cards/GhostCard';
import {
    getCardSetPermissions,
    getUserPermissions,
} from '../../helpers/permissions';
import { CardContext } from '../../components/cards/context';
import CardListView from '../../components/cards/views/CardListView';
import { ProcessAndStep } from '../../components/worksheets/WorksheetMain';
import WorkbookHeader from '../../components/workbooks/workbook/WorkbookHeader';
import WorkbookTop from '../../components/workbooks/workbook/WorkbookTop';
import WorkbookMain from '../../components/workbooks/workbook/WorkbookMain';
import Process, { ProcessName } from '../../components/workbooks/Process';
import FoodaImage from '../../components/workbooks/workbook/FoodaImage';
import { processSteps } from '../../components/forms/processSteps';
import CardMenu from '../../components/cards/CardMenu';
import { getCategoryHexText } from '../../helpers/category';
import { getWorkbookCardMenuItems } from '../../helpers/worksheetCards';
import CardDelete from '../../components/cards/CardDelete';
import { LastCreatedFrom } from '../../components/forms/CreateCard/types';
import { createRelationship } from '../../helpers/relationships';
import { getRelationshipsByCardsIds } from '../../actions/Relationships';

export const SelectionType = {
    ...{
        WORKSHEETS: 'worksheets',
        ACTIVITIES: 'activities',
        COMMENTS: 'comments',
        MEMBERS: 'members',
        INFO: 'info',
    },
    ...ProcessName,
} as const;
export type SelectionType = (typeof SelectionType)[keyof typeof SelectionType];

export interface NumbersCount {
    worksheets: number;
    comments: number;
    users: number;
}
const client = generateClient();
export const getWorksheetById = async (
    id: string,
    userOrganisation: string
) => {
    const query = getWorksheet;
    const res = (await client.graphql({
        query,
        variables: {
            id: id,
            organisation: userOrganisation,
        },
    })) as { data: GetWorksheetQuery };

    return res.data.getWorksheet;
};
const Workbook = () => {
    const [progressArray, setProgressArray] = useState<
        { stageId: string; progress: number }[] | null
    >(null);
    const { cardTypeObject, setLoaded, applyFilter, applySort, users, user } =
        useContext(AppContext);
    const [cardToDelete, setCardToDelete] = useState<CardToDelete | null>(null);
    const userOrganisation = getUserOrganisation(user);
    const [selectedViewType, setViewOption] = useState<ViewType>(ViewType.GRID);
    const [selectionType, setSelectionType] = useState<SelectionType | null>(
        null
    );
    const [isThrottling, setIsThrottling] = useState(false);
    const [convertSparkCardId, setConvertSparkCardId] = useState('');
    const [cardsLoaded, setCardsLoaded] = useState(false);
    const [worksheetsLoaded, setWorksheetsLoaded] = useState(false);
    const [filterCriteria, setFilterCriteria] = useFilterCards(
        `filter_${PageIdentifier.WORKSHEET}`
    );
    const [activeCardPage, setActiveCardPage] = useState<CardPage>(
        CardPage.DETAILS
    );
    const [edit, setEdit] = useState(false);
    const [activeCardId, setActiveCardId] = useState<string | null>();
    const [permissions, setPermissions] = useState<UserPermissions[]>([]);
    const [activeIcons, setActiveIcons] = useIconStates('WorkBookControls');
    const [multiSelectedCards, setMultiSelectedCards] = useState<
        CardToDelete[] | []
    >([]);
    const [showRemoveCardWizard, setShowRemoveCardWizard] = useState(false);
    const [cardToRemove, setCardToRemove] = useState<CardToDelete | null>(null);
    const [loading, setLoading] = useState(false);
    const [cardSortDirection, setCardSortDirection] = useState<'ASC' | 'DESC'>(
        () =>
            (localStorage.getItem(
                `selectedSortDirection_${PageIdentifier.CARDS}`
            ) as 'ASC' | 'DESC') || 'DESC'
    );
    const [cardSortField, setCardSortField] = useState<SortActions>(
        () =>
            (localStorage.getItem(
                `selectedSortAction_${PageIdentifier.CARDS}`
            ) as SortActions) || 'UpdatedAt'
    );
    const [cardFrom, setCardFrom] = useState<Card[] | null>(null);
    const [lastCreatedFrom, setLastCreatedFrom] =
        useState<LastCreatedFrom | null>(null);
    const [showAddWorksheet, setShowAddWorksheet] = useState(false);
    const [showCreateCardWizard, setShowCreateCardWizard] = useState(false);
    const [showAddCardsWizard, setShowAddCardsWizard] = useState(false);
    const [showCreateSparkWizard, setShowCreateSparkWizard] = useState(false);
    const [showDeleteCardWizard, setShowDeleteCardWizard] = useState(false);
    const [cardsObject, setCardsObject] = useState<{ [key: string]: Card }>({});
    const [addMenuAnchor, setAddMenuAnchor] = useState<HTMLElement | null>();
    const { closeSnackbar, isOpen, showSnackbar, message, severity, duration } =
        useSnackbar();
    const [processAndStep, setProcessAndStep] = useState<ProcessAndStep | null>(
        null
    );
    const [counts, setCounts] = useState<NumbersCount>({
        worksheets: 0,
        comments: 0,
        users: 0,
    });
    const [selectedCardIds, setSelectedCardIds] = useState<string[]>([]);
    const [cardAdded, setCardAdded] = useState(false);
    const [description, setDescription] = useState('');

    const [worksheets, setWorksheets] = useState<Worksheet[]>([]);
    const [workbook, setWorkbook] = useState<CardSet>();
    const [cards, setCards] = useState<Card[]>([]);
    const [deleting, setDeleting] = useState(false);
    const { id } = useParams();

    const theme = useTheme();
    const navigate = useNavigate();

    useEffect(() => {
        const updatedCardsObject = { ...cardsObject };
        cards?.forEach((card) => (updatedCardsObject[card.id] = card));

        setCardsObject(updatedCardsObject);
    }, [cards]);

    const viewOptions = {
        [ViewType.GRID]: true,
        [ViewType.LIST]: true,
    };
    const handleClick = (cardId: string, cardPage?: CardPage) => {
        if (!activeCardId || (activeCardId && cardId !== activeCardId)) {
            setActiveCardId(cardId);
        }
        if (cardPage) {
            setActiveCardPage(cardPage);
        }
    };

    const handleCopy = async (cardId: string) => {
        const context = getContext(location.pathname, id);

        try {
            const res = await client.graphql({
                query: copyCard,
                variables: {
                    originalCardId: cardId,
                    _auditContextType: context.contextType,
                    _auditContextId: context.contextId,
                },
            });

            const originalCard = cards.find((card) => card.id === cardId);

            if (originalCard) {
                const copy = { ...originalCard };
                copy.name = `Copy of ${originalCard.name}`;
                copy.id = res.data.copyCard;

                addCardToWorkbook(copy);
            }

            handleModalClose();
        } catch (err) {
            showSnackbar({
                message: 'Error while copying the card',
                severity: 'error',
            });
        }
    };
    const handleDelete = async (id: string, organisation: string) => {
        if (multiSelectedCards.some((card) => card.id === id)) {
            setShowDeleteCardWizard(true);
        } else {
            setShowDeleteCardWizard(true);
            setCardToDelete({
                id: id,
                organisation: organisation,
                cardTypeId: cardsObject[id].toCardType.id,
                cardCategoryId: cardsObject[id].toCardCategory.id,
            });
        }
    };

    const getWorksheetsForWorkBook = async () => {
        if (!id) return;

        try {
            const response = (await client.graphql({
                query: worksheetsByParentId,

                variables: {
                    parentId: id,
                    sortDirection: ModelSortDirection.ASC,
                },
            })) as { data: WorksheetsByParentIdQuery };

            const worksheets = response.data?.worksheetsByParentId?.items ?? [];

            setWorksheets(worksheets as Worksheet[]);
            setWorksheetsLoaded(true);
        } catch (e) {
            console.log(e);
            return [];
        }
    };
    const getWorkbook = async () => {
        const organisation = getUserOrganisation(user);
        const response = await client.graphql<GraphQLQuery<any>>({
            query: getCardSetWithCards,
            variables: {
                organisation: organisation,
                id: id,
            },
        });
        const cleanedData = response?.data?.getCardSet?.processProgress?.map(
            (item: any) => {
                const { __typename, ...rest } = item;
                return rest;
            }
        );

        setProgressArray(cleanedData);

        setWorkbook(response.data.getCardSet as CardSet);
    };
    const getWorkbookItems = async () => {
        if (!cardSortDirection || !cardSortField) return;
        const { data } = await queryData(
            false,
            cardSortDirection,
            null,
            activeIcons,
            getCardDataWithScoreData,
            sortFieldToResponseMap[cardSortField],
            'getCardData',
            filterCriteria,
            setActiveIcons,
            id,
            user
        );
        setCards(data as Card[]);
        setCardsLoaded(true);
    };
    const handleRemove = async (id: string) => {
        if (multiSelectedCards.some((card) => card.id === id)) {
            setShowRemoveCardWizard(true);
        } else {
            const card = cards.find((card) => card.id === id);

            setShowRemoveCardWizard(true);
            setCardToRemove({
                id: id,
                organisation: workbook?.organisation ?? '',
                cardTypeId: card?.toCardType.id,
                cardCategoryId: card?.toCardCategory.id,
            });
        }
    };
    useEffect(() => {
        const sortAction = localStorage.getItem(
            `selectedSortAction_${PageIdentifier.CARDS}`
        ) as SortActions;
        if (sortAction) {
            setCardSortField(sortAction);
        }
        const sortDirection = localStorage.getItem(
            `selectedSortDirection_${PageIdentifier.CARDS}`
        ) as 'ASC' | 'DESC';
        if (sortDirection) {
            setCardSortDirection(sortDirection);
        }
    }, [applySort]);
    useEffect(() => {
        getWorksheetsForWorkBook();
        getWorkbook();
    }, [id]);

    useEffect(() => {
        getWorkbookItems();
    }, [activeIcons, filterCriteria, cardSortDirection]);
    useEffect(() => {
        if (user) {
            if (workbook) {
                setDescription(workbook.description as string);
                const userPermissions = getCardSetPermissions(
                    user?.tokens?.idToken?.payload.sub ?? '',
                    workbook
                );

                setPermissions(userPermissions);
                setCounts({
                    ...counts,
                    worksheets: workbook.counts?.worksheets as number,
                    comments: workbook.counts?.comments as number,
                    users: workbook?.editors?.length as number,
                });
            }
        }
    }, [workbook, user]);

    useEffect(() => {
        setCounts((counts) => {
            const newCounts = { ...counts };
            newCounts.worksheets = worksheets.length;
            return newCounts;
        });
    }, [worksheets.length]);

    const handleModalClose = () => {
        setActiveCardId(null);
        setShowCreateCardWizard(false);
        setShowAddWorksheet(false);
        setShowAddCardsWizard(false);
        setCardFrom(null);
    };

    const toggleIcon = (
        iconName: keyof ActiveIcons,
        selectedSortAction: string
    ) => {
        if (setActiveIcons) {
            setActiveIcons((prevState) => ({
                ...prevState,
                [iconName]:
                    iconName === 'Sort'
                        ? selectedSortAction ===
                          SortActions.LAST_MODIFIED_RECENT_FIRST
                            ? false
                            : true
                        : !prevState[iconName],
            }));
        }
    };

    const saveWorkSheetType = async (selectedWorksheet: string) => {
        setLoading(true);
        const worksheet = worksheetTypes.find(
            (item) => item.i === selectedWorksheet
        );
        if (!id || !worksheet) return;
        const settings = getChartSettings(worksheet?.n || '');
        let options = {};
        const nodeSizeSelection =
            settings?.options.chartData.nodeSize.selection;
        options = {
            chartData: {
                xAxis: settings?.options.chartData.xAxisOptions[0],
                yAxis: settings?.options.chartData.yAxisOptions[1],
                nodeSize: {
                    selection: nodeSizeSelection[nodeSizeSelection.length - 1],
                },
                nodeColor: {
                    selection:
                        settings?.options.chartData.nodeColor.selection[0],
                },
                hideGaps: settings?.options.chartData.hideGaps,
            },
            layout: [
                {
                    include: settings?.options.include,
                    layout: settings?.options.layout,
                },
            ],
        };

        const userOrganisation = getUserOrganisation(user);

        const ordinals = worksheets.length
            ? (worksheets
                  .map((worksheet) => worksheet.ordinal)
                  .filter(
                      (ordinal) => ordinal !== null && ordinal !== undefined
                  ) as number[])
            : [1];

        const lowest = Math.min(...ordinals);

        const query = createWorksheetExt;
        const variables = {
            typeId: worksheet?.i,
            organisation: userOrganisation,
            ordinal: lowest != null ? lowest - 1 : 0,
            parentId: id,
            options: JSON.stringify(options),
            name: worksheet?.n,
        };
        try {
            const res = (await client.graphql({
                query,
                variables: variables,
            })) as { data: CreateWorksheetExtMutation };

            const sheets = [...worksheets];
            sheets.unshift(res.data.createWorksheetExt as Worksheet);

            showSnackbar({
                message: 'Worksheet added',
                severity: 'success',
            });

            setWorksheets(sheets);
            handleModalClose();
            setLoading(false);
        } catch (e) {
            console.log(e);
        }
    };

    const confirmRemove = async (id: string, multiDelete?: boolean) => {
        try {
            const res = (await client.graphql({
                query: addOrRemoveCardsFromSet,
                variables: {
                    cardSetId: workbook!.id,
                    cardIds: [id],
                },
            })) as { data: AddOrRemoveCardsFromSetMutation };

            if (res) {
                setSelectedCardIds([]);
            }
            setCardAdded(true);
            setTimeout(() => {
                setCardAdded(false);
            }, 1000);
            showSnackbar({
                message: 'Card removed from workbook',
                severity: 'success',
            });
        } catch (err) {
            console.log(err);
            showSnackbar({
                message: 'Error while trying to remove card',
                severity: 'error',
            });
        }

        if (!multiDelete) setShowRemoveCardWizard(false);
        setCardToRemove(null);
    };

    const confirmMultiRemove = async () => {
        setLoading(true);
        const multiSelectedCardIds = multiSelectedCards.map((card) => card.id);
        try {
            const res = (await client.graphql({
                query: addOrRemoveCardsFromSet,
                variables: {
                    cardSetId: workbook!.id,
                    cardIds: multiSelectedCardIds,
                },
            })) as { data: AddOrRemoveCardsFromSetMutation };

            if (res) {
                setSelectedCardIds([]);
            }
            setCardAdded(true);
            setTimeout(() => {
                setCardAdded(false);
            }, 1000);
            showSnackbar({
                message: 'Cards added successfully',
                severity: 'info',
            });
        } catch (err) {
            console.log(err);
            showSnackbar({
                message: 'Error while trying to add cards',
                severity: 'error',
            });
        }
        setLoading(false);
        setMultiSelectedCards([]);
        setShowRemoveCardWizard(false);
    };
    const setAddMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            setAddMenuAnchor(e.currentTarget);
        } else {
            setAddMenuAnchor(null);
        }
    };

    const addCardToWorkbook = async (card: Card) => {
        if (!workbook) return;
        const cardId = card.id;
        const response = await addCardToCardSet({
            organisation: 'x',
            cardSetId: workbook?.id,
            cardId,
        });
        const newCard = response?.data?.createCardsCardSets?.card;
        showSnackbar({
            message: 'Card added to workbook',
            severity: 'success',
        });
        setCards((prev: any) => {
            return [newCard, ...prev];
        });
    };

    const deleteWorksheet = async (id: string) => {
        setDeleting(true);
        const query = deleteWorksheetExt;
        try {
            const res = (await client.graphql({
                query,
                variables: {
                    id: id,
                },
            })) as { data: DeleteWorksheetExtMutation };
            if (res.data.deleteWorksheetExt) {
                setDeleting(false);
                showSnackbar({
                    message: 'Worksheet deleted successfully',
                    severity: 'success',
                });
            }
        } catch (e) {
            console.log(e);
        }
    };

    const refreshCard = async (id: string, organisation: string) => {
        const response = (await client.graphql({
            query: getCard,
            variables: {
                id: id,
                organisation: organisation,
            },
        })) as { data: GetCardQuery };

        showSnackbar({
            message: 'Card updated',
            severity: 'success',
        });

        const card = response.data.getCard;

        const items = [...(cards ?? [])];

        const index = items.findIndex((item) => item.id === id);

        if (!!card && index !== -1) {
            items[index] = card as Card;
            setCards(items);
        }
    };
    const confirmDelete = async (
        id: string,
        organisation: string,
        multiDelete?: boolean
    ) => {
        const data = {
            id: id,
            organisation: organisation,
            deleteAfter: getFutureDate(30),
        };
        try {
            const res = await client.graphql<GraphQLQuery<UpdateCardMutation>>({
                query: updateCard,
                variables: {
                    input: data,
                },
            });
            showSnackbar({
                message: 'Card deleted',
                severity: 'info',
            });
            setCards((prevItems) => prevItems.filter((item) => item.id !== id));
            setLoaded && setLoaded(true);
            setShowDeleteCardWizard(false);
        } catch (err) {
            console.log(err);
            setShowDeleteCardWizard(false);
            showSnackbar({
                message: 'Error while removing card',
                severity: 'error',
            });
        }
        if (!multiDelete) setShowDeleteCardWizard(false);
    };

    const confirmMultiDelete = async () => {
        setDeleting(true);
        for (const card of multiSelectedCards) {
            await confirmDelete(card.id, card.organisation, true);
        }
        setDeleting(false);
        setMultiSelectedCards([]);
        setShowDeleteCardWizard(false);
    };
    useEffect(() => {
        if (users.length) {
            const createSub = client
                .graphql({ query: subscriptions.onCreateNotification })
                .subscribe({
                    next: async ({ data }) => {
                        const notification = data.onCreateNotification;

                        if (
                            notification.entity === 'Worksheet' &&
                            notification.type === 'Create'
                        ) {
                            const worksheet = await getWorksheetById(
                                notification?.worksheetId as string,
                                userOrganisation
                            );
                            if (
                                worksheet?.parentId &&
                                worksheet.parentId === id
                            ) {
                                setWorksheets((worksheets) => {
                                    const worksheetItems = [...worksheets];

                                    if (
                                        !worksheetItems.find(
                                            (worksheetItem) =>
                                                worksheetItem.id ===
                                                worksheet.id
                                        )
                                    ) {
                                        worksheetItems.unshift(
                                            worksheet as Worksheet
                                        );
                                    }

                                    return worksheetItems as Worksheet[];
                                });
                            }
                        } else if (
                            notification.entity === 'Worksheet' &&
                            notification.type === 'Delete'
                        ) {
                            setWorksheets((worksheets) => {
                                const worksheetItems = [...worksheets];

                                return worksheetItems.filter(
                                    (worksheet) =>
                                        worksheet.id !==
                                        notification.worksheetId
                                );
                            });
                        } else if (
                            notification.entity === 'Worksheet' &&
                            notification.type === 'Update'
                        ) {
                            const worksheet = await getWorksheetById(
                                notification?.worksheetId as string,
                                userOrganisation
                            );
                            if (
                                worksheet?.parentId &&
                                worksheet.parentId === id
                            ) {
                                setWorksheets((worksheets) => {
                                    const worksheetItems = [...worksheets];
                                    const index = worksheetItems.findIndex(
                                        (worksheetItem) =>
                                            worksheetItem.id ===
                                            notification.worksheetId
                                    );

                                    if (index !== -1) {
                                        worksheetItems[index] =
                                            worksheet as Worksheet;
                                    }

                                    return worksheetItems;
                                });
                            }
                        }
                    },
                    error: (error) => console.warn(error),
                });
            return () => createSub.unsubscribe();
        }
    }, [users]);
    const frameProcess = progressArray?.find((item) => item?.stageId === '1');
    const frameProgress = frameProcess?.progress ?? 0;

    const handleConvert = async (id: string) => {
        setShowCreateCardWizard(true);
        setConvertSparkCardId(id);
    };
    const createCardRelationship = async (target: string, source: string) => {
        if (isThrottling) return;

        setIsThrottling(true);
        try {
            const result: any = await getRelationshipsByCardsIds({
                cardIds: [target],
                organisation: workbook?.organisation as string,
            });
            if (
                result?.data.some(
                    (item: any) =>
                        item.sourceCardId === source || item.targetId === source
                )
            ) {
                showSnackbar({
                    message: 'Relationship already exists',
                    severity: 'info',
                });
            } else {
                const relationship = await createRelationship(
                    target,
                    source,
                    EntityType.Card,
                    EntityType.Card,
                    id,
                    EventContext.CardSet
                );

                if (relationship?.data.createRelationshipExt) {
                    showSnackbar({
                        message: 'Relationship created',
                        severity: 'success',
                    });
                }
            }

            setIsThrottling(false);
        } catch (error) {
            console.error(
                `Error fetching relationships for targetId ${target}:`,
                error
            );
            return [];
        }
    };

    const WorkbookCard = ({ card }: { card: Card }) => {
        const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
        const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
            if (e) {
                e.stopPropagation();
                setMenuAnchor(e.currentTarget);
            } else {
                setMenuAnchor(null);
            }
        };
        const menuItems = useMemo(
            () =>
                getWorkbookCardMenuItems({
                    card,
                    cardsObject,
                    convert: handleConvert,
                    createCardRelationship,
                    setCardFrom,
                    handleCopy,
                    handleDelete,
                    handleRemove,
                    multiSelectedCards,
                }),
            [card.id]
        );

        return (
            <Box sx={{ position: 'relative' }} key={card.id}>
                <CardComponentSmall
                    key={card.id}
                    data={cardToCardComponentProps(card)}
                    permissions={
                        user
                            ? getUserPermissions(
                                  user?.tokens.idToken.payload.sub as string,
                                  card
                              )
                            : []
                    }
                    onClick={() => handleClick(card.id)}
                    onDelete={() => {}}
                    handleDelete={() => {}}
                    handleConvert={() => {}}
                    {...(handleRemove && {
                        handleRemove: handleRemove,
                    })}
                    multiSelectedCards={multiSelectedCards}
                    setMultiSelectedCards={setMultiSelectedCards}
                />
                <Box
                    sx={{
                        position: 'absolute',
                        zIndex: 1200,
                        paddingTop: '15px',
                        right: 0,
                        bottom: '0px',
                        width: '50px',
                        height: '50px',
                    }}
                >
                    <IconButton
                        aria-label=""
                        id="menu-button"
                        onClick={setMenu}
                        sx={{
                            ml: 2,
                            color: getCategoryHexText(
                                card.cardToCardCategoryId
                            ),
                        }}
                    >
                        <MenuIcon width={'20px'} height={'20px'} />
                    </IconButton>
                    {menuAnchor && (
                        <CardMenu
                            anchor={menuAnchor}
                            data={{
                                organisation: card.organisation || '',
                                id: card.id,
                            }}
                            menuItems={menuItems}
                            setMenu={setMenu}
                        />
                    )}
                </Box>
            </Box>
        );
    };
    return (
        <WorkbookContext.Provider
            value={{
                handleDelete: () => {},
                handleRemoveFromWorkbook: handleRemove,
            }}
        >
            <Box>
                <WorkbookHeader
                    workbook={workbook as CardSet}
                    setViewOption={setViewOption}
                    selectedViewType={selectedViewType}
                />
                <Box
                    sx={{
                        marginTop: '50px',
                        width: '100%',
                        padding: '20px 30px 10px 30px',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <WorkbookTop
                        workbook={workbook as CardSet}
                        user={user as AuthSession}
                        worksheets={worksheets}
                        selectionType={selectionType as SelectionType}
                        setSelectionType={setSelectionType}
                        counts={counts}
                    />
                    <Typography variant="h5" sx={{ mb: 1 }}>
                        Worksheets
                    </Typography>
                    <Box sx={{ width: '100%', mb: 1 }}>
                        {!selectionType ? (
                            <Grid
                                container
                                sx={{
                                    height: '560px',
                                    position: 'relative',
                                    mb: 6.8,
                                }}
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Grid item>
                                    <Process
                                        placement={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                        }}
                                        processName={ProcessName.ACT}
                                        processId={
                                            processSteps.find(
                                                (item) =>
                                                    item.name ===
                                                    ProcessName.ACT
                                            )?.id
                                        }
                                        setSelectionType={setSelectionType}
                                        headerColor="#0D47A1"
                                        workbook={workbook}
                                        progressArray={progressArray}
                                        setProgressArray={setProgressArray}
                                    />
                                </Grid>
                                <Grid item>
                                    <Process
                                        placement={{
                                            position: 'absolute',
                                            top: 0,
                                            right: 0,
                                        }}
                                        processName={ProcessName.OBSERVE}
                                        processId={
                                            processSteps.find(
                                                (item) =>
                                                    item.name ===
                                                    ProcessName.OBSERVE
                                            )?.id
                                        }
                                        setSelectionType={setSelectionType}
                                        headerColor="#64B5F6"
                                        workbook={workbook}
                                        progressArray={progressArray}
                                        setProgressArray={setProgressArray}
                                    />
                                </Grid>

                                <Grid
                                    item
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: 1,
                                    }}
                                >
                                    <FoodaImage
                                        selectionType={selectionType}
                                        setSelectionType={setSelectionType}
                                        frameProgress={frameProgress}
                                    />
                                </Grid>
                                <Grid item>
                                    <Process
                                        placement={{
                                            position: 'absolute',
                                            bottom: 0,
                                            right: 0,
                                        }}
                                        processName={ProcessName.ORIENT}
                                        processId={
                                            processSteps.find(
                                                (item) =>
                                                    item.name ===
                                                    ProcessName.ORIENT
                                            )?.id
                                        }
                                        setSelectionType={setSelectionType}
                                        headerColor="#2196F3"
                                        workbook={workbook}
                                        progressArray={progressArray}
                                        setProgressArray={setProgressArray}
                                    />
                                </Grid>
                                <Grid item>
                                    <Process
                                        placement={{
                                            position: 'absolute',
                                            bottom: 0,
                                            left: 0,
                                        }}
                                        processName={ProcessName.DECIDE}
                                        processId={
                                            processSteps.find(
                                                (item) =>
                                                    item.name ===
                                                    ProcessName.DECIDE
                                            )?.id
                                        }
                                        setSelectionType={setSelectionType}
                                        headerColor="#1976D2"
                                        workbook={workbook}
                                        progressArray={progressArray}
                                        setProgressArray={setProgressArray}
                                    />
                                </Grid>
                            </Grid>
                        ) : (
                            <WorkbookMain
                                id={id as string}
                                deleting={deleting}
                                setDeleting={setDeleting}
                                workbook={workbook as CardSet}
                                setWorkbook={(workbook) =>
                                    setWorkbook(workbook as CardSet)
                                }
                                deleteWorksheet={deleteWorksheet}
                                selectionType={selectionType}
                                setShowAddWorksheet={setShowAddWorksheet}
                                selectedViewType={selectedViewType}
                                worksheetsLoaded={worksheetsLoaded}
                                worksheets={worksheets}
                                setWorksheets={setWorksheets}
                                permissions={permissions}
                                setSelectionType={setSelectionType}
                                progressArray={progressArray}
                                setProgressArray={setProgressArray}
                                counts={counts}
                                setCounts={setCounts}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            backgroundColor: theme.palette.background.paper,
                            borderRadius: '10px',
                            padding: '10px 25px 25px 25px',
                            minHeight: '300px',
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '20px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginBottom: '10px',
                                width: '100%',
                            }}
                        >
                            <Typography variant="h5">Cards</Typography>
                            {activeIcons && (
                                <Box
                                    sx={{
                                        [theme.breakpoints.up('md')]: {
                                            left: '50%',
                                            right: 'initial',
                                        },
                                    }}
                                >
                                    <ManagerControls
                                        activeIcons={activeIcons}
                                        toggleIcon={toggleIcon}
                                        pageIdentifier={PageIdentifier.CARDS}
                                        filterCriteria={filterCriteria}
                                        setFilterCriteria={setFilterCriteria}
                                    />
                                </Box>
                            )}
                            {permissions.includes(UserPermissions.EDIT) ? (
                                <Box sx={{ display: 'flex' }}>
                                    <IconButton
                                        color="primary"
                                        onClick={setAddMenu}
                                        title="Add card"
                                        sx={{ width: '56px', height: '56px' }}
                                    >
                                        <AddIcon
                                            sx={{
                                                fontSize: '3rem',
                                                width: '40px',
                                                height: '40px',
                                            }}
                                        />
                                    </IconButton>

                                    <Box
                                        sx={{
                                            display: 'flex',
                                            cursor: 'pointer',
                                        }}
                                    >
                                        {setShowCreateSparkWizard && (
                                            <Tooltip
                                                title="Create a “spark” card to spark a discussion"
                                                placement="right"
                                            >
                                                <IconButton
                                                    color="primary"
                                                    onClick={() =>
                                                        setShowCreateSparkWizard(
                                                            true
                                                        )
                                                    }
                                                    sx={{
                                                        width: '51px',
                                                        height: '51px',
                                                    }}
                                                >
                                                    <img
                                                        src={SparkIcon}
                                                        alt="Bolt Icon"
                                                        style={{
                                                            width: '35x',
                                                            height: '35px',
                                                            borderRadius:
                                                                '16px',
                                                            marginTop: '-7px',
                                                        }}
                                                    />
                                                </IconButton>
                                            </Tooltip>
                                        )}
                                    </Box>
                                </Box>
                            ) : (
                                <Box />
                            )}
                            {showCreateSparkWizard && (
                                <StyledModal
                                    key="modal"
                                    open={showCreateSparkWizard}
                                    onClose={() =>
                                        setShowCreateSparkWizard(false)
                                    }
                                    sx={{ zIndex: 1401 }}
                                >
                                    <ModalContainer sx={{ maxWidth: '35rem' }}>
                                        <CreateSparkCard
                                            setShowCreateSparkWizard={
                                                setShowCreateSparkWizard
                                            }
                                            addCardToWorkbook={
                                                addCardToWorkbook
                                            }
                                        />
                                    </ModalContainer>
                                </StyledModal>
                            )}
                        </Box>
                        <Box sx={{}}>
                            {selectedViewType === ViewType.GRID ? (
                                <Grid2 container spacing={2}>
                                    {cardsLoaded && !cards.length && (
                                        <Grid2
                                            xs={6}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                            xl={2}
                                        >
                                            {permissions.includes(
                                                UserPermissions.EDIT
                                            ) && (
                                                <GhostCard
                                                    pageIdentifier={
                                                        PageIdentifier.CARDS
                                                    }
                                                    height="auto"
                                                    action={() =>
                                                        setShowCreateCardWizard(
                                                            true
                                                        )
                                                    }
                                                    hide={true}
                                                />
                                            )}
                                        </Grid2>
                                    )}

                                    {cards.map((card) => (
                                        <Grid2
                                            xs={6}
                                            sm={6}
                                            md={4}
                                            lg={3}
                                            xl={2}
                                        >
                                            <WorkbookCard card={card} />
                                        </Grid2>
                                    ))}
                                </Grid2>
                            ) : (
                                <CardContext.Provider
                                    value={{
                                        emptyAction: () =>
                                            setShowCreateCardWizard(true),
                                        createCardRelationship,
                                        handleClick,
                                        handleClose: () => {},
                                        handleDelete,
                                        handleRemove,
                                        handleEdit: () => {},
                                        handleCopy,
                                        setCardFrom,
                                        handleConvert: handleConvert,
                                        refreshCard,
                                        items:
                                            cards?.map((card) =>
                                                cardToCardComponentProps(card)
                                            ) || [],
                                    }}
                                >
                                    <CardListView
                                        multiSelectedCards={multiSelectedCards}
                                        cardsObject={cardsObject}
                                        pageIdentifier={
                                            PageIdentifier.WORKBOOK_INSIDE
                                        }
                                        rowClick={(id) => {
                                            const card = cardsObject[id];

                                            setMultiSelectedCards(
                                                (prevCards) => {
                                                    const newCard = {
                                                        id: id,
                                                        organisation:
                                                            card.organisation,
                                                        cardTypeId:
                                                            card.toCardType.id,
                                                        cardCategoryId:
                                                            card.toCardCategory
                                                                .id,
                                                    };
                                                    const index =
                                                        prevCards.findIndex(
                                                            (card) =>
                                                                card.id === id
                                                        );

                                                    if (index > -1) {
                                                        return [
                                                            ...prevCards.slice(
                                                                0,
                                                                index
                                                            ),
                                                            ...prevCards.slice(
                                                                index + 1
                                                            ),
                                                        ];
                                                    } else {
                                                        return [
                                                            ...prevCards,
                                                            newCard,
                                                        ];
                                                    }
                                                }
                                            );
                                        }}
                                    />
                                </CardContext.Provider>
                            )}
                            {addMenuAnchor && permissions && id && (
                                <AddCardMenu
                                    permissions={permissions}
                                    anchor={addMenuAnchor}
                                    setMenu={setAddMenu}
                                    secondaryAction={() =>
                                        setShowAddCardsWizard(true)
                                    }
                                    action={() => setShowCreateCardWizard(true)}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>

                {activeCardId && (
                    <CardSetModal
                        activeCardId={activeCardId as string}
                        resetCard={handleModalClose}
                        cardSet={workbook as CardSet}
                        handleModalClose={handleModalClose}
                        handleClose={handleModalClose}
                        data={cardToCardComponentProps(
                            cards.find(
                                (card) => card.id === activeCardId
                            ) as Card
                        )}
                        card={
                            cards.find(
                                (card) => card.id === activeCardId
                            ) as Card
                        }
                        handleClick={handleClick}
                        activeCardPage={activeCardPage}
                        refreshCard={refreshCard}
                        handleEdit={() => {}}
                        pageIdentifier={PageIdentifier.WORKBOOK_INSIDE}
                        handleDelete={handleDelete}
                        handleRemove={handleRemove}
                        handleCopy={handleCopy}
                        setCardFrom={setCardFrom}
                        createCardRelationship={createCardRelationship}
                        handleConvert={handleConvert}
                        cardsObject={cardsObject}
                    />
                )}
                <StyledModal
                    key="modal"
                    open={
                        showCreateCardWizard || showAddWorksheet || !!cardFrom
                    }
                    onClose={handleModalClose}
                    sx={modalBackdropStyles}
                >
                    <Box>
                        {(!!showCreateCardWizard ||
                            showAddWorksheet ||
                            !!cardFrom) && (
                            <ModalContainer
                                sx={{
                                    maxWidth: showAddWorksheet
                                        ? '55rem'
                                        : '65rem',
                                }}
                            >
                                {showAddWorksheet ? (
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            px: 3,
                                            pt: 3,
                                            legend: {
                                                color: theme.palette.text
                                                    .primary,
                                                fontWeight: 600,
                                            },
                                            height: '600px',
                                            position: 'relative',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                right: '20px',
                                                top: '20px',
                                            }}
                                        >
                                            <IconButton
                                                onClick={() =>
                                                    handleModalClose()
                                                }
                                            >
                                                <CloseIcon
                                                    sx={{ color: 'inherit' }}
                                                />
                                            </IconButton>
                                        </Box>

                                        <Typography
                                            variant="h5"
                                            sx={{
                                                marginBottom: 2,
                                                textAlign: 'center',
                                            }}
                                        >
                                            Add a worksheet
                                        </Typography>
                                        <Divider
                                            sx={{ marginBottom: '20px' }}
                                        />
                                        <WorksheetSelector
                                            name="worksheetSelector"
                                            type={FieldType.WORKSHEETSELECTOR}
                                            onChange={(event) =>
                                                saveWorkSheetType(
                                                    event.target.value
                                                )
                                            }
                                            loading={loading}
                                            selectionType={selectionType}
                                        />
                                    </Box>
                                ) : (
                                    <CreateCard
                                        workbook={workbook}
                                        cardSet={workbook?.id}
                                        handleClose={(card) => {
                                            if (card) {
                                                showSnackbar({
                                                    message: 'Card created',
                                                    severity: 'success',
                                                });
                                            }
                                            setCards((cards) => {
                                                const worksheetCards = [
                                                    ...cards,
                                                ];

                                                if (
                                                    !worksheetCards.find(
                                                        (worksheetCard) =>
                                                            worksheetCard.id ===
                                                            card?.id
                                                    )
                                                ) {
                                                    worksheetCards.unshift(
                                                        card as Card
                                                    );
                                                }
                                                return worksheetCards;
                                            });
                                            setShowCreateCardWizard(false);

                                            setCardFrom(null);
                                        }}
                                        pageIdentifier={
                                            PageIdentifier.WORKBOOK_INSIDE
                                        }
                                        processAndStep={processAndStep}
                                        setProcessAndStep={setProcessAndStep}
                                        close={() =>
                                            setShowCreateCardWizard(false)
                                        }
                                        convertSparkCardId={convertSparkCardId}
                                        cardFrom={cardFrom}
                                        lastCreatedFrom={lastCreatedFrom}
                                        setLastCreatedFrom={setLastCreatedFrom}
                                    />
                                )}
                            </ModalContainer>
                        )}
                    </Box>
                </StyledModal>
                <StyledModal
                    key="add-cards-workbook-modal"
                    open={showAddCardsWizard}
                    onClose={handleModalClose}
                    sx={modalBackdropStyles}
                >
                    <Box>
                        {showAddCardsWizard && workbook && (
                            <ModalContainer
                                sx={{
                                    maxWidth: '75rem',
                                    maxHeight: '41rem',
                                    height: '100%',
                                }}
                            >
                                <AddExistingCards
                                    workbook={workbook}
                                    setWorkbookCards={setCards}
                                    handleModalClose={handleModalClose}
                                />
                            </ModalContainer>
                        )}
                    </Box>
                </StyledModal>
                <StyledModal
                    key="delete-card-modal"
                    open={showDeleteCardWizard}
                    onClose={() => {
                        setShowDeleteCardWizard(false);
                        setCardToDelete(null);
                        setMultiSelectedCards([]);
                    }}
                    disableEnforceFocus
                    sx={{
                        zIndex: 1500,
                    }}
                >
                    <Box>
                        {showDeleteCardWizard &&
                            (multiSelectedCards || cardToDelete) &&
                            (cardToDelete ? (
                                <ModalContainer sx={{ maxWidth: '40rem' }}>
                                    <CardDelete
                                        id={cardToDelete.id}
                                        organisation={cardToDelete.organisation}
                                        toggleDeleteWizard={
                                            setShowDeleteCardWizard
                                        }
                                        setCardToDelete={setCardToDelete}
                                        confirmDelete={confirmDelete}
                                        cardCategoryId={
                                            cardToDelete.cardCategoryId
                                        }
                                        pageIdentifier={PageIdentifier.CARDS}
                                    />
                                </ModalContainer>
                            ) : (
                                <ModalContainer sx={{ maxWidth: '40rem' }}>
                                    <CardDelete
                                        toggleDeleteWizard={
                                            setShowDeleteCardWizard
                                        }
                                        confirmMultiDelete={confirmMultiDelete}
                                        pageIdentifier={PageIdentifier.CARDS}
                                        multiSelectedCards={multiSelectedCards}
                                        setMultiSelectedCards={
                                            setMultiSelectedCards
                                        }
                                        deleting={deleting}
                                    />
                                </ModalContainer>
                            ))}
                    </Box>
                </StyledModal>
                <StyledModal
                    key="remove-card-modal"
                    open={showRemoveCardWizard}
                    onClose={() => setShowRemoveCardWizard(false)}
                    disableEnforceFocus
                    sx={{
                        zIndex: 1500,
                    }}
                >
                    <Box>
                        {showRemoveCardWizard &&
                            (multiSelectedCards || cardToRemove) &&
                            (cardToRemove ? (
                                <ModalContainer sx={{ maxWidth: '40rem' }}>
                                    <RemoveCard
                                        id={cardToRemove.id}
                                        organisation={cardToRemove.organisation}
                                        toggleRemoveWizard={
                                            setShowRemoveCardWizard
                                        }
                                        confirmRemove={confirmRemove}
                                        cardComponentType={
                                            CardComponentType.WORKBOOK
                                        }
                                        inProgress={loading}
                                    />
                                </ModalContainer>
                            ) : (
                                <ModalContainer sx={{ maxWidth: '40rem' }}>
                                    <RemoveCard
                                        multiSelectedCards={multiSelectedCards}
                                        organisation={
                                            multiSelectedCards[0].organisation
                                        }
                                        toggleRemoveWizard={
                                            setShowRemoveCardWizard
                                        }
                                        confirmMultiRemove={confirmMultiRemove}
                                        cardComponentType={
                                            CardComponentType.WORKBOOK
                                        }
                                        inProgress={loading}
                                    />
                                </ModalContainer>
                            ))}
                    </Box>
                </StyledModal>
                <Snackbar
                    open={isOpen}
                    autoHideDuration={duration}
                    onClose={closeSnackbar}
                    sx={{ zIndex: 1600 }}
                >
                    <Alert
                        variant="filled"
                        severity={severity}
                        sx={{ width: '100%' }}
                        onClose={closeSnackbar}
                    >
                        {message}
                    </Alert>
                </Snackbar>
            </Box>
        </WorkbookContext.Provider>
    );
};

export default Workbook;
