import { Box, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { CustomDataPoint } from '../../charts/BubbleChart';
import { truncateText } from '../../../helpers/utils';
import { AttributeDefinition, Card } from '../../../API';
import BubbleKebabMenu from '../../workbooks/BubbleKebabMenu';
import { UserPermissions } from '../../../globals';

interface BubbleModalProps {
    node: CustomDataPoint;
    attributeDefinitions: (AttributeDefinition | null)[] | null | undefined;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    allScores: string[] | undefined;
    allAttributes: string[] | undefined;
    selectedCard: Card;
    workbook: boolean | undefined;
    worksheetId?: string | null;
    permissions: UserPermissions[] | undefined;
    handleCopy?: ((card: Card, worksheetId?: string) => void) | undefined;
    handleRemoveFromWorkbook?: ((card: Card) => void) | undefined;
    setCardFrom?: ((card: Card[] | null) => void) | undefined;
}
const BubbleModal = ({
    node,
    attributeDefinitions,
    setIsModalOpen,
    allScores,
    allAttributes,
    selectedCard,
    workbook,
    worksheetId,
    permissions,
    handleCopy,
    handleRemoveFromWorkbook,
    setCardFrom,
}: BubbleModalProps) => {
    return (
        <Box
            sx={{
                py: '8px',
                px: '16px',
                color: '#000',
                background: 'white',
                boxShadow: 1,
                borderRadius: '8px',
                width: '200px',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Typography
                    variant="h6"
                    sx={{ fontSize: '14px', textWrap: 'wrap', lineHeight: 1.2 }}
                >
                    {truncateText(node.label, 50)}
                </Typography>
                {!workbook ? (
                    <IconButton
                        aria-label="close"
                        onClick={() => setIsModalOpen(false)}
                        sx={{
                            mr: '-20px',
                            mb: '15px',
                        }}
                        size="small"
                    >
                        <CloseIcon />
                    </IconButton>
                ) : (
                    <BubbleKebabMenu
                        node={node}
                        card={selectedCard}
                        fullWidth={false}
                        setIsModalOpen={setIsModalOpen}
                        worksheetId={worksheetId}
                        permissions={permissions}
                        handleCopy={handleCopy}
                        handleRemoveFromWorkbook={handleRemoveFromWorkbook}
                        setCardFrom={setCardFrom}
                    />
                )}
            </Box>
        </Box>
    );
};

export default BubbleModal;
