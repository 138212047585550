import { IconButton, Tooltip, styled } from '@mui/material';
import MuiCardActions from '@mui/material/CardActions';
import { CardPage } from './cardTypes';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { Link } from 'react-router-dom';

export interface CardActionItemProps {
    action: ((cardPage?: CardPage) => void) | string;
    value?: CardPage;
    Icon: React.FunctionComponent;
    label: string;
    active?: boolean;
    visible?: boolean;
}

export interface CardActionsProps {
    setMenu: (e: React.MouseEvent<HTMLElement>) => void;
    expanded?: boolean;
    items: CardActionItemProps[];
    padding?: number;
    textColor: string;
    deletedCard?: boolean | undefined;
    multiDelete?: boolean | undefined;
    worksheetCard?: boolean;
    fadeOut?: boolean;
}

interface StyledCardActionsProps {
    padding?: number;
    textColor: string;
    items: CardActionItemProps[];
    flex?: number;
    worksheetCard?: boolean;
}

const StyledDefaultMuiCardActions = styled(
    MuiCardActions
)<StyledCardActionsProps>(({ theme, padding = 5, textColor, items, flex }) => {
    return {
        width: items.length > 3 ? '100%' : '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
        paddingLeft: '27px',
        paddingRight: '27px',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '0.85rem',
        paddingBottom: '0.85rem',
        flex: flex ?? 'initial',
        'button, a': {
            padding: 0,
        },
        'svg, a': {
            width: '30px',
            height: '30px',
            color: textColor,
        },
        'a, button': {
            opacity: 0.5,
            ['&.active, &:hover']: {
                opacity: 1,
            },
        },
    };
});

const StyledExpandedMuiCardActions = styled(
    StyledDefaultMuiCardActions
)<StyledCardActionsProps>(
    ({ theme, padding = 1, textColor, worksheetCard }) => {
        return {
            'button, a': {
                padding: 0,
            },
            'svg, a': {
                width: worksheetCard ? '25px' : '30px',
                height: worksheetCard ? '25px' : '30px',
                color: textColor,
            },
            height: worksheetCard ? '35px' : '',
        };
    }
);

const CardActions = ({
    setMenu,
    expanded,
    items,
    padding,
    textColor,
    deletedCard,
    multiDelete,
    worksheetCard,
    fadeOut,
}: CardActionsProps) => {
    const StyledComponent = expanded
        ? StyledExpandedMuiCardActions
        : StyledDefaultMuiCardActions;

    return (
        <StyledComponent
            padding={padding}
            textColor={textColor}
            items={items}
            flex={1}
            worksheetCard={worksheetCard}
            sx={{
                ...(fadeOut && {
                    svg: {
                        opacity: '0.25',
                    },
                }),
            }}
        >
            {items.map((item) => {
                const {
                    action,
                    Icon,
                    label,
                    value,
                    active,
                    visible = true,
                } = item;

                if (visible) {
                    if (action instanceof Function) {
                        return (
                            <Tooltip title={label} placement="top" key={label}>
                                <IconButton
                                    aria-label={label}
                                    onClick={() => action(value)}
                                    sx={{
                                        p: 0,
                                        ml: 0,
                                        width: '30px',
                                        height: '30px',
                                        svg: {
                                            height: '30px',
                                            width: '30px',
                                        },
                                    }}
                                    className={active ? 'active' : ''}
                                >
                                    <Icon />
                                </IconButton>
                            </Tooltip>
                        );
                    } else {
                        return (
                            <div key={label}>
                                <Tooltip title={label} placement="top">
                                    <IconButton
                                        sx={{
                                            p: 0,
                                            ml: 0,
                                            width: '30px',
                                            height: '30px',
                                            svg: {
                                                height: '30px',
                                                width: '30px',
                                            },
                                        }}
                                    >
                                        <Link
                                            key={label}
                                            to={action}
                                            className={active ? 'active' : ''}
                                        >
                                            <Icon />
                                        </Link>
                                    </IconButton>
                                </Tooltip>
                            </div>
                        );
                    }
                }
            })}
            {!deletedCard && (
                <Tooltip title="Actions" placement="top">
                    <IconButton
                        aria-label=""
                        id="menu-button"
                        onClick={setMenu}
                        sx={{
                            ml: 0,
                            width: '30px',
                            height: '30px',
                            svg: {
                                height: '30px',
                                width: '30px',
                                color: multiDelete ? '#fff' : '',
                            },
                            zIndex: 1099,
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                </Tooltip>
            )}
        </StyledComponent>
    );
};

export default CardActions;
