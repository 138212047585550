import { Chart, ChartArea, Scale } from 'chart.js';
import { CustomDataPoint } from './BubbleChart';
import { Tick } from 'chart.js';
import { Context } from 'chartjs-plugin-datalabels';
import { Chart as ChartJS } from 'chart.js';
import { truncateText } from '../../helpers/utils';
import { Card, ScoreType } from '../../API';
import { Theme } from '@mui/material';
import zoomPlugin from 'chartjs-plugin-zoom';
import { CardComponentData } from '../cards/cardTypes';
import { CardScoreData } from '../../helpers/worksheets';

export const chartAreaBackgroundPlugin = {
    id: 'chartAreaBackground',
    beforeDraw: (
        chart: any,
        args: any,
        options: { backgroundColor: string }
    ) => {
        const isBubbleChart = chart?.config?.type === 'bubble';
        if (!isBubbleChart) {
            return;
        }
        const ctx = chart.ctx;
        const chartArea: ChartArea = chart.chartArea;

        ctx.save();
        ctx.fillStyle = options.backgroundColor;
        ctx.fillRect(
            chartArea.left,
            chartArea.top,
            chartArea.right - chartArea.left,
            chartArea.bottom - chartArea.top
        );
        ctx.restore();
    },
};
export const calculateMaxValue = (value: number, scoreType: ScoreType) => {
    const additionalSpaceFactor = 1.1;
    if (scoreType === ScoreType.Percentage) {
        return 100;
    }
    if (scoreType === ScoreType.Currency || scoreType === ScoreType.Integer) {
        return Math.round(value * additionalSpaceFactor);
    }
    return 10;
};

export const determineDataLabelAlignment = (
    context: Context,
    chart: ChartJS,
    currentDataPoint: CustomDataPoint
) => {
    const chartArea = chart.chartArea;
    const currentBubbleRadius = currentDataPoint?.r;
    const xScale = chart.scales.x;
    const yScale = chart.scales.y;
    const currentBubbleX = xScale.getPixelForValue(currentDataPoint?.x);
    const currentBubbleY = yScale.getPixelForValue(currentDataPoint?.y);

    const isCloseToRightEdge =
        chartArea.right - currentBubbleX <= currentBubbleRadius + 15;
    const isCloseToLeftEdge =
        currentBubbleX - chartArea.left <= currentBubbleRadius + 15;
    const isCloseToTopEdge =
        currentBubbleY - chartArea.top <= currentBubbleRadius + 15;
    const isCloseToBottomEdge =
        chartArea.bottom - currentBubbleY <= currentBubbleRadius + 15;

    let isNearOtherBubbleToLeft = false;
    let isNearOtherBubbleToRight = false;
    let isNearOtherBubbleToTop = false;
    let isNearOtherBubbleToBottom = false;

    context.chart.data.datasets.forEach((dataset) => {
        dataset.data.forEach((otherDataPoint, otherIndex) => {
            if (otherIndex !== context.dataIndex) {
                const otherBubble = otherDataPoint as CustomDataPoint;
                const otherBubbleX = xScale.getPixelForValue(otherBubble.x);
                const otherBubbleY = yScale.getPixelForValue(otherBubble.y);
                const distanceX = Math.abs(otherBubbleX - currentBubbleX);
                const distanceY = Math.abs(otherBubbleY - currentBubbleY);
                const combinedRadius = currentBubbleRadius + otherBubble.r + 15;

                if (distanceX < combinedRadius && distanceY < combinedRadius) {
                    if (otherBubbleX < currentBubbleX)
                        isNearOtherBubbleToLeft = true;
                    if (otherBubbleX > currentBubbleX)
                        isNearOtherBubbleToRight = true;
                    if (otherBubbleY < currentBubbleY)
                        isNearOtherBubbleToTop = true;
                    if (otherBubbleY > currentBubbleY)
                        isNearOtherBubbleToBottom = true;
                }
            }
        });
    });

    if (isCloseToBottomEdge) return 'top';
    if (isCloseToRightEdge && !isNearOtherBubbleToLeft) return 'left';
    if (isCloseToRightEdge && isNearOtherBubbleToLeft) return 'top';
    if (isCloseToLeftEdge && !isNearOtherBubbleToRight) return 'right';
    if (isCloseToLeftEdge && isNearOtherBubbleToRight) return 'top';
    if (isNearOtherBubbleToRight) return 'left';
    if (isNearOtherBubbleToLeft) return 'right';
    if (isCloseToTopEdge && !isNearOtherBubbleToBottom) return 'bottom';
    if (isCloseToTopEdge && isNearOtherBubbleToBottom) return 'right';
    return 'right';
};

interface axisOptions {
    lowTickLabel: string;
    highTickLabel: string;
    title: string;
    scoreType: ScoreType;
}

interface axisOptions {
    scoreType: ScoreType;
    lowTickLabel: string;
    highTickLabel: string;
    title: string;
}
interface CustomPointOptions extends Highcharts.PointOptionsObject {
    card?: CardScoreData;
}

export const getBubbleChartOptions = (
    xAxisOptions: axisOptions,
    yAxisOptions: axisOptions,
    displayGrid: boolean,
    thumbnail: boolean,
    dataset: any[],
    carousel: boolean,
    theme: Theme,
    workbook?: boolean,
    pptView?: boolean,
    onBubbleClick?: (
        data: CustomDataPoint,
        position: {
            top: number;
            left: number;
        }
    ) => void,
    onDoubleClick?: (card: Card | CardComponentData, side: string) => void,
    activeCardId?: string | null,
    activeCardId2?: string | null,
    handleMultiSelect?: (card: Card) => void,
    multiSelectedCards?: Card[] | undefined
): Highcharts.Options => {
    const maxXValue = Math.max(...dataset.map((data) => data.x));
    const maxYValue = Math.max(...dataset.map((data) => data.y));
    const maxX = calculateMaxValue(maxXValue, xAxisOptions.scoreType) || 100000;
    const maxY = calculateMaxValue(maxYValue, yAxisOptions.scoreType) || 100000;
    return {
        chart: {
            type: 'bubble',
            plotBorderWidth: 1,
            height: '75%',
            zooming: {
                type: 'xy',
                pinchType: 'xy',
                mouseWheel: {
                    enabled: true,
                    sensitivity: 1.2,
                },
            },
            panning: {
                enabled: true,
                type: 'xy',
            },
            panKey: 'shift',
        },

        title: {
            text: 'Bubble Chart',
            style: {
                color: workbook ? theme.palette.text.secondary : 'grey',
                fontSize:
                    !thumbnail && pptView
                        ? '20px'
                        : thumbnail && !pptView
                        ? '10px'
                        : '16px',
                fontWeight: 'bold',
            },
        },
        credits: {
            enabled: false,
        },
        legend: {
            enabled: false,
        },
        xAxis: {
            lineWidth: 0,
            tickLength: 0,
            tickWidth: 0,
            gridLineColor: '#cccccc',
            gridLineDashStyle: 'Dash',
            min: 0,
            max: maxX,
            gridLineWidth: displayGrid ? 1 : 0,
            title: {
                text: xAxisOptions.title,
                style: {
                    color: workbook ? theme.palette.text.secondary : 'grey',
                    fontSize:
                        !thumbnail && pptView
                            ? '20px'
                            : thumbnail && !pptView
                            ? '10px'
                            : '18px',
                    fontWeight: 'bold',
                },
            },
            labels: {
                formatter: function (
                    this: Highcharts.AxisLabelsFormatterContextObject
                ) {
                    const value = this.value as number;
                    if (xAxisOptions.scoreType === ScoreType.Percentage)
                        return value.toFixed(0) + '%';
                    if (xAxisOptions.scoreType === ScoreType.Currency)
                        return value.toFixed(0);
                    if (xAxisOptions.scoreType === ScoreType.Integer)
                        return value.toFixed(0);
                    if (value === 0) return xAxisOptions.lowTickLabel;
                    if (value === maxX) return xAxisOptions.highTickLabel;
                    return '';
                },
                style: {
                    color: workbook ? theme.palette.text.secondary : 'grey',
                    fontSize:
                        !thumbnail && pptView
                            ? '20px'
                            : thumbnail && !pptView
                            ? '6px'
                            : '12px',
                    fontWeight: pptView ? 'bold' : 'normal',
                    whiteSpace: 'nowrap',
                    textOverflow: 'none',
                },
            },
        },

        yAxis: {
            min: 0,
            max: maxY,
            gridLineWidth: displayGrid ? 1 : 0,
            lineWidth: 0,
            tickLength: 0,
            tickWidth: 0,
            gridLineColor: '#cccccc',
            gridLineDashStyle: 'Dash',
            title: {
                text: yAxisOptions.title,
                style: {
                    color: workbook ? theme.palette.text.secondary : 'grey',
                    fontSize:
                        !thumbnail && pptView
                            ? '20px'
                            : thumbnail && !pptView
                            ? '10px'
                            : '18px',
                    fontWeight: 'bold',
                },
            },
            labels: {
                formatter: function (
                    this: Highcharts.AxisLabelsFormatterContextObject
                ) {
                    const value = this.value as number;
                    if (yAxisOptions.scoreType === ScoreType.Percentage) {
                        return value.toFixed(0) + '%';
                    }
                    if (yAxisOptions.scoreType === ScoreType.Currency) {
                        return value.toFixed(0);
                    }
                    if (yAxisOptions.scoreType === ScoreType.Integer) {
                        return value.toFixed(0);
                    }
                    if (value === 0) return yAxisOptions.lowTickLabel;
                    if (value === maxY) return yAxisOptions.highTickLabel;

                    return '';
                },
                y: 10,
                style: {
                    color: workbook ? theme.palette.text.secondary : 'grey',
                    fontSize:
                        !thumbnail && pptView
                            ? '20px'
                            : thumbnail && !pptView
                            ? '6px'
                            : '12px',
                    fontWeight: pptView ? 'bold' : 'normal',
                    whiteSpace: 'nowrap',
                    textOverflow: 'none',
                },
            },
        },
        tooltip: {
            useHTML: true,
            backgroundColor: 'transparent',
            outside: true,
            hideDelay: 100,
            stickOnContact: false,
            formatter() {
                const dataPoint = this as any;

                if (!dataPoint.card || !dataPoint.card.originalCard) {
                    return '<div id="custom-bubble-tooltip">No Data</div>';
                }

                return `<div id="custom-bubble-tooltip" 
                            data-card='${JSON.stringify(
                                dataPoint.card.originalCard
                            )}'>
                        </div>`;
            },
        },

        plotOptions: {
            series: {
                dataLabels: {
                    enabled: true,
                    inside: true,
                    align: 'center',
                    verticalAlign: 'middle',
                    allowOverlap: true,
                    formatter: function (this: Highcharts.PointOptionsObject) {
                        return `${truncateText(this.name, 10)}`;
                    },
                    style: {
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: 'rgba(0,0,0,0.87)',
                        textOutline: 'none',
                    },
                },
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (this: Highcharts.Point, event) {
                            const point = this as Highcharts.Point & {
                                lastTapTime?: number;
                            };

                            const now = new Date().getTime();

                            if (
                                point.lastTapTime &&
                                now - point.lastTapTime < 300
                            ) {
                                const options = this
                                    .options as CustomPointOptions;

                                if (
                                    onDoubleClick &&
                                    options.card?.originalCard
                                ) {
                                    onDoubleClick(
                                        options.card.originalCard,
                                        'right'
                                    );
                                }

                                point.lastTapTime = undefined;
                            } else {
                                point.lastTapTime = now;
                                const options = this
                                    .options as CustomPointOptions;

                                setTimeout(() => {
                                    if (point.lastTapTime === now) {
                                        if (
                                            handleMultiSelect &&
                                            options.card?.originalCard
                                        ) {
                                            handleMultiSelect(
                                                options.card.originalCard
                                            );
                                        }
                                    }
                                }, 300);
                            }
                        },
                    },
                },
            },
        },
        series: dataset.flat().map((series) => {
            const cardId = series.data[0]?.card?.originalCard?.id;
            const isActive =
                cardId === activeCardId || cardId === activeCardId2;
            const isMultiSelected =
                multiSelectedCards?.some((card) => card.id === cardId) ?? false;

            const getFullColor = (color: string | undefined) => {
                if (!color) return undefined;
                return color.replace(/rgba?\(([^)]+),\s*[\d.]+\)/, 'rgb($1)');
            };

            const jitterFactor = 0.15;
            const pointOccurrences = new Map<string, number>();
            const jitterIndices = new Map<string, number>();

            dataset.flat().forEach((series) => {
                series.data.forEach((point: any) => {
                    const key = `${point.x},${point.y}`;
                    pointOccurrences.set(
                        key,
                        (pointOccurrences.get(key) || 0) + 1
                    );
                });
            });

            return {
                ...series,
                data: series.data.map((point: any, index: number) => {
                    const key = `${point.x},${point.y}`;
                    const count = pointOccurrences.get(key) ?? 0;
                    jitterIndices.set(key, index + 1);

                    if (count > 1) {
                        let hash = 0;
                        if (point?.card?.originalCard?.id) {
                            for (
                                let i = 0;
                                i < point.card.originalCard.id.length;
                                i++
                            ) {
                                hash =
                                    (hash << 5) -
                                    hash +
                                    point.card.originalCard.id.charCodeAt(i);
                                hash |= 0;
                            }
                        }
                        const uniqueSeed =
                            point.x * 61 + point.y * 27 + hash + index;
                        const baseAngle = (uniqueSeed % 360) * (Math.PI / 180);
                        const angle = baseAngle + (2 * Math.PI * index) / count;
                        const jitterX = Math.cos(angle) * jitterFactor;
                        const jitterY = Math.sin(angle) * jitterFactor;
                        return {
                            ...point,
                            x: point.x + jitterX,
                            y: point.y + jitterY,
                        };
                    }
                    return point;
                }),
                marker: {
                    lineWidth: isActive ? 4 : isMultiSelected ? 2 : 0,
                    lineColor: isActive
                        ? '#3d74de'
                        : isMultiSelected
                        ? getFullColor(series.data[0].color)
                        : undefined,
                    fillColor: isMultiSelected
                        ? getFullColor(series.data[0].color)
                        : series.data[0].color,
                    states: {
                        hover: {
                            enabled: true,
                            radiusPlus: 2,
                            lineWidthPlus: 3,
                            lineColor: '#3d74de',
                        },
                    },
                },
                stickyTracking: false,
                opacity: multiSelectedCards?.length
                    ? isMultiSelected
                        ? 1
                        : 0.3
                    : 1,
                states: {
                    hover: {
                        enabled: true,
                        halo: {
                            size: 0,
                        },
                        marker: {
                            radiusPlus: 3,
                            lineWidthPlus: 3,
                            lineColor: '#3d74de',
                        },
                    },
                    inactive: {
                        opacity: 1,
                    },
                },
            };
        }),
    };
};
