import { useCallback, useContext, useEffect, useState } from 'react';
import {
    Box,
    Button,
    Divider,
    Grid,
    InputLabel,
    Typography,
    useTheme,
} from '@mui/material';
import { generateClient } from 'aws-amplify/api';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent } from './CreateCard/AttributesColumn';
import {
    AttributeType,
    Card,
    CardSet,
    CreatePlaybookMutation,
    CreatePlaybookPageMutation,
    Playbook,
    PlaybookPageType,
    WorkbookFilter,
} from '../../API';
import {
    createPlaybook,
    createPlaybookPage,
    updatePlaybook,
} from '../../graphql/mutations';
import InputFromAttribute from './input-fields/InputFromAttribute';
import FieldProps, { FieldType } from './input-fields/inputFieldTypes';
import { DateTime } from 'luxon';
import { Workbook } from '../../pages/workbooks';
import PrimaryColumn from './AddExistingCard/PrimaryColumn';
import useIconStates from '../../hooks/useIconStates';
import { ActiveIcons } from '../cards/views/WorksheetLayout';
import {
    CardComponentData,
    CardSetComponentData,
    PageIdentifier,
    SortActions,
    sortFieldToResponseMap,
} from '../cards/cardTypes';
import { ViewType } from '../layouts/PageView';
import useFilterCards from '../../hooks/useFilterCards';
import {
    GraphQLResponse,
    queryData,
    queryDataWithFilter,
} from '../../actions/QueryData';
import { getWorkbooksBasic } from '../../graphql/custom-queries';
import { AppContext } from '../contexts';
import { getUserOrganisation } from '../../helpers/auth';
import { listCardSets } from '../../graphql/queries';
import useSortCards from '../../hooks/useSort';
import {
    getInitialSortDirection,
    getInitialSortField,
} from './AddExistingCard';
import { RelationshipFormType } from '../cards/CardRelationships';
import { getCardSetPermissions } from '../../helpers/permissions';
import { UserPermissions } from '../../globals';

interface CreatePlaybookProps {
    playbook?: Playbook | Workbook;
    type?: 'Playbook' | 'Workbook' | 'Card set' | 'Relationship';
    handleClose?: (playbook?: Playbook) => void;
    createWorkbook?: (workbook: Workbook) => void;
    handleNewWorkbookRelationships?: (
        clickedCardSets: CardSetComponentData[],
        allWorkbooks: CardSet[],
        playbookObject: any
    ) => Promise<void>;
    containingWorkbooks?: CardSet[];
}

export interface NewPlayBook {
    [key: string]: string | undefined | null;
}

const CreatePlaybook = ({
    playbook,
    type,
    handleClose,
    createWorkbook,
    handleNewWorkbookRelationships,
    containingWorkbooks,
}: CreatePlaybookProps) => {
    const { applySort, user } = useContext(AppContext);
    const [playbookObject, setPlayBookObject] = useState<NewPlayBook>({
        name: '',
        description: '',
        organisation: '',
    });
    const [selectBy, setSelectBy] = useState<'card' | 'card_set' | 'workbook'>(
        'workbook'
    );
    const [activeIcons, setActiveIcons] = useIconStates(
        'PlayabookWorkbookControls'
    );
    const [selectedViewType, setSelectedViewType] = useState<ViewType>(
        ViewType.GRID
    );
    const [workbookFilterCriteria, setWorkbookFilterCriteria] = useFilterCards(
        `filter_${PageIdentifier.CONNECT_WORKBOOK_TO_PLAYBOOK_FORM}`
    );
    const [workbooks, setWorkbooks] = useState<CardSet[]>([]);
    const [clickedWorkbooks, setClickedWorkbooks] = useState<
        CardSetComponentData[]
    >([]);
    const [sortAction, sortDirection] = useSortCards(
        PageIdentifier.CONNECT_WORKBOOK_TO_PLAYBOOK_FORM
    );
    const [workbookSortField, setWorkbooksortField] = useState<SortActions>(
        getInitialSortField(
            `selectedSortAction_${PageIdentifier.CONNECT_WORKBOOK_TO_PLAYBOOK_FORM}`,
            SortActions.LAST_MODIFIED_RECENT_FIRST
        )
    );
    const [workbookSortDirection, setWorkbooksortDirection] = useState<
        'ASC' | 'DESC'
    >(() =>
        getInitialSortDirection(
            `selectedSortDirection_${PageIdentifier.CONNECT_WORKBOOK_TO_PLAYBOOK_FORM}`,
            'DESC'
        )
    );
    const [workbooksToken, setWorkbooksToken] = useState<string | null>('');
    const [searchTerm, setSearchTerm] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const theme = useTheme();

    const client = generateClient();

    const createPlaybookConfiguration: FieldProps[] = [
        {
            type: AttributeType.Text,
            label: `${type} name`,
            name: 'name',
            required: true,
        },

        {
            type: AttributeType.MultilineText,
            label: 'Description',
            name: 'description',
        },
    ];

    type === 'Workbook' &&
        playbookObject?.worksheet === 'select' &&
        createPlaybookConfiguration.splice(2, 0, {
            type: FieldType.WORKSHEETSELECTOR,
            label: '',
            name: 'selectedWorksheet',
            required: true,
        });

    useEffect(() => {
        if (playbook) {
            setPlayBookObject(playbook as NewPlayBook);
        }
    }, [playbook]);

    useEffect(() => {
        setWorkbooksortField(sortAction as SortActions);
        setWorkbooksortDirection(sortDirection as 'ASC' | 'DESC');
    }, [applySort]);

    const getBySearchTerm = async (searchTerm: string) => {
        const userGroup = user ? await getUserOrganisation(user) : '';
        if (!searchTerm || searchTerm.length < 3) return;

        let variables: {
            organisation: string;
            filter?: WorkbookFilter;
        } = {
            organisation: userGroup,

            ...(searchTerm && {
                filter: {
                    nameContains: searchTerm.toUpperCase(),
                },
            }),
        };

        const response = await queryDataWithFilter({
            query: getWorkbooksBasic,
            organisation: userGroup,
            filter: {
                nameContains: searchTerm.toUpperCase(),
            },
            responseType: 'getWorkbooks',
        });

        setWorkbooksToken(response.nextToken);
        setWorkbooks(response?.data as CardSet[]);
    };
    const throttle = (func: (searchTerm: string) => void, limit: number) => {
        let inThrottle = false;

        return (...args: [searchTerm: string]) => {
            if (!inThrottle) {
                func(...args);
                inThrottle = true;
                setTimeout(() => (inThrottle = false), limit);
            }
        };
    };
    const throttledAPICall: (searchTerm: string) => void = useCallback(
        throttle((searchTerm: string) => {
            getBySearchTerm(searchTerm);
        }, 150),
        []
    );
    useEffect(() => {
        if (searchTerm !== null) {
            if (searchTerm) {
                throttledAPICall(searchTerm);
            } else {
                setWorkbooks([]);
                fetchWorkbooks();
            }
        }
    }, [searchTerm]);
    useEffect(() => {
        fetchWorkbooks(true);
    }, [
        activeIcons,
        workbookSortField,
        workbookSortDirection,
        workbookFilterCriteria,
    ]);
    const updatePlaybookValues = (attr: string, event: ChangeEvent) => {
        if (!!!event || event instanceof DateTime) return;

        setPlayBookObject({
            ...playbookObject,
            [attr]: event?.target?.value as string,
        });
    };
    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (type === 'Workbook' && createWorkbook) {
            createWorkbook(playbookObject as Workbook);
            return;
        }
        const query = playbook ? updatePlaybook : createPlaybook;

        const data = playbookObject;

        const res = (await client.graphql({
            query,
            variables: {
                input: {
                    description: data.description,
                    name: data.name ?? '',
                    ...(data.id && {
                        id: data.id,
                    }),
                    organisation: data?.organisation || 'x',
                    workbooks: clickedWorkbooks.map((item) => item.id),
                },
            },
        })) as { data: CreatePlaybookMutation };
        const newPlaybook = res.data.createPlaybook;
        if (newPlaybook) {
            const titleconfig = JSON.stringify({
                content: 'middle',
                logo: 'top',
                color: { text: '#ffffff', background: '#414B5A' },
            });
            const titlePageVariables = {
                input: {
                    playbookPageType: PlaybookPageType.Title,
                    organisation: newPlaybook.organisation,
                    title: newPlaybook.name,
                    playbookId: newPlaybook.id,
                    options: JSON.stringify({ titleconfig: titleconfig }),
                    ordinal: 1,
                },
            };
            const response = (await client.graphql({
                query: createPlaybookPage,
                variables: titlePageVariables,
            })) as { data: CreatePlaybookPageMutation };
        }

        !!handleClose && handleClose(res.data.createPlaybook as Playbook);
    };
    const toggleIcon = (
        iconName: keyof ActiveIcons,
        selectedSortAction: string
    ) => {
        if (setActiveIcons) {
            setActiveIcons((prevState) => ({
                ...prevState,
                [iconName]:
                    iconName === 'Sort'
                        ? selectedSortAction ===
                          SortActions.LAST_MODIFIED_RECENT_FIRST
                            ? false
                            : true
                        : !prevState[iconName],
            }));
        }
    };

    const handleSelect = (item: CardComponentData | CardSetComponentData) => {
        if (selectBy === 'workbook') {
            setClickedWorkbooks((prevClickedWorkbooks) => {
                const isItemExists = prevClickedWorkbooks.some(
                    (card) => card.id === item.id
                );
                if (isItemExists) {
                    return prevClickedWorkbooks.filter(
                        (card) => card.id !== item.id
                    );
                } else {
                    return [...prevClickedWorkbooks, item];
                }
            });
        }
    };
    const fetchWorkbooks = async (initialLoad?: boolean) => {
        if (!workbookSortDirection || !workbookSortField) return;
        setLoading(true);
        try {
            const { data, nextToken } = await queryData(
                initialLoad ?? false,
                workbookSortDirection,
                workbooksToken,
                activeIcons,
                getWorkbooksBasic,
                sortFieldToResponseMap[workbookSortField],
                'getWorkbooks',
                workbookFilterCriteria,
                setActiveIcons,
                '',
                user
            );
            if (initialLoad) {
                setWorkbooks(data as CardSet[]);
            } else {
                setWorkbooks((workbooks) => {
                    return [...workbooks, ...(data as CardSet[])];
                });
            }
            setWorkbooksToken(nextToken);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }
    };
    return (
        <Box sx={{ position: 'relative' }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    px: 3,
                    py: 1,
                }}
            >
                <Typography variant="h5">
                    {type === 'Relationship'
                        ? 'Select one or more workbooks to relate to this playbook'
                        : `Create ${type?.toLowerCase()}`}
                </Typography>

                {handleClose && (
                    <Box>
                        <IconButton onClick={() => handleClose()}>
                            <CloseIcon sx={{ color: 'inherit' }} />
                        </IconButton>
                    </Box>
                )}
            </Box>
            {type === 'Workbook' && <Divider sx={{ mb: 2, mx: 2 }} />}
            <form onSubmit={onSubmit}>
                <Grid container>
                    {type !== 'Relationship' && (
                        <Grid
                            item
                            xs={12}
                            md={type === 'Playbook' ? 3 : 12}
                            sx={{
                                height: type === 'Playbook' ? '35rem' : '22rem',
                            }}
                        >
                            {type === 'Playbook' && (
                                <Box
                                    sx={{
                                        background:
                                            theme.palette.mode === 'dark'
                                                ? theme.palette.background
                                                      .default
                                                : '#efefef',
                                        py: 1,
                                        px: 3,
                                        borderTop: 'solid 1px #ccc',
                                        borderBottom: 'solid 1px #ccc',
                                    }}
                                >
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: 16,
                                        }}
                                    >
                                        Playbook details
                                    </Typography>
                                </Box>
                            )}
                            <Box
                                sx={{
                                    p: 2,
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Box>
                                    {createPlaybookConfiguration.map(
                                        (attributes, i: number) => (
                                            <InputFromAttribute
                                                key={attributes.label}
                                                {...attributes}
                                                {...(attributes.type !==
                                                    AttributeType.MultiSelect && {
                                                    onChange: (
                                                        event: ChangeEvent
                                                    ) =>
                                                        updatePlaybookValues(
                                                            attributes.name,
                                                            event
                                                        ),
                                                })}
                                                selectValue={
                                                    playbookObject[
                                                        attributes.name
                                                    ] as string
                                                }
                                            />
                                        )
                                    )}
                                </Box>
                                {type === 'Workbook' && (
                                    <Button variant="contained" type="submit">
                                        Create workbook
                                    </Button>
                                )}
                            </Box>
                        </Grid>
                    )}
                    {(type === 'Playbook' || type === 'Relationship') && (
                        <Grid
                            item
                            xs={12}
                            md={type === 'Relationship' ? 12 : 9}
                            sx={{
                                borderLeft:
                                    type === 'Playbook'
                                        ? 'solid 1px #ccc'
                                        : null,
                            }}
                        >
                            <Box
                                sx={{
                                    height: '33rem',
                                    overflow: 'hidden',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <PrimaryColumn
                                    selectBy={selectBy}
                                    setSelectBy={setSelectBy}
                                    activeIcons={activeIcons}
                                    toggleIcon={toggleIcon}
                                    selectedViewType={selectedViewType}
                                    setSelectedViewType={setSelectedViewType}
                                    cardSetFilterCriteria={
                                        workbookFilterCriteria
                                    }
                                    setCardSetFilterCriteria={
                                        setWorkbookFilterCriteria
                                    }
                                    cardSets={
                                        containingWorkbooks
                                            ? workbooks.filter(
                                                  (workbook) =>
                                                      !containingWorkbooks.some(
                                                          (
                                                              containingCardSet: CardSet
                                                          ) =>
                                                              containingCardSet.id ===
                                                              workbook.id
                                                      ) &&
                                                      getCardSetPermissions(
                                                          user?.userSub || '',
                                                          workbook
                                                      ).includes(
                                                          UserPermissions.EDIT
                                                      )
                                              )
                                            : workbooks
                                    }
                                    handleSelect={handleSelect}
                                    clickedItems={clickedWorkbooks}
                                    onLoadMore={fetchWorkbooks}
                                    token={workbooksToken}
                                    loading={loading}
                                    searchTerm={searchTerm}
                                    setSearchTerm={setSearchTerm}
                                    title={'Select one or more workbooks'}
                                    relationshipForm={
                                        type === 'Relationship'
                                            ? RelationshipFormType.WORKBOOK
                                            : undefined
                                    }
                                />
                            </Box>
                            {type === 'Relationship' ? (
                                <Button
                                    variant="contained"
                                    type="button"
                                    sx={{
                                        mt: 1,
                                        ml: '84%',
                                        alignSelf: 'flex-end',
                                    }}
                                    onClick={() =>
                                        handleNewWorkbookRelationships &&
                                        handleNewWorkbookRelationships(
                                            clickedWorkbooks,
                                            workbooks,
                                            playbookObject
                                        )
                                    }
                                >
                                    Relate
                                </Button>
                            ) : (
                                <Button
                                    variant="contained"
                                    type="submit"
                                    sx={{
                                        mt: 1,
                                        ml: '77%',
                                        alignSelf: 'flex-end',
                                    }}
                                    disabled={
                                        playbookObject?.worksheet ===
                                            'select' &&
                                        !playbookObject.selectedWorksheet
                                    }
                                >
                                    {playbook
                                        ? `Edit ${type?.toLowerCase()}`
                                        : `Create ${type?.toLowerCase()}`}
                                </Button>
                            )}
                        </Grid>
                    )}
                </Grid>
            </form>
        </Box>
    );
};

export default CreatePlaybook;
