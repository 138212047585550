import React, { useContext, useEffect, useState } from 'react';
import { PlaybookPageProps } from '../playbookTypes';
import { generateClient } from 'aws-amplify/api';
import { getUserOrganisation } from '../../../helpers/auth';
import {
    cardsCardSetsByCardSetIdWithScores,
    cardsOrderedByNameWithScoreData,
} from '../../../graphql/custom-queries';
import {
    Attribute,
    Card,
    CardsCardSetsByCardSetIdWithScoresQuery,
    CardsOrderedByNameQuery,
} from '../../../API';
import { Box, Typography, useTheme } from '@mui/material';

import PieChart from '../../charts/PieChart';
import ComparisonTable from './ComparisonTable';
import CardDetailsFromWorksheet from './CardDetailsFromWorksheet';
import { getCategoryHex } from '../../../helpers/category';
import { generateColorShades } from '../../../helpers/charts';
import { worksheets } from '../../forms/worksheets';
import {
    StyledPlaybookPage,
    StyledPlaybookScrollContainer,
} from '../StyledPlaybookComponents';
import Spiral from '../../../assets/images/SC-spiral.png';
import { StyledSection } from '../../StyledElements';
import PlaybookMenu from '../PlaybookMenu';
import { UserPermissions } from '../../../globals';
import { AppContext } from '../../contexts';
import CustomLegend from '../../charts/CustomLegend';
import PieChartBox from './PieChartBox';
import Logo from '../../profile/Logo';
import { Field } from '../../../helpers/worksheets';

const ListPage = ({
    page,
    playbookPageForm,
    setEditMode,
    orderPages,
    cards,
    handleDelete,
    itemSelected,
    permissions,
    first,
    last,
    edit,
    carousel,
    pptView,
    logo,
    pageOptions,
}: PlaybookPageProps) => {
    const theme = useTheme();
    const includesOnlyDetails =
        page &&
        page.include?.every((field) => field === Field.DETAILS) &&
        page.include?.length === 1;

    const { cardTypes, user } = useContext(AppContext);
    const [maxNumberAttributeValues, setMaxNumberAttributeValues] = useState(0);
    const worksheet = worksheets.find((item) => item.i === page?.worksheetId);
    const leftChartId = worksheet?.lp?.l as string;
    const rightChartId = worksheet?.lp?.r as string;

    const leftChartParts = leftChartId?.split('-');
    const leftChartTitle =
        (
            leftChartParts &&
            cardTypes
                .find((item) => item.id === leftChartParts[0])
                ?.attributeDefinitions?.find((attr) => attr?.id === leftChartId)
                ?.name
        )?.toLowerCase() || '';

    const rightChartParts = rightChartId?.split('-');
    const rightChartTitle =
        (
            rightChartParts &&
            cardTypes
                .find((item) => item.id === rightChartParts[0])
                ?.attributeDefinitions?.find(
                    (attr) => attr?.id === rightChartId
                )?.name
        )?.toLowerCase() || '';

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const setMenu = (e: React.BaseSyntheticEvent) => {
        e.stopPropagation();

        if (e) {
            setAnchorEl(e.currentTarget);
        } else {
            setAnchorEl(null);
        }
    };

    const [livePageCards, setLivePageCards] = useState<Card[] | undefined>(
        cards
    );
    const [adjustedCategoryColors, setAdjustedCategoryColors] =
        useState<string[]>();
    const parsedPageCardSetIds = page?.cardSets;
    const client = generateClient();

    useEffect(() => {
        const fetchCards = async () => {
            const getCardsCardSets = async (cardSetId?: string) => {
                if (cardSetId) {
                    return (await client.graphql({
                        query: cardsCardSetsByCardSetIdWithScores,
                        variables: {
                            cardSetId,
                        },
                    })) as { data: CardsCardSetsByCardSetIdWithScoresQuery };
                } else {
                    return;
                }
            };

            const getCards = async (cardSetIds: (string | undefined)[]) => {
                const cardSetRes = await Promise.all(
                    cardSetIds.map((cardSetId) => {
                        if (cardSetId) {
                            return getCardsCardSets(cardSetId);
                        }
                    })
                );

                const cardsCardSets = cardSetRes.flatMap(
                    (promiseItem) =>
                        promiseItem?.data?.cardsCardSetsByCardSetId?.items
                );

                const cards = cardsCardSets?.map(
                    (cardsCardSet) => cardsCardSet?.card
                ) as Card[];
                const dedupedCards = cards.filter(
                    (value, index, self) =>
                        index === self.findIndex((t) => t.id === value.id) &&
                        worksheet?.auto.includes(value.cardToCardTypeId)
                );

                if (dedupedCards) {
                    setLivePageCards(dedupedCards);
                }
            };

            const cardSetsToQuery: any = playbookPageForm?.cardSetIds.length
                ? playbookPageForm?.cardSetIds
                : playbookPageForm?.workbookIds.length
                ? playbookPageForm?.workbookIds
                : parsedPageCardSetIds ?? [];

            if (cardSetsToQuery.length) {
                getCards(cardSetsToQuery);
            } else {
                setLivePageCards([]);
            }
        };

        playbookPageForm?.workbookIds?.length ||
        playbookPageForm?.cardSetIds?.length
            ? fetchCards()
            : cards?.length
            ? setLivePageCards(cards as Card[])
            : setLivePageCards([]);
    }, [playbookPageForm?.cardSetIds, playbookPageForm?.workbookIds]);
    useEffect(() => {
        const fetchCards = async () => {
            const userGroup = getUserOrganisation(user);

            const ids: { id: { eq: string } }[] = [];
            playbookPageForm?.cardIds.forEach((id: string) => {
                ids.push({ id: { eq: id } });
            });

            let allCards: Card[] = [];
            let nextToken = null;

            try {
                do {
                    const response = await client.graphql({
                        query: cardsOrderedByNameWithScoreData,
                        variables: {
                            organisation: userGroup,
                            filter: { or: ids },
                            nextToken: nextToken,
                        },
                    });

                    const result = response as {
                        data: CardsOrderedByNameQuery;
                    };
                    allCards = [
                        ...allCards,
                        ...(result?.data?.cardsOrderedByName?.items as Card[]),
                    ];
                    nextToken = result?.data?.cardsOrderedByName?.nextToken;
                } while (nextToken);

                setLivePageCards(allCards);
            } catch (e) {
                console.log(e);
            }
        };

        if (
            playbookPageForm?.cardIds &&
            playbookPageForm?.cardIds?.length > 0
        ) {
            !cards?.length ? fetchCards() : setLivePageCards(cards);
        } else {
            setLivePageCards(cards);
        }
    }, [playbookPageForm?.cardIds, cards, user]);

    const processAttributes = (
        attributes: (Attribute | null | undefined)[],
        chartId: string,
        pieLabels: string[],
        pieData: number[]
    ) => {
        if (!livePageCards) return;
        const uncategorized = 'Uncategorized';
        const uncategorizedNumber =
            livePageCards?.length -
            attributes.filter((item) => item?.attributeDefinitionID === chartId)
                .length;

        const attributeValues = attributes
            ?.filter((item) => item?.attributeDefinitionID === chartId)
            .map((attribute) => {
                if (attribute?.value) {
                    return Array.from(attribute?.value)[0] === '"'
                        ? JSON.parse(attribute?.value)
                        : attribute?.value;
                }
            });

        attributeValues?.forEach((value: any) => {
            if (attributeValues.length > maxNumberAttributeValues) {
                setMaxNumberAttributeValues(attributeValues.length);
            }
            if (value) {
                const index = pieLabels.indexOf(value);
                if (index === -1) {
                    pieLabels.push(value);
                    pieData.push(1);
                } else {
                    pieData[index] = pieData[index] + 1;
                }
            }
        });
        if (uncategorizedNumber > 0) {
            const uncategorizedIndex = pieLabels.indexOf(uncategorized);
            if (uncategorizedIndex === -1) {
                pieLabels.push(uncategorized);
                pieData.push(uncategorizedNumber);
            } else {
                pieData[uncategorizedIndex] += uncategorizedNumber;
            }
        }
    };

    const uncategorized = 'Uncategorized';
    const leftPieLabels: string[] = [];
    const leftPieData: number[] = [];

    const rightPieLabels: string[] = [];
    const rightPieData: number[] = [];
    const attributes = livePageCards?.flatMap((card) => card.attributes) || [];
    processAttributes(attributes, leftChartId, leftPieLabels, leftPieData);
    processAttributes(attributes, rightChartId, rightPieLabels, rightPieData);

    useEffect(() => {
        const categoryColor =
            livePageCards &&
            livePageCards.length > 0 &&
            getCategoryHex(livePageCards[0].cardToCardCategoryId);

        if (categoryColor) {
            setAdjustedCategoryColors(
                generateColorShades(categoryColor, maxNumberAttributeValues)
            );
        }
    }, [livePageCards]);

    const smallStyles = {
        height: '100%',
        overflow: 'hidden',
        pointerEvents: 'none',
        userSelect: 'none',
    };

    const defaultLayout = { layout: null, include: null };
    const parsedOptions = page?.options
        ? JSON.parse(page.options)
        : defaultLayout;
    const layout =
        pageOptions?.layout?.[0]?.layout ?? parsedOptions.layout?.[0]?.layout;
    const include =
        pageOptions?.layout?.[0]?.include ?? parsedOptions.layout?.[0]?.include;

    return (
        <StyledPlaybookPage edit={!!playbookPageForm} carousel={carousel}>
            {!playbookPageForm && !pptView && (
                <Box
                    sx={{
                        width: '50px',
                        height: '418px',
                        background: `url(${Spiral})`,
                        backgroundRepeat: 'repeat-y',
                        position: 'absolute',
                        left: '-11px',
                        backgroundSize: '65%',
                    }}
                />
            )}
            <StyledPlaybookScrollContainer
                sx={(!playbookPageForm && smallStyles) || {}}
            >
                <StyledSection
                    sx={{
                        mb: !playbookPageForm ? 1.5 : 'inherit',
                        minHeight: pptView ? '100px' : 'initial',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontSize= {carousel ? "3rem" : !!playbookPageForm ? "2rem" :"1.3rem"}
                            sx={{ width: '100%' }}
                        >
                            {page?.title}{' '}
                            {pptView && (
                                <span
                                    style={{ fontSize: '24px' }}
                                >{`(1 of 2)`}</span>
                            )}
                        </Typography>
                        {logo &&
                            layout?.find(
                                (item: { value: string }) =>
                                    item.value === 'logo'
                            )?.enabled &&
                            !pptView && (
                                <Logo
                                    thumbnail={!playbookPageForm}
                                    logo={logo}
                                    pptView={pptView}
                                />
                            )}
                    </Box>

                    <Typography
                        variant="body2"
                        fontSize={
                            pptView
                                ? '1em'
                                : playbookPageForm && !pptView
                                ? '0.875em'
                                : '0.9em'
                        }
                        sx={{ marginTop: '10px', whiteSpace: 'pre-wrap' }}
                    >
                        {page?.commentary}
                    </Typography>
                </StyledSection>
                {pptView &&
                    include?.find(
                        (item: { value: string }) => item.value === 'charts'
                    )?.enabled && <Box sx={{ height: '500px' }} />}
                {include?.find(
                    (item: { value: string }) => item.value === 'charts'
                )?.enabled &&
                    livePageCards &&
                    livePageCards.length > 0 &&
                    (leftChartId || rightChartId) && (
                        <StyledSection
                            sx={{
                                mb: !playbookPageForm ? 0.5 : '2em',
                                height: pptView ? '500px' : 'initial',
                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mt: pptView ? 5 : playbookPageForm ? 2 : -1,
                                    mb: pptView ? 3 : playbookPageForm ? 2 : 0,
                                    fontSize: pptView
                                        ? '24px'
                                        : !playbookPageForm
                                        ? '8px'
                                        : null,
                                }}
                            >
                                Charts
                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                }}
                            >
                                <PieChartBox
                                    chartId={leftChartId}
                                    pieLabels={leftPieLabels}
                                    pieData={leftPieData}
                                    chartTitle={leftChartTitle}
                                    playbookPageForm={playbookPageForm}
                                    adjustedCategoryColors={
                                        adjustedCategoryColors
                                    }
                                    pptView={pptView}
                                />
                                <Box sx={{ ml: pptView ? 10 : 0 }}>
                                    <PieChartBox
                                        chartId={rightChartId}
                                        pieLabels={rightPieLabels}
                                        pieData={rightPieData}
                                        chartTitle={rightChartTitle}
                                        playbookPageForm={playbookPageForm}
                                        adjustedCategoryColors={
                                            adjustedCategoryColors
                                        }
                                        pptView={pptView}
                                    />
                                </Box>
                            </Box>
                        </StyledSection>
                    )}

                {include?.find(
                    (item: { value: string }) =>
                        item.value === 'comparison table'
                )?.enabled &&
                    !pptView &&
                    livePageCards &&
                    livePageCards.length > 0 && (
                        <StyledSection
                            sx={{ mb: !playbookPageForm ? 0.5 : '2em' }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mt: playbookPageForm ? 0 : 0,
                                    mb: playbookPageForm ? 2 : -2,
                                    fontSize: !playbookPageForm ? '8px' : null,
                                }}
                            >
                                Comparison table
                            </Typography>

                            <ComparisonTable
                                page={page}
                                livePageCards={
                                    !playbookPageForm &&
                                    include?.find(
                                        (item: { value: string }) =>
                                            item.value === 'details'
                                    )?.enabled &&
                                    !pptView &&
                                    livePageCards.length > 2
                                        ? [livePageCards[0], livePageCards[1]]
                                        : livePageCards
                                }
                                playbookPageForm={playbookPageForm}
                                leftChartId={leftChartId}
                                rightChartId={rightChartId}
                                include={include}
                            />
                        </StyledSection>
                    )}
                {include?.find(
                    (item: { value: string }) => item.value === 'details'
                )?.enabled &&
                    !pptView &&
                    livePageCards &&
                    livePageCards.length > 0 && (
                        <StyledSection
                            sx={{ mb: !playbookPageForm ? 0 : '2em' }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    mb: playbookPageForm ? 2 : 0.5,
                                    fontSize: !playbookPageForm ? '8px' : null,
                                }}
                            >
                                Details
                            </Typography>
                            <Box>
                                {!playbookPageForm && !pptView
                                    ? livePageCards
                                          ?.slice(
                                              0,
                                              includesOnlyDetails ? 2 : 1
                                          )
                                          .map((card) => {
                                              return (
                                                  <CardDetailsFromWorksheet
                                                      card={card}
                                                      frontCard
                                                  />
                                              );
                                          })
                                    : livePageCards?.map((card) => {
                                          return (
                                              <CardDetailsFromWorksheet
                                                  card={card}
                                              />
                                          );
                                      })}
                            </Box>
                        </StyledSection>
                    )}
            </StyledPlaybookScrollContainer>
            {edit !== true &&
                (permissions.includes(UserPermissions.DELETE) ||
                    permissions.includes(UserPermissions.EDIT)) && (
                    <PlaybookMenu
                        id={page?.id ?? ''}
                        setMenu={setMenu}
                        orderPages={orderPages}
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        handleDelete={handleDelete}
                        setEditMode={setEditMode}
                        first={first}
                        last={last}
                        iconColor="rgba(0, 0, 0, 0.54)"
                    />
                )}
        </StyledPlaybookPage>
    );
};

export default ListPage;
