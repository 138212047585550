import { createPieChartData } from '../../helpers/charts';
import { getPieChartOptions } from './PieChartOptions ';
import { EditablePageValues } from '../playbooks/EditablePlaybookPage';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

interface PieChartProps {
    labels: string[];
    data: number[];
    defaultLabel?: string;
    colors?: string[];
    title: string;
    playbookPageForm?: EditablePageValues;
    pptView?: boolean;
    setActivePieLabel?: React.Dispatch<React.SetStateAction<string>>;
}

const PieChart = ({
    labels,
    data,
    defaultLabel,
    colors,
    title,
    playbookPageForm,
    pptView,
    setActivePieLabel,
}: PieChartProps) => {
    const chartData = createPieChartData(labels, data, defaultLabel, colors);
    const options = getPieChartOptions(
        title,
        chartData,
        playbookPageForm,
        setActivePieLabel
    );

    return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default PieChart;
