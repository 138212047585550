import React, { useContext, useEffect, useRef, useState } from 'react';
import { createBubbleChartData } from '../../helpers/charts';
import { getBubbleChartOptions } from './BubblesChartOptions';
import { ColorMapping } from '../playbooks/analysis/BubblePage';
import { ChartEvent } from 'chart.js/dist/core/core.plugins';
import { BubbleDataPoint, Chart } from 'chart.js';
import { AttributeDefinition, Card } from '../../API';
import { CardScoreData } from '../../helpers/worksheets';
import { NodeColorOptions, NodeSizeOptions } from '../playbooks/playbookTypes';
import { Box, IconButton, useTheme } from '@mui/material';
import { useDroppable } from '@dnd-kit/core';
import Highcharts, { SeriesBubbleOptions } from 'highcharts';
import 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { CardComponentData } from '../cards/cardTypes';
import { WorksheetContext } from '../contexts';
import ReactDOM from 'react-dom';
import BubbleModal from '../playbooks/analysis/BubbleModal';
import { UserPermissions } from '../../globals';
import { getWorkSheetCardMenuItems } from '../../helpers/worksheetCards';
import CardMenu from '../cards/CardMenu';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';

export interface CustomDataPoint {
    x: number;
    y: number;
    r: number;
    label: string;
    name: string;
    card: CardScoreData;
    originalCard: Card;
}
interface BubbleChartOptions {
    onClick: (event: ChartEvent, elements: Array<any>, chart: Chart) => void;
}
export interface Node {
    x: number;
    y: number;
    r: number;
}
interface BubbleChartProps {
    labels: string[];
    data: CustomDataPoint[][];
    colors: ColorMapping;
    xAxisOptions: any;
    yAxisOptions: any;
    displayGrid: boolean;
    thumbnail: boolean;
    categoryColor: string;
    onBubbleClick: (
        data: CustomDataPoint,
        position: { top: number; left: number }
    ) => void;
    nodes: Node[];
    nodeSizeOption: NodeSizeOptions;
    workbook?: boolean;
    carousel?: boolean;
    nodeColorOption: NodeColorOptions;
    setSelectedCard: React.Dispatch<React.SetStateAction<Card | null>>;
    pptView?: boolean;
    cardTypeId?: string;
    chartRef: React.MutableRefObject<Chart<
        'bubble',
        BubbleDataPoint[],
        unknown
    > | null>;
    zoomStateRef?: React.MutableRefObject<{
        xMin: number | undefined;
        xMax: number | undefined;
        yMin: number | undefined;
        yMax: number | undefined;
    }>;
    handleBubbleHover: (
        data: CustomDataPoint,
        position: {
            top: number;
            left: number;
        }
    ) => void;
    handleCardClick?: (card: Card | CardComponentData, side: string) => void;
    activeCardId?: string | null;
    activeCardId2?: string | null;
    attributeDefinitions: (AttributeDefinition | null)[] | null | undefined;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    allScores: string[] | undefined;
    allAttributes: string[] | undefined;
    worksheetId: string | undefined;
    permissions: UserPermissions[] | undefined;
    activeCardIds?: (string | null)[];
}

const BubbleChart = ({
    labels,
    data,
    colors,
    xAxisOptions,
    yAxisOptions,
    displayGrid,
    thumbnail,
    categoryColor,
    onBubbleClick,
    nodes,
    nodeSizeOption,
    workbook,
    carousel,
    nodeColorOption,
    setSelectedCard,
    pptView,
    cardTypeId,
    chartRef,
    zoomStateRef,
    handleBubbleHover,
    handleCardClick,
    activeCardId,
    activeCardId2,
    attributeDefinitions,
    setIsModalOpen,
    allScores,
    allAttributes,
    worksheetId,
    permissions,
    activeCardIds,
}: BubbleChartProps) => {
    const { isOver, setNodeRef } = useDroppable({
        id: `${cardTypeId}_`,
    });
    const {
        multiSelectedCards,
        setMultiSelectedCards,
        handleCopy,
        handleDelete,
        handleRemoveFromWorkbook,
        setCardFrom,
        handleRemoveFromWorksheet,
        createCardRelationship,
    } = useContext(WorksheetContext);
    const theme = useTheme();
    const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>();
    const setMenu = (e: React.MouseEvent<HTMLElement> | null) => {
        if (e) {
            e.stopPropagation();
            setMenuAnchor(e.currentTarget);
        } else {
            setMenuAnchor(null);
        }
    };
    const handleMultiSelect = (card: Card) => {
        setMultiSelectedCards((multiSelectedCards) => {
            let cards = [...multiSelectedCards];

            if (
                multiSelectedCards.find(
                    (selectedCard) => selectedCard?.id === card?.id
                )
            ) {
                cards = cards.filter((c) => c?.id !== card?.id);
            } else {
                cards.push(card);
            }

            return cards;
        });
    };

    const bubbleSeriesData = createBubbleChartData(
        labels,
        data,
        colors,
        categoryColor,
        thumbnail,
        nodeSizeOption,
        nodeColorOption
    ) as SeriesBubbleOptions[];

    const highchartsOptions = getBubbleChartOptions(
        xAxisOptions,
        yAxisOptions,
        displayGrid,
        thumbnail,
        bubbleSeriesData,
        carousel as boolean,
        theme,
        workbook,
        pptView,
        onBubbleClick,
        handleCardClick,
        activeCardId,
        activeCardId2,
        handleMultiSelect,
        multiSelectedCards
    );
    const allAttributesRef = useRef(allAttributes);
    const allScoresRef = useRef(allScores);
    const attributeDefinitionsRef = useRef(attributeDefinitions);
    const permissionsRef = useRef(permissions);

    useEffect(() => {
        allAttributesRef.current = allAttributes;
        allScoresRef.current = allScores;
        attributeDefinitionsRef.current = attributeDefinitions;
        permissionsRef.current = permissions;
    }, [allAttributes, allScores, attributeDefinitions, permissions]);

    useEffect(() => {
        Highcharts.wrap(
            Highcharts.Tooltip.prototype,
            'refresh',
            function (this: Highcharts.Tooltip, proceed, ...args) {
                proceed.apply(this, args);

                setTimeout(() => {
                    const tooltip = this as any;
                    const dataPoint = tooltip.chart.hoverPoint;

                    const customDataPoint = {
                        x: dataPoint?.x,
                        y: dataPoint?.y,
                        r: dataPoint?.z,
                        label: dataPoint?.name,
                        name: dataPoint?.name,
                        card: dataPoint?.card,
                        originalCard: dataPoint?.card?.originalCard,
                    };
                    if (dataPoint?.card?.originalCard) {
                        ReactDOM.render(
                            <BubbleModal
                                node={customDataPoint}
                                attributeDefinitions={
                                    attributeDefinitionsRef.current ?? []
                                }
                                setIsModalOpen={setIsModalOpen}
                                allScores={allScoresRef.current ?? []}
                                allAttributes={allAttributesRef.current ?? []}
                                selectedCard={dataPoint?.card?.originalCard}
                                workbook={workbook}
                                worksheetId={worksheetId}
                                permissions={permissionsRef.current ?? []}
                                handleCopy={handleCopy}
                                handleRemoveFromWorkbook={
                                    handleRemoveFromWorkbook
                                }
                                setCardFrom={setCardFrom}
                            />,

                            document.getElementById('custom-bubble-tooltip')
                        );
                    } else {
                        console.error('No card data found on hover point.');
                    }
                }, 0);
            }
        );

        return () => {
            Highcharts.Tooltip.prototype.refresh =
                Highcharts.Tooltip.prototype.refresh;
        };
    }, [allAttributes, allScores, attributeDefinitions]);

    const workbookPermissions = [UserPermissions.DELETE, UserPermissions.EDIT];
    const card = multiSelectedCards && multiSelectedCards[0];
    const menuItems = getWorkSheetCardMenuItems({
        activeCardIds,
        card,
        worksheetId,
        permissions,
        workbookPermissions,
        handleDelete,
        multiSelectedCards,
        handleRemoveFromWorkbook,
        handleRemoveFromWorksheet,
        createCardRelationship,
        handleCopy,
        setCardFrom,
    });

    return carousel ? (
        <Box sx={{ canvas: { height: '500px!important' } }}>
            <HighchartsReact
                highcharts={Highcharts}
                options={highchartsOptions}
            />
        </Box>
    ) : workbook ? (
        <Box
            sx={{
                height: '100%',
                width: '100%',
                mt: -5,
                position: 'relative',
            }}
            ref={setNodeRef}
        >
            <HighchartsReact
                highcharts={Highcharts}
                options={highchartsOptions}
            />
            {multiSelectedCards && multiSelectedCards?.length > 0 && (
                <Box
                    sx={{
                        position: 'absolute',
                        bottom: 70,
                        right: 10,
                    }}
                >
                    {(menuItems.length > 0 ||
                        permissions?.includes(UserPermissions.EDIT)) && (
                        <IconButton
                            aria-label=""
                            id="menu-button"
                            onClick={setMenu}
                            sx={{
                                color: 'black',
                            }}
                        >
                            <MenuIcon width={'40px'} height={'40px'} />
                        </IconButton>
                    )}
                    {menuAnchor && (
                        <CardMenu
                            permissions={[
                                UserPermissions.EDIT,
                                UserPermissions.DELETE,
                            ]}
                            anchor={menuAnchor}
                            data={{
                                organisation:
                                    multiSelectedCards[0].organisation || '',
                                id: multiSelectedCards[0].id,
                            }}
                            menuItems={menuItems}
                            setMenu={setMenu}
                        />
                    )}
                </Box>
            )}
        </Box>
    ) : (
        <HighchartsReact highcharts={Highcharts} options={highchartsOptions} />
    );
};

export default BubbleChart;
