import { Card, CardType, Worksheet } from '../API';
import {
    colorArrays,
    getRiskCards,
} from '../components/playbooks/analysis/RiskAnalysis';
import { ScoreCard } from './card';
import { sortObj } from './utils';
import { ChartType } from './worksheets';
import { worksheets } from '../components/forms/worksheets';
import { UserPermissions } from '../globals';
import { CardToDelete } from '../pages/cards';
import { Dispatch, SetStateAction } from 'react';
import { MenuItem } from '../components/cards/CardMenu';

const sortPorters = ({
    cards,
    cardTypeObject,
}: {
    cards: Card[];
    cardTypeObject: {
        [key: string]: CardType;
    };
}) => {
    const trend = Object.values(cardTypeObject).find(
        (type) => type.id === '12'
    );

    const category = trend?.attributeDefinitions?.find(
        (att) => att?.id === '12-A-1'
    );
    let forces: string = category?.configuration ?? '';
    forces = JSON.parse(forces);
    let categories = Array.isArray(forces) ? forces : JSON.parse(forces);

    categories = categories.map((category: string) => {
        const items: Card[] = [];

        cards.forEach((card) => {
            const attribute = card.attributes?.find(
                (attr) => attr?.attributeDefinitionID === '12-A-1'
            );
            const value = attribute?.value ? JSON.parse(attribute.value) : '';

            if (value === category) {
                items.push(card);
            }
        });

        return items;
    });

    return categories.flat(1);
};
const sortPestle = ({
    cards,
    cardTypeObject,
}: {
    cards: Card[];
    cardTypeObject: {
        [key: string]: CardType;
    };
}) => {
    const trend = Object.values(cardTypeObject).find(
        (type) => type.id === '11'
    );
    const category = trend?.attributeDefinitions?.find(
        (att) => att?.id === '11-A-2'
    );
    const time = trend?.attributeDefinitions?.find(
        (att) => att?.id === '11-A-3'
    );

    let categoryConfig: string = category?.configuration ?? '';
    categoryConfig = JSON.parse(categoryConfig);
    let categories: string[] = Array.isArray(categoryConfig)
        ? categoryConfig
        : JSON.parse(categoryConfig);

    let timeScalesConfig: string[] =
        JSON.parse(time?.configuration ?? '') ?? [];
    let timeScales: string[] = Array.isArray(timeScalesConfig)
        ? timeScalesConfig
        : JSON.parse(timeScalesConfig);
    let times = timeScales.map((time: string) => {
        const timeItems: Card[][] = [];
        categories.forEach((category) => {
            const items: Card[] = [];

            cards.forEach((card) => {
                const catAttribute = card.attributes?.find(
                    (attr) => attr?.attributeDefinitionID === '11-A-2'
                );
                const catValue = catAttribute?.value
                    ? JSON.parse(catAttribute.value)
                    : '';

                const attribute = card.attributes?.find(
                    (attr) => attr?.attributeDefinitionID === '11-A-3'
                );
                const value = attribute?.value
                    ? JSON.parse(attribute.value)
                    : '';

                if (value === time && catValue === category) {
                    items.push(card);
                }
            });

            timeItems.push(items);
        });
        return timeItems;
    });

    return times.flat(2);
};
const sortRisk = ({
    cards,
    cardTypeObject,
}: {
    cards: Card[];
    cardTypeObject: {
        [key: string]: CardType;
    };
}) => {
    const allCards: ScoreCard[][] = [];
    colorArrays.forEach((colorArray, yIndex) => {
        colorArray.forEach((color, xIndex) => {
            const riskCards = getRiskCards(xIndex, yIndex, cards);

            allCards.push(riskCards);
        });
    });

    return allCards.flat(1);
};

export const sortWorksheetCardsByTypeOrAttribute = ({
    cards,
    chartType,
    cardTypeObject,
}: {
    cards: Card[];
    chartType: ChartType;
    cardTypeObject: {
        [key: string]: CardType;
    };
}): Card[] => {
    switch (chartType) {
        case ChartType.PORTERS_FIVE_FORCES:
            return sortPorters({ cards, cardTypeObject });
        case ChartType.PESTLE_ANALYSIS:
            return sortPestle({ cards, cardTypeObject });
        case ChartType.RISK_ANALYSIS:
            return sortRisk({ cards, cardTypeObject });
        default:
            return sortObj(cards, 'cardToCardTypeId');
    }
};

export const showAddToWorksheet = ({
    worksheet,
    card,
    cardTypeObject,
}: {
    worksheet: Worksheet;
    card: Card;
    cardTypeObject: { [key: string]: CardType };
}) => {
    const cards = worksheets.find((w) => w.i === worksheet.typeId)?.auto;

    if (worksheet.typeId === '5') {
        const options = JSON.parse(worksheet.options as string);

        const scoreDefinitions =
            cardTypeObject[card.cardToCardTypeId]?.scoreDefinitions;

        let x = scoreDefinitions.find(
            (score) => score.name === options.chartData.xAxis
        )?.id;

        let y = scoreDefinitions.find(
            (score) => score.name === options.chartData.yAxis
        )?.id;

        let scoreX = card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === x
        );
        let scoreY = card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === y
        );

        if (!scoreX?.data.length || !scoreY?.data.length) return false;

        if (
            !scoreX?.data[scoreX.data.length - 1].value ||
            !scoreX?.data[scoreX.data.length - 1].value
        )
            return false;

        return true;
    }

    if (worksheet.typeId === '7') {
        const mitigation = card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === '7-S-3'
        );
        const mitigationScore = mitigation?.data[mitigation.data.length - 1];

        let likelihood = card.toScoreData?.items.find(
            (item) => item?.scoreDefinitionId === '7-S-2'
        );

        const scoreLikelihood = likelihood?.data?.[likelihood.data.length - 1];

        if (!scoreLikelihood?.value || !mitigationScore?.value) return false;
    }
    if (worksheet.typeId === '63') {
        if (!card.toScoreData?.items.length) return false;

        let total = 0;

        card.toScoreData.items.forEach((item) => {
            let data = item?.data;
            if (data?.length) {
                let value = data ? data[data.length - 1].value : 0;

                if (value) {
                    total += 1;
                }
            }
        });

        if (total > 2) return true;

        return false;
    }
    if (worksheet.typeId === '64') {
        let score = card.toScoreData?.items.find(
            (scoreItem) => scoreItem?.scoreDefinitionId === '12-S-1'
        )?.data;

        let value = score ? score[score.length - 1].value : 0;

        if (
            !value ||
            !card.attributes?.find(
                (attr) => attr?.attributeDefinitionID === '12-A-1'
            )
        )
            return false;
    }
    if (worksheet.typeId === '65') {
        if (
            !card.attributes?.find(
                (attr) => attr?.attributeDefinitionID === '11-A-2'
            ) ||
            !card.attributes?.find(
                (attr) => attr?.attributeDefinitionID === '11-A-3'
            )
        )
            return false;
    }

    if (!cards?.length || !cards.includes(card?.cardToCardTypeId)) return false;

    return true;
};

export const getWorkSheetCardMenuItems = ({
    card,
    workbookPermissions,
    permissions,
    worksheetId,
    handleRemoveFromWorkbook,
    handleRemoveFromWorksheet,
    createCardRelationship,
    multiSelectedCards,
    handleDelete,
    setCardFrom,
    handleCopy,
    activeCardIds,
}: {
    card: Card | undefined;
    workbookPermissions?: UserPermissions[];
    permissions: UserPermissions[] | undefined;
    worksheetId?: string;
    handleRemoveFromWorkbook: ((card: Card) => void) | undefined;
    handleRemoveFromWorksheet: ((id: string) => void) | undefined;
    createCardRelationship?: (target: string, source: string) => void;
    multiSelectedCards?: Card[];
    handleDelete: (card: Card) => void;
    setCardFrom: ((card: Card[]) => void) | undefined;
    handleCopy:
        | ((card: Card, worksheetId?: string | undefined) => void)
        | undefined;
    activeCardIds?: (string | null)[];
}) => {
    if (!card) return;
    const menuItems: any = [];

    const activeCards = activeCardIds?.filter(Boolean) ?? [];

    if (activeCards.length === 2 && activeCards.includes(card.id)) {
        createCardRelationship &&
            menuItems.push({
                text: 'Create relationship',
                action: () => {
                    createCardRelationship(
                        activeCards[0] as string,
                        activeCards[1] as string
                    );
                },
            });
    } else if (
        multiSelectedCards?.length &&
        multiSelectedCards.find((selectedCard) => selectedCard.id === card.id)
    ) {
        setCardFrom &&
            menuItems.push({
                text: 'Create card from',
                action: () => {
                    setCardFrom(multiSelectedCards);
                },
            });
        createCardRelationship &&
            multiSelectedCards.length === 2 &&
            menuItems.push({
                text: 'Create relationship',
                action: () => {
                    createCardRelationship(
                        multiSelectedCards[0].id,
                        multiSelectedCards[1].id
                    );
                },
            });
    } else {
        handleCopy &&
            menuItems.push({
                text: 'Copy',
                action: () => {
                    handleCopy(card, worksheetId);
                },
            });
        setCardFrom &&
            menuItems.push({
                text: 'Create card from',
                action: () => {
                    setCardFrom([card]);
                },
            });

        permissions?.includes(UserPermissions.DELETE) &&
            menuItems.push({
                text: 'Delete',
                action: () => {
                    handleDelete(card);
                },
            });
        handleRemoveFromWorkbook &&
            workbookPermissions?.includes(UserPermissions.EDIT) &&
            menuItems.push({
                text: 'Remove from workbook',
                action: () => {
                    handleRemoveFromWorkbook(card);
                },
            });
        handleRemoveFromWorksheet &&
            workbookPermissions?.includes(UserPermissions.EDIT) &&
            menuItems.push({
                text: 'Remove from worksheet',
                action: () => {
                    handleRemoveFromWorksheet(card.id);
                },
            });
    }

    return menuItems;
};

export const getWorkSheetPanelCardMenuItems = ({
    card,
    cardTypeObject,
    workbookPermissions,
    permissions,
    worksheet,
    addCardToWorksheet,
    handleRemoveFromWorkbook,
    createCardRelationship,
    multiSelectedCards,
    handleDelete,
    setCardFrom,
    handleCopy,
    activeCardIds,
}: {
    card: Card;
    workbookPermissions?: UserPermissions[];
    permissions: UserPermissions[];
    worksheet: Worksheet;
    handleRemoveFromWorkbook: ((card: Card) => void) | undefined;
    createCardRelationship?: (target: string, source: string) => void;
    addCardToWorksheet?: ((card: Card) => void) | undefined;
    multiSelectedCards?: Card[];
    handleDelete: (card: Card) => void;
    setCardFrom: ((card: Card[]) => void) | undefined;
    handleCopy:
        | ((card: Card, worksheetId?: string | undefined) => void)
        | undefined;
    cardTypeObject: {
        [key: string]: CardType;
    };
    activeCardIds: (string | null)[];
}) => {
    const menuItems: any = [];

    let activeCards = activeCardIds.filter((activeCardId) => activeCardId);

    if (activeCards.length === 2 && activeCards.includes(card.id)) {
        createCardRelationship &&
            menuItems.push({
                text: 'Create relationship',
                action: () => {
                    createCardRelationship(
                        activeCards[0] as string,
                        activeCards[1] as string
                    );
                },
            });
    } else if (
        multiSelectedCards?.length &&
        multiSelectedCards.find((selectedCard) => selectedCard.id === card.id)
    ) {
        setCardFrom &&
            menuItems.push({
                text: 'Create card from',
                action: () => {
                    setCardFrom(multiSelectedCards);
                },
            });
        createCardRelationship &&
            multiSelectedCards.length === 2 &&
            menuItems.push({
                text: 'Create relationship',
                action: () => {
                    createCardRelationship(
                        multiSelectedCards[0].id,
                        multiSelectedCards[1].id
                    );
                },
            });
    } else {
        addCardToWorksheet &&
            showAddToWorksheet({ worksheet, card, cardTypeObject }) &&
            menuItems.push({
                text: 'Add card to worksheet',
                action: () => addCardToWorksheet(card),
            });
        handleCopy &&
            menuItems.push({
                text: 'Copy',
                action: () => {
                    handleCopy(card, worksheet.id);
                },
            });

        setCardFrom &&
            menuItems.push({
                text: 'Create card from',
                action: () => {
                    setCardFrom([card]);
                },
            });

        permissions.includes(UserPermissions.DELETE) &&
            menuItems.push({
                text: 'Delete',
                action: () => {
                    handleDelete(card);
                },
            });

        handleRemoveFromWorkbook &&
            workbookPermissions?.includes(UserPermissions.EDIT) &&
            menuItems.push({
                text: 'Remove from workbook',
                action: () => {
                    handleRemoveFromWorkbook(card);
                },
            });
    }

    return menuItems;
};

export const getCardMenuItems = ({
    card,
    handleCopy,
    handleDelete,
    handleRemove,
    createCardRelationship,
    createCardSet,
    setCardFrom,
    convert,
    multiSelectedCards,
    permissions,
    cardsObject,
}: {
    card: Card;
    handleCopy: ((cardId: string) => void) | undefined;
    createCardRelationship?: (target: string, source: string) => void;
    createCardSet?: () => void;
    handleDelete: ((id: string, organisation: string) => void) | undefined;
    handleRemove: ((id: string) => void) | undefined;
    convert: ((id: string) => void) | undefined;
    setCardFrom: ((cards: Card[]) => void) | undefined;
    multiSelectedCards?: Card[] | CardToDelete[];
    cardsObject: { [key: string]: Card };
    permissions: UserPermissions[];
}) => {
    const menuItems = [];

    if (
        multiSelectedCards?.length &&
        multiSelectedCards.findIndex(
            (selectedCard: Card | CardToDelete) => selectedCard.id === card.id
        ) !== -1
    ) {
        setCardFrom &&
            menuItems.push({
                text: 'Create card from',
                action: () => {
                    setCardFrom(
                        multiSelectedCards.map((selectedCard) => {
                            return cardsObject[selectedCard.id];
                        })
                    );
                },
            });
        createCardRelationship &&
            multiSelectedCards.length === 2 &&
            menuItems.push({
                text: 'Create relationship',
                action: () => {
                    createCardRelationship(
                        multiSelectedCards[0].id,
                        multiSelectedCards[1].id
                    );
                },
            });
        handleDelete &&
            permissions.includes(UserPermissions.DELETE) &&
            menuItems.push({
                text: 'Delete',
                action: () =>
                    handleDelete(card.id, card.organisation as string),
            });
        return menuItems;
    } else {
        handleCopy &&
            menuItems.push({
                text: 'Copy',
                action: () => handleCopy(card.id),
            });
        if (convert && permissions.includes(UserPermissions.EDIT)) {
            menuItems.push({
                text: 'Convert card',
                action: () => convert(card.id),
            });
        }

        setCardFrom &&
            cardsObject &&
            menuItems.push({
                text: 'Create card from',
                action: () => setCardFrom([cardsObject[card.id]] as Card[]),
            });

        createCardSet &&
            cardsObject &&
            menuItems.push({
                text: 'Create card set',
                action: () => createCardSet(),
            });

        handleDelete &&
            permissions.includes(UserPermissions.DELETE) &&
            menuItems.push({
                text: 'Delete',
                action: () =>
                    handleDelete(card.id, card.organisation as string),
            });

        handleRemove &&
            permissions.includes(UserPermissions.EDIT) &&
            menuItems.push({
                text: 'Remove card from set',
                action: () => handleRemove(card.id),
            });
    }
    return menuItems;
};

export const getWorkbookCardMenuItems = ({
    card,
    handleCopy,
    handleDelete,
    handleRemove,
    createCardRelationship,
    setCardFrom,
    convert,
    multiSelectedCards,
    cardsObject,
}: {
    card: Card;
    handleCopy: ((cardId: string) => void) | undefined;
    createCardRelationship?: (target: string, source: string) => void;
    handleDelete: ((id: string, organisation: string) => void) | undefined;
    handleRemove: ((id: string) => void) | undefined;
    convert: ((id: string) => void) | undefined;
    setCardFrom: ((cards: Card[]) => void) | undefined;
    multiSelectedCards?: Card[] | CardToDelete[];
    cardsObject: { [key: string]: Card };
}) => {
    const menuItems: MenuItem[] = [];

    if (
        multiSelectedCards?.length &&
        multiSelectedCards.findIndex(
            (selectedCard: Card | CardToDelete) => selectedCard?.id === card?.id
        ) !== -1
    ) {
        setCardFrom &&
            menuItems.push({
                text: 'Create card from',
                action: () => {
                    setCardFrom(
                        multiSelectedCards.map((selectedCard) => {
                            return cardsObject[selectedCard.id];
                        })
                    );
                },
            });
        createCardRelationship &&
            multiSelectedCards.length === 2 &&
            menuItems.push({
                text: 'Create relationship',
                action: () => {
                    createCardRelationship(
                        multiSelectedCards[0].id,
                        multiSelectedCards[1].id
                    );
                },
            });
        return menuItems;
    }

    handleCopy &&
        menuItems.push({
            text: 'Copy',
            action: () => {
                handleCopy(card.id);
            },
        });
    setCardFrom &&
        menuItems.push({
            text: 'Create card from',
            action: () => {
                setCardFrom([card]);
            },
        });
    handleDelete &&
        menuItems.push({
            text: 'Delete',
            action: () => {
                handleDelete(card.id, card.organisation);
            },
        });
    handleRemove &&
        menuItems.push({
            text: 'Remove from workbook',
            action: () => {
                handleRemove(card.id);
            },
        });
    convert &&
        menuItems.push({
            text: 'Convert card',
            action: () => {
                convert(card.id);
            },
        });

    return menuItems;
};
