import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { Worksheet } from '../../API';
import StyledCardBase from '../cards/CardBase';
import { worksheets } from '../forms/worksheets';
import { AppContext } from '../contexts';
import { useContext, useState } from 'react';
import {
    getWorksheetBackgroundColor,
    getWorksheetThumbnail,
} from '../../helpers/worksheets';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { truncateText } from '../../helpers/utils';
import { ReactComponent as MenuIcon } from '../../assets/icons/Menu.svg';
import { ReactComponent as FlipIcon } from '../../assets/icons/Flip.svg';
import WorksheetMenu from './WorksheetMenu';
import { StyledModal } from '../Modal';
import ModalContainer from '../ModalContainer';
import CardDelete from '../cards/CardDelete';
import { PageIdentifier } from '../cards/cardTypes';
import { UserPermissions } from '../../globals';
import { ViewType } from '../layouts/PageView';

interface WorksheetItemProps {
    worksheet: Worksheet;
    workbookId: string;
    deleteWorksheet: (id: string) => Promise<void>;
    deleting: boolean;
    workbookPermissions: UserPermissions[];
    selectedViewType: ViewType;
}

export const WorksheetAvatar = ({
    worksheet,
    selectedViewType,
}: {
    worksheet: string;
    selectedViewType: ViewType;
}) => {
    const WorksheetIcon: string = getWorksheetThumbnail(worksheet) as string;
    if (!WorksheetIcon) {
        console.error(`Icon not found for worksheet: ${worksheet}`);
        return null;
    }

    return (
        <Box
            component="img"
            src={WorksheetIcon}
            sx={{
                width: selectedViewType === ViewType.LIST ? 30 : '100%',
            }}
        ></Box>
    );
};

const WorksheetItem = ({
    worksheet,
    workbookId,
    deleteWorksheet,
    deleting,
    workbookPermissions,
    selectedViewType,
}: WorksheetItemProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showMenu, setShowMenu] = useState(true);
    const [deleteWorksheetConfirmation, setDeleteWorksheetConfirmation] =
        useState(false);
    const [isFlipped, setIsFlipped] = useState(false);
    const typeId = worksheet.typeId;
    const { cardTypeObject } = useContext(AppContext);
    const navigate = useNavigate();
    const currentWorksheet = worksheets.find(
        (worksheet) => worksheet.i === typeId
    );

    const { data, textColor } = getWorksheetBackgroundColor(
        worksheet,
        cardTypeObject
    );

    return (
        <Box
            sx={{
                cursor: 'pointer',
                '.MuiPaper-root': { cursor: 'pointer' },
            }}
            onClick={() =>
                !showMenu &&
                navigate(`/workbooks/${workbookId}/${worksheet.id}`)
            }
        >
            <Box
                className="flip-card"
                style={{
                    width: '100%',
                    height: '240px',
                }}
            >
                <motion.div
                    className="flip-card-inner"
                    initial={false}
                    animate={{ rotateY: isFlipped ? 180 : 360 }}
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                    transition={{ duration: 0.4, animationDirection: 'normal' }}
                >
                    <Box
                        className="flip-card-front"
                        sx={{
                            width: '100%',
                            height: '100%',
                            '&:hover .menu-icon': {
                                display: 'flex',
                            },
                        }}
                    >
                        <StyledCardBase
                            multiSelectedCards={[]}
                            data={data}
                            worksheet={true}
                            numberOfWorksheetCards={
                                worksheet?.toDataSources?.items?.length
                            }
                            worksheetFlipped={false}
                            smallCard={true}
                            headerRight={
                                <Box
                                    sx={{
                                        cursor: 'pointer',
                                        zIndex: 20000,
                                        paddingTop: '5px',
                                    }}
                                >
                                    <FlipIcon
                                        width="20px"
                                        height="20px"
                                        fill={textColor}
                                        color={textColor}
                                    />
                                </Box>
                            }
                            handleHeaderClick={() => setIsFlipped(!isFlipped)}
                        >
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    marginTop: '-8px',
                                }}
                                onClick={() =>
                                    navigate(
                                        `/workbooks/${workbookId}/${worksheet.id}`
                                    )
                                }
                            >
                                <WorksheetAvatar
                                    worksheet={currentWorksheet?.n as string}
                                    selectedViewType={selectedViewType}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Tooltip
                                        placement="top"
                                        title={worksheet?.name}
                                    >
                                        <Typography
                                            className="worksheet-name"
                                            variant="body1"
                                            data-testid="worksheet-name-front"
                                            sx={{
                                                mt: '-15px',
                                                color: textColor,
                                                fontWeight: 500,
                                                width: '100%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {worksheet.name &&
                                                truncateText(
                                                    worksheet.name,
                                                    15
                                                )}
                                        </Typography>
                                    </Tooltip>
                                    {workbookPermissions.includes(
                                        UserPermissions.DELETE
                                    ) && (
                                        <Box
                                            data-testid="menu-icon"
                                            className="menu-icon"
                                            sx={{
                                                position: 'absolute',
                                                display: 'none',
                                                opacity: isFlipped ? 0 : 1,
                                                transition: 'opacity 0.8s ease',
                                                right: 5,
                                                bottom: 5,
                                                zIndex: 20000,
                                            }}
                                        >
                                            <MenuIcon
                                                width={25}
                                                height={25}
                                                color={textColor}
                                                onClick={(
                                                    event: React.MouseEvent<
                                                        SVGSVGElement,
                                                        MouseEvent
                                                    >
                                                ) => {
                                                    event.stopPropagation();
                                                    setAnchorEl(
                                                        event.currentTarget as unknown as HTMLElement
                                                    );
                                                    setShowMenu(true);
                                                }}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            </Box>
                        </StyledCardBase>
                    </Box>
                    <Box
                        className="flip-card-back"
                        data-testid={'back-side'}
                        style={{
                            width: '100%',
                            height: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                height: '100%',
                                width: '100%',
                            }}
                        >
                            <StyledCardBase
                                multiSelectedCards={[]}
                                data={data}
                                worksheet={true}
                                numberOfWorksheetCards={
                                    worksheet?.toDataSources?.items?.length
                                }
                                worksheetFlipped={true}
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                }}
                                smallCard={true}
                                headerRight={
                                    <Box
                                        sx={{
                                            cursor: 'pointer',
                                            zIndex: 20000,
                                            paddingTop: '5px',
                                        }}
                                    >
                                        <FlipIcon
                                            width="20px"
                                            height="20px"
                                            fill={textColor}
                                            color={textColor}
                                            data-testid={'flip-icon'}
                                        />
                                    </Box>
                                }
                                handleHeaderClick={() =>
                                    setIsFlipped(!isFlipped)
                                }
                            >
                                <Box
                                    sx={{ height: '100%', width: '100%' }}
                                    onClick={() =>
                                        navigate(
                                            `/workbooks/${workbookId}/${worksheet.id}`
                                        )
                                    }
                                >
                                    <Tooltip
                                        placement="top"
                                        title={worksheet?.name}
                                    >
                                        <Typography
                                            variant="body1"
                                            sx={{
                                                color: textColor,
                                                textAlign: 'center',
                                                p: 1,
                                            }}
                                        >
                                            {worksheet.name &&
                                                truncateText(
                                                    worksheet.name,
                                                    15
                                                )}
                                        </Typography>
                                    </Tooltip>
                                    <Divider
                                        sx={{
                                            width: '90%',
                                            margin: 'auto',
                                            borderBottomWidth: 1,
                                            borderColor: textColor,
                                        }}
                                    />
                                    <Tooltip
                                        placement="top"
                                        title={worksheet?.commentary}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                color: textColor,
                                                textAlign: 'center',
                                                p: 1,
                                            }}
                                        >
                                            {!worksheet?.commentary
                                                ? 'No commentary'
                                                : truncateText(
                                                      worksheet?.commentary ||
                                                          '',
                                                      150
                                                  )}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                            </StyledCardBase>
                        </Box>
                    </Box>
                </motion.div>
            </Box>
            {showMenu && anchorEl && worksheet && (
                <WorksheetMenu
                    setAnchorEl={setAnchorEl}
                    anchorEl={anchorEl}
                    setDeleteWorksheetConfirmation={
                        setDeleteWorksheetConfirmation
                    }
                />
            )}
            {deleteWorksheetConfirmation && (
                <StyledModal
                    key="delete-card-modal"
                    open={deleteWorksheetConfirmation}
                    onClose={() => {
                        setDeleteWorksheetConfirmation(false);
                    }}
                    disableEnforceFocus
                    sx={{
                        zIndex: 1500,
                    }}
                >
                    <ModalContainer sx={{ maxWidth: '40rem' }}>
                        <CardDelete
                            id={worksheet.id}
                            organisation={worksheet.organisation}
                            confirmDelete={deleteWorksheet}
                            pageIdentifier={PageIdentifier.WORKSHEET}
                            toggleDeleteWizard={setDeleteWorksheetConfirmation}
                            deleting={deleting}
                        />
                    </ModalContainer>
                </StyledModal>
            )}
        </Box>
    );
};

export default WorksheetItem;
